import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { CreateCurrencyDto, CurrencyDto, CurrencyListDto, UpdateCurrencyDto } from "api-shared";
import { useDispatch } from "react-redux";
import { showNotificationEvent } from "../../infrastructure/notifications";
import { apiDelete, apiGet, apiPatch, apiPost } from "../../lib/api";
import { NotificationType } from "../../lib/notifications";
import { FeedbackTranslationKeys } from "../../translations/notification-translations";

const ADMIN_CURRENCIES_PATH = "admin/currencies";

export const useAdminCurrencies = () => {
    return useQuery({
        queryKey: [ADMIN_CURRENCIES_PATH],
        queryFn: ({ signal }) => apiGet<CurrencyListDto>(ADMIN_CURRENCIES_PATH, { signal }),
    });
};

export const useAdminAddCurrency = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: (data: CreateCurrencyDto) => apiPost<CurrencyDto>(ADMIN_CURRENCIES_PATH, data),
        onSuccess: () => {
            queryClient.invalidateQueries([ADMIN_CURRENCIES_PATH]);
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_CURRENCY_CREATED));
        },
    });
};

export const useAdminDeleteCurrency = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: (id: number) => apiDelete(`${ADMIN_CURRENCIES_PATH}/${id}`),
        onSuccess: () => {
            queryClient.invalidateQueries([ADMIN_CURRENCIES_PATH]);
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_CURRENCY_DELETED));
        },
    });
};

export const useAdminUpdateCurrency = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: ({ id, ...changes }: UpdateCurrencyDto & { id: number }) =>
            apiPatch<CurrencyDto>(`${ADMIN_CURRENCIES_PATH}/${id}`, changes),
        onSuccess: () => {
            queryClient.invalidateQueries([ADMIN_CURRENCIES_PATH]);
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_CURRENCY_UPDATED));
        },
    });
};
