import { useTranslation } from "react-i18next";
import DeleteDialog from "../../../components/dialogues/DeleteDialog";
import { useAdminDeleteDepartment, useAdminDepartments } from "../../../domain/admin/departments";

import { Container, Divider, Paper, Stack, Typography, styled } from "@mui/material";
import { useState } from "react";
import LoadingAnimation from "../../../components/loading/LoadingAnimation";
import useTimezone from "../../../hooks/useTimezone";
import { translationKeys } from "../../../translations/main-translations";
import DepartmentsTable from "./DepartmentsTable";

const Header = styled("div")(({ theme }) => ({
    padding: theme.spacing(2, 3),
}));

const DepartmentsSettings = () => {
    const { t: translate } = useTranslation();
    const [departmentToDelete, setDepartmentToDelete] = useState<number | null>(null);
    const departmentsQuery = useAdminDepartments();
    const departmentsMutation = useAdminDeleteDepartment();
    const { formatDate } = useTimezone();

    if (!departmentsQuery.isSuccess) {
        return <LoadingAnimation />;
    }

    return (
        <Container disableGutters maxWidth={false} sx={{ height: "100%" }}>
            {departmentToDelete !== null && (
                <DeleteDialog
                    open={Boolean(departmentToDelete)}
                    translate={translate}
                    onClose={() => setDepartmentToDelete(null)}
                    onDelete={() => departmentToDelete != null && departmentsMutation.mutate(departmentToDelete)}
                    item="department"
                    hideDescription
                />
            )}

            <Stack direction="column" sx={{ height: "100%" }} component={Paper}>
                <Header>
                    <Typography variant="subtitle1" component="div">
                        {translate(translationKeys.VDLANG_ADMIN_SIDEBAR_DATA_DEPARTMENTS)}
                    </Typography>
                </Header>
                <Divider />
                <DepartmentsTable
                    departments={departmentsQuery.data.map((d) => ({ ...d, createdAt: formatDate(d.createdAt) }))}
                    translate={translate}
                    removeDepartment={setDepartmentToDelete}
                />
            </Stack>
        </Container>
    );
};

export default DepartmentsSettings;
