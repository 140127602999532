import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AdminMeasureConfigListDto, AdminMeasureConfigValidationListDto, AdminMeasureListDto, MeasureDto } from "api-shared";
import { useDispatch } from "react-redux";
import { showNotificationEvent } from "../../infrastructure/notifications";
import { apiGet, apiPatch, apiPost } from "../../lib/api";
import { NotificationType } from "../../lib/notifications";
import { FeedbackTranslationKeys } from "../../translations/notification-translations";

const ADMIN_MEASURES_PATH = "admin/measures";
const ADMIN_MEASURE_CONFIGS_PATH = "admin/measure-configs";

interface IMeasuresBulkUpdateInputDto {
    measureIds: number[];
    changes: Partial<MeasureDto>;
}

export const useAdminMeasures = () => {
    return useQuery({
        queryKey: [ADMIN_MEASURES_PATH],
        queryFn: ({ signal }) => apiGet<AdminMeasureListDto>(ADMIN_MEASURES_PATH, { signal }),
    });
};

export const useAdminReopenLastGateTask = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: (id: number) => apiPost(`${ADMIN_MEASURES_PATH}/${id}/reopen`),
        onSuccess: () => {
            queryClient.invalidateQueries([ADMIN_MEASURES_PATH]);
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_LAST_GATE_TASK_REOPENED));
        },
    });
};

export const useAdminBulkUpdateMeasures = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: ({ measureIds, changes }: IMeasuresBulkUpdateInputDto) =>
            apiPatch<IMeasuresBulkUpdateInputDto>(`${ADMIN_MEASURES_PATH}/bulk`, { measureIds, ...changes }),
        onSuccess: () => {
            queryClient.invalidateQueries([ADMIN_MEASURES_PATH]);
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_MEASURES_UPDATED));
        },
    });
};
export const useAdminUpdateMeasure = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: ({ id, ...changes }: Partial<MeasureDto> & Pick<MeasureDto, "id">) =>
            apiPatch<MeasureDto>(`${ADMIN_MEASURES_PATH}/${id}`, changes),
        onSuccess: () => {
            queryClient.invalidateQueries([ADMIN_MEASURES_PATH]);
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_MEASURE_UPDATED));
        },
    });
};

export const useAdminMeasureConfigs = () => {
    return useQuery({
        queryKey: [ADMIN_MEASURE_CONFIGS_PATH],
        queryFn: ({ signal }) => apiGet<AdminMeasureConfigListDto>(ADMIN_MEASURE_CONFIGS_PATH, { signal }),
    });
};

export const useAdminMeasureGetValuestreams = (measureId: number | undefined) => {
    return useQuery({
        queryKey: [ADMIN_MEASURES_PATH, "valuestreams", measureId],
        queryFn: ({ queryKey: [, , measureId], signal }) =>
            apiGet<AdminMeasureConfigValidationListDto>(`${ADMIN_MEASURES_PATH}/${measureId}/valuestreams`, { signal }),
        enabled: measureId !== undefined,
    });
};
