import { useQuery } from "@tanstack/react-query";
import { AdminAttributeListDto } from "api-shared";
import { apiGet } from "../../lib/api";

const ADMIN_ATTRIBUTES_PATH = "admin/attributes";

export const AdminAttributesQueryKeys = {
    all: [ADMIN_ATTRIBUTES_PATH, "all"] as const,
};

export const useAdminAttributes = () => {
    return useQuery({
        queryKey: AdminAttributesQueryKeys.all,
        queryFn: ({ signal }) => apiGet<AdminAttributeListDto>(ADMIN_ATTRIBUTES_PATH, { signal }),
    });
};
