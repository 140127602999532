import { Chip, chipClasses, styled } from "@mui/material";
import { blue, green } from "@mui/material/colors";
import { AclListDto, AdminMeasureConfigListDto, AdminMeasureListDto, DashboardDto, GroupDto, UserAclDto, UserDto } from "api-shared";
import { TFunction } from "i18next";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { CellProps, Column } from "react-table";
import DeleteDialog from "../../../components/dialogues/DeleteDialog";
import DeleteIcon from "../../../components/icons/DeleteIcon";
import LoadingAnimation from "../../../components/loading/LoadingAnimation";
import BaseTable from "../../../components/table/BaseTable";
import TableGroupCell from "../../../components/table/TableGroupCell";
import TableHeaderCell from "../../../components/table/TableHeaderCell";
import TableIconButton from "../../../components/table/TableIconButton";
import TableTextCell from "../../../components/table/TableTextCell";
import TableUserCell from "../../../components/table/TableUserCell";
import { useAdminPermissionsDeleteGroupAcl, useAdminPermissionsDeleteUserAcl } from "../../../domain/admin/permissions";
import { useLanguage } from "../../../hooks/useLanguage";
import { fullGroupPath } from "../../../lib/groups";
import { translationKeys } from "../../../translations/main-translations";
import AclTableRuleCell from "./AclTableRuleCell";
import { useGetAclTableRuleResolver } from "./helper/useGetAclTableRuleResolver";

const INITIAL_SORT_BY = [
    {
        id: "assigneeId",
        desc: false,
    },
];

export enum AclAssigneeType {
    User = "user",
    Group = "group",
}

export type AclWithType = Omit<UserAclDto, "userId"> & {
    assigneeId: number;
    type: AclAssigneeType;
    isDirect: boolean;
};

interface AclTableProps {
    acls: AclListDto;
    isFetching: boolean;
    measureConfigs: AdminMeasureConfigListDto;
    dashboards: DashboardDto[];
    measures: AdminMeasureListDto;
    users?: UserDto[];
    groups?: GroupDto[];
    globalFilter?: string;
    indicateRuleOrigin?: boolean; // Enable visual indication whether user/group rule origin is direct or indirect
    groupId?: number; // Set in combination with indicateRuleOrigin to determine if group rule applies directly or indirectly for a specific group
}

const AclIndirectRuleChip = styled(Chip)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    [`& > .${chipClasses.label}`]: {
        ...theme.typography.caption,
    },
    backgroundColor: blue[50],
    color: blue[900],
}));

const AclDirectRuleChip = styled(Chip)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    [`& > .${chipClasses.label}`]: {
        ...theme.typography.caption,
    },
    backgroundColor: green[50],
    color: green[900],
}));

const getUserOrGroupCell = (
    props: CellProps<AclWithType>,
    users: UserDto[],
    groups: GroupDto[],
    indicateRuleOrigin: boolean,
    translate: TFunction,
) => {
    const getChip = (isDirect: boolean) =>
        isDirect ? (
            <AclDirectRuleChip label={translate(translationKeys.VDLANG_ACL_DIRECT)} size="small" />
        ) : (
            <AclIndirectRuleChip label={translate(translationKeys.VDLANG_ACL_INDIRECT)} size="small" />
        );

    if (props.row.original.type === AclAssigneeType.User) {
        return (
            <TableUserCell users={users} {...props} value={props.row.original.assigneeId}>
                {indicateRuleOrigin ? getChip(props.row.original.isDirect) : null}
            </TableUserCell>
        );
    } else if (props.row.original.type === AclAssigneeType.Group) {
        return (
            <TableGroupCell groups={groups} {...props} value={props.row.original.assigneeId}>
                {indicateRuleOrigin ? getChip(props.row.original.isDirect) : null}
            </TableGroupCell>
        );
    } else {
        return null;
    }
};

const getUserOrGroupValue = (row: AclWithType, users: UserDto[], groups: GroupDto[], language: string) => {
    if (row.type === AclAssigneeType.User) {
        const user = users.find((u) => u.id === row.assigneeId);
        return user?.displayname ?? user?.realname ?? String(row.assigneeId);
    } else if (row.type === AclAssigneeType.Group) {
        const group = groups.find((g) => g.id === row.assigneeId);
        return group === undefined ? row.assigneeId : fullGroupPath(groups, row.assigneeId, language);
    } else {
        return "";
    }
};

const getDeleteCell = (_: CellProps<AclWithType>, onClick: () => void) => (
    <TableIconButton onClick={onClick}>
        <DeleteIcon />
    </TableIconButton>
);

function getAclRuleCell(measures: AdminMeasureListDto) {
    return (props: CellProps<AclWithType>) => <AclTableRuleCell {...props} measures={measures} />;
}

export const AclTable = ({
    acls,
    isFetching,
    measureConfigs,
    users,
    groups,
    globalFilter,
    dashboards,
    measures,
    groupId,
    indicateRuleOrigin = false,
}: AclTableProps) => {
    const { t } = useTranslation();
    const language = useLanguage();
    const deleteUserAclMutation = useAdminPermissionsDeleteUserAcl();
    const deleteGroupAclMutation = useAdminPermissionsDeleteGroupAcl();

    const getResolvedAclRule = useGetAclTableRuleResolver();

    // We need to delay the global filter because the base table will not update the filtering otherwise :(
    const [delayedGlobalFilter, setDelayedGlobalFilter] = useState("");

    const [aclToDeleteId, setAclToDeleteId] = useState<number | undefined>(undefined);

    const mergedAcls = useMemo<AclWithType[]>(() => {
        const userAcls = acls.userAcls.map((acl) => ({ ...acl, assigneeId: acl.userId, type: AclAssigneeType.User, isDirect: true }));
        const groupAcls = acls.groupAcls.map((acl) => ({
            ...acl,
            assigneeId: acl.groupId,
            type: AclAssigneeType.Group,
            isDirect: groupId !== undefined && acl.groupId === groupId,
        }));
        return [...userAcls, ...groupAcls];
    }, [acls.groupAcls, acls.userAcls, groupId]);

    useEffect(() => {
        setDelayedGlobalFilter(globalFilter ?? "");
    }, [globalFilter, mergedAcls]);

    const onRemove = () => {
        const aclDel = mergedAcls.find((acl) => acl.id === aclToDeleteId);
        if (aclDel === undefined) {
            return;
        }

        setDelayedGlobalFilter("");
        if (aclDel.type === AclAssigneeType.User) {
            deleteUserAclMutation.mutate(aclDel.id);
        } else {
            deleteGroupAclMutation.mutate(aclDel.id);
        }
    };

    const renderUserOrGroup = useMemo(
        () => (props: CellProps<AclWithType>) => getUserOrGroupCell(props, users ?? [], groups ?? [], indicateRuleOrigin, t),
        [groups, users, indicateRuleOrigin, t],
    );

    const columns = useMemo<Column<AclWithType>[]>(
        () => [
            {
                id: "assigneeId",
                accessor: (acl: AclWithType) => getUserOrGroupValue(acl, users ?? [], groups ?? [], language),
                label: indicateRuleOrigin ? t(translationKeys.VDLANG_ACL_PERMISSION_ORIGIN) : t(translationKeys.VDLANG_ACL_USER_GROUP),
                Header: TableHeaderCell,
                Cell: renderUserOrGroup,
            },
            {
                id: "namespace",
                accessor: (acl) => t(`${translationKeys.VDLANG_ACL_NAMESPACES}.${acl.namespace}`),
                label: t(translationKeys.VDLANG_ACL_NAMESPACE),
                Header: TableHeaderCell,
                Cell: TableTextCell,
            },
            {
                id: "permission",
                accessor: (acl) => t(`${translationKeys.VDLANG_ACL_PERMISSIONS}.${acl.permission}`),
                label: t(translationKeys.VDLANG_ACL_PERMISSION),
                Header: TableHeaderCell,
                Cell: TableTextCell,
            },
            {
                id: "rule",
                accessor: (acl) => getResolvedAclRule(acl, measures, measureConfigs, dashboards),
                label: t(translationKeys.VDLANG_ACL_RULE),
                Header: TableHeaderCell,
                Cell: getAclRuleCell(measures),
            },
            {
                Header: "",
                accessor: "id",
                id: "delete",
                width: 64,
                disableResizing: true,
                disableSortBy: true,
                Cell: (cell) => getDeleteCell(cell, () => setAclToDeleteId(cell.value)),
            },
        ],
        [groups, renderUserOrGroup, t, users, measureConfigs, dashboards, measures, language, indicateRuleOrigin, getResolvedAclRule],
    );

    if (isFetching) {
        return <LoadingAnimation />;
    }

    return (
        <>
            <DeleteDialog
                item="rule"
                translate={t}
                open={aclToDeleteId !== undefined}
                onClose={() => setAclToDeleteId(undefined)}
                onDelete={onRemove}
            />
            <BaseTable
                fullHeight
                isFetching={false}
                data={mergedAcls}
                defaultSortBy={INITIAL_SORT_BY}
                columns={columns}
                itemName="acls"
                translate={t}
                globalFilter={delayedGlobalFilter}
                noDataText={t(translationKeys.VDLANG_PERMISSION_DIALOG_NO_RULES_FOUND)}
            />
        </>
    );
};
