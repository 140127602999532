import { Stack } from "@mui/material";
import { AdminMeasureDto } from "api-shared";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Alert from "../../../components/Alert";
import ActionItemDialog from "../../../components/dialogues/ActionItemDialog";
import Form from "../../../components/Form";
import Select from "../../../components/input/select/Select";
import { useAdminMeasureGetValuestreams } from "../../../domain/admin/measures";
import { translationKeys } from "../../../translations/main-translations";

type MeasuresChangeValuestreamDialogProps = {
    open?: boolean;
    onClose?: () => void;
    measure?: AdminMeasureDto;
    onValuestreamSubmit: (newMeasureConfigId: number) => void;
};

export const MeasuresChangeValuestreamDialog = ({
    open = false,
    onClose = () => {},
    measure,
    onValuestreamSubmit,
}: MeasuresChangeValuestreamDialogProps) => {
    const { t } = useTranslation();

    const useAdminMeasureGetValuestreamsQuery = useAdminMeasureGetValuestreams(measure?.id);
    const [selectedMeasureConfigId, setSelectedMeasureConfigId] = useState<number | null>(null);

    const onCancel = () => {
        setSelectedMeasureConfigId(null);
        onClose();
    };

    if (!useAdminMeasureGetValuestreamsQuery.isSuccess || useAdminMeasureGetValuestreamsQuery.isLoading) {
        return null;
    }

    // Hide the current valuestream from the list of options
    const filteredValuestreams = useAdminMeasureGetValuestreamsQuery.data.filter((vs) => vs.measureConfig.id !== measure?.measureConfigId);

    const measureConfigOptions = () => {
        return [
            ...filteredValuestreams.map(({ measureConfig }) => ({
                value: measureConfig.id,
                label: t(measureConfig.name),
            })),
        ];
    };

    // Select the first usable/valid valuestream
    const validValuestreams = filteredValuestreams.filter((vs) => vs.isValidEffectCatgoryConfig && vs.isValidGateTaskConfig);
    const currentSelectedMeasureConfigId =
        selectedMeasureConfigId ?? (validValuestreams[0] !== undefined ? validValuestreams[0].measureConfig.id : -1);
    const measureConfig = useAdminMeasureGetValuestreamsQuery.data.find((s) => s.measureConfig.id === currentSelectedMeasureConfigId);

    const isValid = useAdminMeasureGetValuestreamsQuery.data.some(
        ({ measureConfig, isValidEffectCatgoryConfig, isValidGateTaskConfig }) =>
            measureConfig.id === currentSelectedMeasureConfigId && isValidEffectCatgoryConfig && isValidGateTaskConfig,
    );

    const onSubmit = () => {
        if (measure && isValid) {
            onValuestreamSubmit(currentSelectedMeasureConfigId);
        }
    };

    return (
        <ActionItemDialog
            open={open}
            title={t(translationKeys.VDLANG_ADMIN_MEASURES_CHANGE_VALUESTREAM)}
            primary={t(translationKeys.VDLANG_MEASURE_ACCESS_CONTROL_CONFIRM_CHANGE_BUTTON)}
            primaryIsTranslated
            onPrimary={onSubmit}
            primaryDisabled={!isValid}
            onClose={onCancel}
            translate={t}
            disableContentPadding
            maxWidth="sm"
        >
            <Form onSubmit={onSubmit} style={{ margin: 0 }} width="100%">
                <Stack px={3} py={2} gap={1} direction="column">
                    <Select
                        margin="none"
                        label={t(translationKeys.VDLANG_ACL_TITLE_VALUESTREAM)}
                        value={measureConfigOptions().find((t) => t.value === currentSelectedMeasureConfigId)}
                        options={measureConfigOptions()}
                        onChange={(o) => setSelectedMeasureConfigId(o?.value ?? 0)}
                        menuPortalTarget={document.body}
                    />

                    {measureConfig != undefined && (!measureConfig.isValidEffectCatgoryConfig || !measureConfig.isValidGateTaskConfig) ? (
                        <Alert dense severity="error">
                            {t(translationKeys.VDLANG_ADMIN_MEASURES_CHANGE_VALUESTREAM_ERROR)}
                            <ul>
                                {measureConfig.isValidEffectCatgoryConfig ? null : (
                                    <li>{t(translationKeys.VDLANG_ADMIN_MEASURES_CHANGE_VALUESTREAM_ERROR_EC)}</li>
                                )}
                                {measureConfig.isValidGateTaskConfig ? null : (
                                    <li>{t(translationKeys.VDLANG_ADMIN_MEASURES_CHANGE_VALUESTREAM_ERROR_GT)}</li>
                                )}
                            </ul>
                        </Alert>
                    ) : null}

                    {measureConfig != undefined &&
                    !measureConfig?.responsibleCanSeeValuestream &&
                    measureConfig.isValidEffectCatgoryConfig &&
                    measureConfig.isValidGateTaskConfig ? (
                        <Alert dense severity="warning">
                            {t(translationKeys.VDLANG_ADMIN_MEASURES_CHANGE_VALUESTREAM_WARN_RESPONSIBLE_MISSING_PERMISSION)}
                        </Alert>
                    ) : null}

                    {measureConfig != undefined &&
                    measureConfig.addedFields.length > 0 &&
                    measureConfig.isValidEffectCatgoryConfig &&
                    measureConfig.isValidGateTaskConfig ? (
                        <Alert dense severity="warning">
                            {t(translationKeys.VDLANG_ADMIN_MEASURES_CHANGE_VALUESTREAM_WARN_FIELD_ADD)}
                            <ul>
                                {measureConfig.addedFields.map((field) => (
                                    <li key={field}>{t(field)}</li>
                                ))}
                            </ul>
                        </Alert>
                    ) : null}

                    {measureConfig != undefined &&
                    measureConfig.removedFields.length > 0 &&
                    measureConfig.isValidEffectCatgoryConfig &&
                    measureConfig.isValidGateTaskConfig ? (
                        <Alert dense severity="warning">
                            {t(translationKeys.VDLANG_ADMIN_MEASURES_CHANGE_VALUESTREAM_WARN_FIELD_REMOVE)}
                            <ul>
                                {measureConfig.removedFields.map((field) => (
                                    <li key={field}>{t(field)}</li>
                                ))}
                            </ul>
                        </Alert>
                    ) : null}
                </Stack>
            </Form>
        </ActionItemDialog>
    );
};
