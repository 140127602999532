import { FormGroup, TextField, Typography } from "@mui/material";
import { TreeNodeDto, zMinMaxShortText } from "api-shared";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import Form from "../../../../../components/Form";
import ActionItemDialog from "../../../../../components/dialogues/ActionItemDialog";
import { useAdminCreateTreeNode } from "../../../../../domain/admin/tree-nodes";
import { useLanguage } from "../../../../../hooks/useLanguage";
import { translateFromProperty } from "../../../../../lib/translate";
import { translationKeys } from "../../../../../translations/main-translations";

interface IAddTreeNodeDialogProps {
    parent?: TreeNodeDto;
    attributeId: number;
    open: boolean;
    onClose: () => void;
}

const zNameField = z.string().trim().pipe(zMinMaxShortText);

const AddTreeNodeDialog = ({ parent, attributeId, open, onClose }: IAddTreeNodeDialogProps) => {
    const { t } = useTranslation();
    const language = useLanguage();
    const [nameDe, setNameDe] = useState("");
    const [nameEn, setNameEn] = useState("");
    const createAdminTreeNode = useAdminCreateTreeNode().mutate;

    const parentName = parent != null ? translateFromProperty(parent, "name", language) : null;

    const parsedNameDe = zNameField.safeParse(nameDe);
    const parsedNameEn = zNameField.safeParse(nameEn);

    const isValid = parsedNameDe.success && parsedNameEn.success;

    const resetDialog = () => {
        setNameDe("");
        setNameEn("");
    };

    const onAdd = () => {
        if (!isValid) {
            return;
        }
        createAdminTreeNode(
            {
                attributeId,
                parentId: parent?.id ?? null,
                nameDe: parsedNameDe.data,
                nameEn: parsedNameEn.data,
            },
            {
                onSuccess: () => {
                    resetDialog();
                    onClose();
                },
            },
        );
    };

    return (
        <ActionItemDialog
            action="create"
            item="tree_node"
            primary="Add"
            onPrimary={onAdd}
            translate={t}
            open={open}
            onClose={() => {
                resetDialog();
                onClose();
            }}
            primaryDisabled={!isValid}
        >
            <Form onSubmit={onAdd}>
                <FormGroup>
                    <Typography fontWeight="bold">{t(translationKeys.VDLANG_TREE_NODE_PARENT)}</Typography>
                    <Typography>{parentName ?? t(translationKeys.VDLANG_TREE_NODE_NO_PARENT_SELECTED)}</Typography>
                    <TextField
                        required
                        label={t("displaynameDe")}
                        name="nameDe"
                        value={nameDe}
                        onChange={(event) => setNameDe(event.target.value)}
                        margin="normal"
                        error={!parsedNameDe.success}
                    />
                    <TextField
                        required
                        label={t("displaynameEn")}
                        name="nameEn"
                        value={nameEn}
                        onChange={(event) => setNameEn(event.target.value)}
                        margin="normal"
                        error={!parsedNameEn.success}
                    />
                </FormGroup>
            </Form>
        </ActionItemDialog>
    );
};

export default AddTreeNodeDialog;
