import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { DomainDto, DomainListDto } from "api-shared";
import { useDispatch } from "react-redux";
import { showNotificationEvent } from "../../infrastructure/notifications";
import { apiDelete, apiGet, apiPatch, apiPost } from "../../lib/api";
import { NotificationType } from "../../lib/notifications";
import { FeedbackTranslationKeys } from "../../translations/notification-translations";

const ADMIN_DOMAINS_PATH = "admin/domains";

export const useAdminDomains = () => {
    return useQuery({
        queryKey: [ADMIN_DOMAINS_PATH],
        queryFn: ({ signal }) => apiGet<DomainListDto>(ADMIN_DOMAINS_PATH, { signal }),
    });
};

export const useAdminAddDomain = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: (data: Omit<DomainDto, "id">) => apiPost<DomainDto>(ADMIN_DOMAINS_PATH, data),
        onSuccess: () => {
            queryClient.invalidateQueries([ADMIN_DOMAINS_PATH]);
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_DOMAIN_CREATED));
        },
    });
};

export const useAdminDeleteDomain = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: (id: number) => apiDelete(`${ADMIN_DOMAINS_PATH}/${id}`),
        onSuccess: () => {
            queryClient.invalidateQueries([ADMIN_DOMAINS_PATH]);
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_DOMAIN_DELETED));
        },
        meta: {
            ignoreErrors: true,
        },
    });
};

export const useAdminUpdateDomain = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: ({ id, ...changes }: Partial<Omit<DomainDto, "id">> & Required<Pick<DomainDto, "id">>) =>
            apiPatch<DomainDto>(`${ADMIN_DOMAINS_PATH}/${id}`, changes),
        onSuccess: () => {
            queryClient.invalidateQueries([ADMIN_DOMAINS_PATH]);
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_DOMAIN_UPDATED));
        },
        meta: {
            ignoreErrors: true,
        },
    });
};
