import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Radio, RadioGroup } from "@mui/material";
import { AdminUserDto, ClientGroupType, InviteUserRequest, UserTier } from "api-shared";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import isEmail from "validator/lib/isEmail";
import Form from "../../../components/Form";
import ActionItemDialog from "../../../components/dialogues/ActionItemDialog";
import InfoIcon from "../../../components/icons/InfoIcon";
import EmailInput from "../../../components/input/EmailInput";
import ValidatedInput from "../../../components/input/ValidatedInput";
import Select from "../../../components/input/select/Select";
import { useCurrentClient } from "../../../domain/client";
import { useSeatLimits } from "../../../domain/seat-limit";
import { useLanguage } from "../../../hooks/useLanguage";
import { Language, translationKeys } from "../../../translations/main-translations";
import { GroupSelect } from "./GroupSelect";
import { getUserTierOption, getUserTierOptions } from "./user-utils";

interface InviteMemberDialogProps {
    open: boolean;
    inviteUser: (newUser: InviteUserRequest) => void;
    allUsers: AdminUserDto[];
    onClose: () => void;
}

function makeEmptyUser({
    realname = "",
    email = "",
    language = Language.EN,
    groups = [],
    tier = UserTier.Light,
}: Partial<InviteUserRequest> = {}): InviteUserRequest {
    return {
        realname,
        email,
        language,
        groups,
        tier,
    };
}

const InviteMemberDialog = ({ open, inviteUser, onClose, allUsers }: InviteMemberDialogProps) => {
    const { t: translate } = useTranslation();
    const language = useLanguage();
    const seatLimits = useSeatLimits();
    const client = useCurrentClient();

    const defaultGroups = client.clientGroups.filter((group) => group.type === ClientGroupType.DEFAULT_GROUP).map((group) => group.groupId);
    const [user, setUser] = useState(makeEmptyUser({ language, groups: defaultGroups, tier: client.defaultUserTier }));
    const [inviteMore, setInviteMore] = useState(false);
    const [emailTouched, setEmailTouched] = useState(false);

    // Ref for focusing the first input when inviting multiple users
    const realnameRef = useRef<HTMLInputElement>(null);

    const isValidMail = isEmail(user.email.trim(), { allow_utf8_local_part: false });
    const isFilled = user.realname.trim().length > 0 && isValidMail;

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setUser((old) => ({ ...old, [name]: value }));
    };

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        handleInputChange(event);
        setEmailTouched(true);
    };

    const handleTierChange = (tier: UserTier) => {
        setUser((old) => ({ ...old, tier }));
    };

    // is there still one more user seat after inviting the current user
    const canInviteMore =
        seatLimits.admin.maxSeats - 1 > seatLimits.admin.seats ||
        seatLimits.advanced.maxSeats - 1 > seatLimits.advanced.seats ||
        seatLimits.basic.maxSeats - 1 > seatLimits.basic.seats ||
        seatLimits.light.maxSeats - 1 > seatLimits.light.seats;
    // is the current user inside the user limit
    const seatCheck = {
        [UserTier.SuperAdmin]: false,
        [UserTier.Admin]: seatLimits.admin.hasOpenSeats,
        [UserTier.Advanced]: seatLimits.advanced.hasOpenSeats,
        [UserTier.Basic]: seatLimits.basic.hasOpenSeats,
        [UserTier.Light]: seatLimits.light.hasOpenSeats,
    };
    const hasOpenSeats = seatCheck[user.tier];
    const hasAlreadyExistingMail = allUsers.some((u) => u.email?.toLowerCase() === user.email.toLowerCase());

    const userTierOptions = getUserTierOptions(translate);

    const submit = () => {
        if (!isFilled || hasAlreadyExistingMail) {
            return;
        }
        inviteUser({ ...user });

        if (inviteMore && canInviteMore) {
            // keep permissions when inviting another user and there are still user seats available
            setUser(({ groups }) => makeEmptyUser({ language: user.language, groups, tier: user.tier }));
        } else {
            // reset permissions when modal will be closed or no more seats are available
            setUser(makeEmptyUser({ language, groups: defaultGroups, tier: client.defaultUserTier }));
        }

        if (inviteMore) {
            realnameRef.current?.focus();
        }

        setEmailTouched(false);
    };

    let emailHelperText = "";
    if (emailTouched && !isValidMail) {
        emailHelperText = translate(translationKeys.VDLANG_INVITE_USER_INVALID_EMAIL);
    }
    if (emailTouched && hasAlreadyExistingMail) {
        emailHelperText = translate(translationKeys.VDLANG_INVITE_USER_ALREADY_EXISTS);
    }

    return (
        <ActionItemDialog
            open={open}
            onClose={onClose}
            action="edit"
            item="invite_member"
            primary="invite"
            primaryDisabled={!isFilled || !hasOpenSeats || hasAlreadyExistingMail}
            onPrimary={submit}
            translate={translate}
            title={translate("invite_member")}
            keepDialog={inviteMore}
            fullWidth={true}
        >
            <Form onSubmit={submit}>
                <ValidatedInput
                    inputRef={realnameRef}
                    name="realname"
                    mandatory={true}
                    label={translate("realname")}
                    type="text"
                    value={user.realname}
                    onChange={handleInputChange}
                    margin="dense"
                    fullWidth
                />
                <EmailInput
                    mandatory
                    fullWidth
                    value={user.email}
                    onChange={handleEmailChange}
                    margin="dense"
                    error={emailTouched && (hasAlreadyExistingMail || !isValidMail)}
                    helperText={emailHelperText}
                />
                <GroupSelect
                    label={translate(translationKeys.VDLANG_ADMIN_MEMBERS_GROUPS)}
                    onChange={(groups) => setUser((old) => ({ ...old, groups }))}
                    value={user.groups}
                />
                <Select
                    name="tier"
                    label={
                        <>
                            {`${translate(translationKeys.VDLANG_USER_TIER)} `}
                            <InfoIcon title={translate(translationKeys.VDLANG_USER_TIER_HINT)} />
                        </>
                    }
                    value={getUserTierOption(user.tier, translate)}
                    onChange={(option) => handleTierChange(option?.value ?? userTierOptions[0].value)}
                    options={userTierOptions}
                    margin="none"
                    menuPortalTarget={document.body}
                />
                <FormControl component="fieldset" margin="dense">
                    <FormLabel>{translate("Language")}</FormLabel>
                    <RadioGroup value={user.language} onChange={handleInputChange} name="language">
                        {[Language.EN, Language.DE].map((lang) => (
                            <FormControlLabel key={lang} value={lang} control={<Radio size="small" />} label={translate(lang)} />
                        ))}
                    </RadioGroup>
                </FormControl>
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox checked={inviteMore} onChange={(event) => setInviteMore(event.target.checked)} />}
                        label={translate("invite_another_member")}
                        disabled={!canInviteMore}
                    />
                </FormGroup>
            </Form>
        </ActionItemDialog>
    );
};

export default InviteMemberDialog;
