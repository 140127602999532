import { Grid } from "@mui/material";
import { DomainDto, IdentityProviderDto, sanitizeDomain } from "api-shared";
import { TFunction } from "i18next";
import React, { useState } from "react";
import isFQDN from "validator/lib/isFQDN";
import Form from "../../../components/Form";
import ActionItemDialog from "../../../components/dialogues/ActionItemDialog";
import ValidatedInput from "../../../components/input/ValidatedInput";
import Select from "../../../components/input/select/Select";
import { Option } from "../../../components/input/select/types";
import { useAdminAddDomain, useAdminUpdateDomain } from "../../../domain/admin/domains";
import { translationKeys } from "../../../translations/main-translations";

interface ICreateEditDomainDialogProps {
    open: boolean;
    onClose: () => void;
    translate: TFunction;
    domain: DomainDto | undefined;
    domains: DomainDto[];
    identityProviders: IdentityProviderDto[];
}

const defaultDomain: DomainDto = {
    name: "",
};

const CreateEditDomainDialog = ({ open, onClose, translate, domain, domains, identityProviders }: ICreateEditDomainDialogProps) => {
    const createDomainMutation = useAdminAddDomain();
    const updateDomainMutation = useAdminUpdateDomain();
    const [changes, setChanges] = useState<Partial<DomainDto>>({});
    const updatedDomain: DomainDto = {
        ...defaultDomain,
        ...domain,
        ...changes,
    };
    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!("target" in event)) {
            return;
        }
        const { name, value } = event.target;
        setChanges((oldDomain) => ({ ...oldDomain, [name]: value }));
    };

    const handleIdentityProviderChange = (selectedOption: Option | null) => {
        setChanges((oldDomain) => ({ ...oldDomain, identityProviderId: selectedOption !== null ? selectedOption.value : null }));
    };

    const domainNameChanged = updatedDomain.name !== domain?.name;
    const domainAlreadyExists = domainNameChanged && domains.some((d) => d.name === updatedDomain.name.trim());
    const isValid = updatedDomain != null && isFQDN(sanitizeDomain(updatedDomain.name)) && !domainAlreadyExists;

    const getDomainNameErrorText = () => {
        if (!isFQDN(sanitizeDomain(updatedDomain.name))) {
            return translate(translationKeys.VDLANG_INVALID_DOMAIN_NAME);
        }
        if (domainAlreadyExists) {
            return translate(translationKeys.VDLANG_DOMAIN_NAME_ALREADY_EXISTS);
        }
        return "";
    };

    const handleClose = () => {
        setChanges({});
        onClose();
    };

    const submit = () => {
        if (!isValid) {
            return;
        }

        const { id, ...newDomain } = updatedDomain;
        if (id === -1 || id === undefined) {
            createDomainMutation.mutate(newDomain as DomainDto);
        } else {
            updateDomainMutation.mutate({ id, ...changes });
        }
        handleClose();
    };

    const identityProviderOptions = [
        { value: null, label: translate(translationKeys.VDLANG_DEFAULT) },
        ...identityProviders.map(({ id, name }) => ({
            value: id,
            label: name,
        })),
    ];

    return (
        <ActionItemDialog
            open={open}
            onClose={handleClose}
            title={translate(translationKeys.VDLANG_ADMIN_DOMAIN)}
            primary={translationKeys.VDLANG_SAVE}
            primaryDisabled={!isValid}
            onPrimary={submit}
            translate={translate}
        >
            <Form onSubmit={submit}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <ValidatedInput
                            name="name"
                            mandatory
                            label={translate("Name")}
                            type="text"
                            value={updatedDomain.name}
                            onChange={handleValueChange}
                            placeholder={translate(translationKeys.VDLANG_NEW_DOMAIN_PLACEHOLDER)}
                            fullWidth
                            disabled={domain?.originatorId === null}
                            error={!isValid && updatedDomain.name !== ""}
                            helperText={getDomainNameErrorText()}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Select
                            label={translate(translationKeys.VDLANG_USED_IN_IDENTITY_PROVIDER)}
                            value={identityProviderOptions.find((ip) => ip.value === updatedDomain.identityProviderId)}
                            options={identityProviderOptions}
                            onChange={handleIdentityProviderChange}
                            menuPortalTarget={document.body}
                            isClearable={updatedDomain.identityProviderId != null}
                            isSearchable
                        />
                    </Grid>
                </Grid>
            </Form>
        </ActionItemDialog>
    );
};

export default CreateEditDomainDialog;
