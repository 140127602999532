import { UserDto } from "api-shared";
import { useTranslation } from "react-i18next";
import { CellProps } from "react-table";
import DeleteIcon from "../../../components/icons/DeleteIcon";
import TableIconButton from "../../../components/table/TableIconButton";
import { translationKeys } from "../../../translations/main-translations";

interface UserTableDeleteCellProps {
    cell: CellProps<UserDto>;
    onClick: (value: any) => void;
    disabled?: boolean;
}

export const UserTableDeleteCell = ({ cell, disabled, onClick }: UserTableDeleteCellProps) => {
    const { t } = useTranslation();

    const label = t(translationKeys.VDLANG_ADMIN_GROUPS_REMOVE_USER);

    return (
        <TableIconButton onClick={() => onClick(cell.value)} disabled={disabled ?? false} title={label} aria-label={label}>
            <DeleteIcon />
        </TableIconButton>
    );
};
