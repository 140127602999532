import AddIcon from "@mui/icons-material/AddRounded";
import DeleteIcon from "@mui/icons-material/DeleteRounded";
import { Button, Divider, Grid, Paper, styled } from "@mui/material";
import { CompanyCreateDto, CompanyDto, CompanyUpdateDto } from "api-shared";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { CellProps, Column } from "react-table";
import DeleteDialog from "../../../components/dialogues/DeleteDialog";
import SearchInput from "../../../components/input/SearchInput";
import LoadingAnimation from "../../../components/loading/LoadingAnimation";
import BaseTable from "../../../components/table/BaseTable";
import TableHeaderCell from "../../../components/table/TableHeaderCell";
import TableIconButton from "../../../components/table/TableIconButton";
import TableSettingsButton from "../../../components/table/TableSettingsButton";
import TableTextCell from "../../../components/table/TableTextCell";
import Tooltip from "../../../components/Tooltip";
import { useAdminAddCompany, useAdminCompanies, useAdminDeleteCompany, useAdminUpdateCompany } from "../../../domain/admin/companies";
import { useDebounce } from "../../../hooks/useDebounce";
import { translationKeys } from "../../../translations/main-translations";
import CreateCompany from "./CreateCompanyDialog";
import EditCompanyDialog from "./EditCompanyDialog";

interface ICompanyRow {
    id: number;
    name: string;
    isUsed: boolean;
    location: string;
}

const INITIAL_SORT_BY = [
    {
        id: "name",
        desc: false,
    },
];

const formatLocation = (company: CompanyDto) => {
    const locationParts = [company.street, company.zip, company.city, company.country];

    return locationParts.filter((part) => part !== null && part !== "").join(", ");
};

const Root = styled(Paper)({ height: "100%" });
const RootGrid = styled(Grid)({ height: "100%" });

const Grow = styled(Grid)({
    flexGrow: 1,
    flexShrink: 1,
});

const CompaniesSettings = () => {
    const [createCompanyModalOpen, setCreateCompanyModalOpen] = useState(false);
    const [editCompanyModal, setEditCompanyModal] = useState<number | null>(null);
    const [companyToDelete, setCompanyToDelete] = useState<number | null>(null);

    const { t: translate } = useTranslation();

    const [searchKey, setSearchKey] = useState("");
    const debouncedSearchKey = useDebounce(searchKey);

    const companiesQuery = useAdminCompanies();
    const addCompanyMutation = useAdminAddCompany();
    const deleteCompanyMutation = useAdminDeleteCompany();
    const updateCompanyMutation = useAdminUpdateCompany();

    const renderDeleteButton = useCallback(
        ({ row, value }: CellProps<ICompanyRow>) => (
            <Tooltip title={row.original.isUsed ? translate("cannot_delete_company_in_use") : ""}>
                <span>
                    <TableIconButton disabled={row.original.isUsed} onClick={() => setCompanyToDelete(value)}>
                        <DeleteIcon />
                    </TableIconButton>
                </span>
            </Tooltip>
        ),
        [translate],
    );

    const submitEdit = (company: CompanyUpdateDto) => {
        if (editCompanyModal != null) {
            updateCompanyMutation.mutate({ ...company, id: editCompanyModal });
            setEditCompanyModal(null);
        }
    };

    const createCompany = (newCompany: CompanyCreateDto) => {
        addCompanyMutation.mutate(newCompany);
        setCreateCompanyModalOpen(false);
    };

    const deleteCompany = () => {
        if (companyToDelete !== null) {
            deleteCompanyMutation.mutate(companyToDelete);
            setEditCompanyModal(null);
            setCompanyToDelete(null);
        }
    };

    const tableData = useMemo<ICompanyRow[]>(
        () =>
            (companiesQuery.data ?? ([] as CompanyDto[])).map((company) => ({
                id: company.id,
                name: company.name,
                isUsed: company.isUsed ?? false,
                location: formatLocation(company),
            })),
        [companiesQuery.data],
    );

    const columns = useMemo<Column<ICompanyRow>[]>(
        () => [
            {
                Header: TableHeaderCell,
                label: translate("Name"),
                accessor: "name",
                disableSortBy: false,
                Cell: TableTextCell,
            },
            {
                Header: TableHeaderCell,
                label: translate("location"),
                accessor: "location",
                disableSortBy: false,
                Cell: TableTextCell,
            },
            {
                Header: "",
                accessor: "id",
                id: "remove",
                disableResizing: true,
                width: 64,
                Cell: renderDeleteButton,
            },
            {
                Header: "",
                accessor: "id",
                id: "settings",
                disableResizing: true,
                width: 64,
                Cell: ({ value }: CellProps<ICompanyRow>) => (
                    <TableSettingsButton title={translate("Edit Company")} onClick={() => setEditCompanyModal(value)} />
                ),
            },
        ],
        [translate, renderDeleteButton],
    );

    if (!companiesQuery.isSuccess) {
        // Show loading animation only for initial request
        return <LoadingAnimation />;
    }

    const companies = companiesQuery.data;

    const companyToEdit = companies.find((company) => company.id === editCompanyModal);

    return (
        <Root>
            <RootGrid container direction="column" wrap="nowrap">
                {companyToEdit != null ? (
                    <EditCompanyDialog
                        open={editCompanyModal != null}
                        company={companyToEdit}
                        onClose={() => setEditCompanyModal(null)}
                        onDelete={() => setCompanyToDelete(editCompanyModal)} // explicitly call with no arg instead of event
                        translate={translate}
                        submitCallback={submitEdit}
                    />
                ) : null}
                <CreateCompany
                    translate={translate}
                    submitCallback={createCompany}
                    open={createCompanyModalOpen}
                    onClose={() => setCreateCompanyModalOpen(false)}
                />
                <DeleteDialog
                    open={companyToDelete != null}
                    onClose={() => setCompanyToDelete(null)}
                    onDelete={deleteCompany}
                    item="company"
                    translate={translate}
                >
                    {translate("remove_company_description", { company: companies.find((c) => c.id === companyToDelete)?.name ?? "" })}
                </DeleteDialog>
                <Grid item>
                    <Grid container justifyContent="space-between" alignItems="center" spacing={2} wrap="nowrap" px={3} py={1.2}>
                        <Grid item>
                            <SearchInput translate={translate} onChange={setSearchKey} searchKey={searchKey} />
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => setCreateCompanyModalOpen(true)}
                                startIcon={<AddIcon />}
                            >
                                {translate("Create Company")}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item component={Divider} />
                <Grow item>
                    <BaseTable
                        fullHeight
                        data={tableData}
                        columns={columns}
                        defaultSortBy={INITIAL_SORT_BY}
                        itemName="companies"
                        translate={translate}
                        globalFilter={debouncedSearchKey}
                        noDataText={translate(translationKeys.VDLANG_NO_COMPANIES)}
                    />
                </Grow>
            </RootGrid>
        </Root>
    );
};

export default CompaniesSettings;
