import { Divider, FormControlLabel, Radio, RadioGroup, Stack, Typography } from "@mui/material";
import { TreeNodeDto } from "api-shared";
import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import ActionItemDialog from "../../../../../components/dialogues/ActionItemDialog";
import Form from "../../../../../components/Form";
import TreeNodes from "../../../../../components/TreeNodes";
import { useAdminDeleteTreeNode } from "../../../../../domain/admin/tree-nodes";
import { Language } from "../../../../../translations/main-translations";

interface IDeleteTreeNodeDialogProps {
    treeNode: TreeNodeDto;
    treeNodes: TreeNodeDto[];
    open: boolean;
    onClose: () => void;
    language: Language;
}

enum DeleteOptions {
    NoReplace = "NO_REPLACE",
    RemoveUsage = "REMOVE_USAGE",
    Replace = "REPLACE",
}

const DeleteTreeNodeDialog = ({ language, treeNode, treeNodes, open, onClose }: IDeleteTreeNodeDialogProps) => {
    const { t: translate } = useTranslation();
    const deleteTreeNode = useAdminDeleteTreeNode().mutate;
    const deleteTreeNodeWithoutReport = useAdminDeleteTreeNode(true).mutate;
    const [deleteOption, setDeleteOption] = useState<DeleteOptions>(DeleteOptions.NoReplace);
    const [replacementId, setReplacementId] = useState<number | null>(null);

    const handleReplacementSelect = (_event: React.SyntheticEvent, nodeId: number | null) => {
        setReplacementId(nodeId);
    };

    const onDelete = () => {
        const options = { onSuccess: onClose };
        switch (deleteOption) {
            case DeleteOptions.RemoveUsage: {
                deleteTreeNode({ attributeId: treeNode.attributeId, id: treeNode.id, replaceValue: null }, options);
                break;
            }
            case DeleteOptions.Replace: {
                deleteTreeNode({ attributeId: treeNode.attributeId, id: treeNode.id, replaceValue: replacementId }, options);
                break;
            }
            case DeleteOptions.NoReplace:
            default: {
                // will fail if tree node in use, so we do not report the error
                deleteTreeNodeWithoutReport({ attributeId: treeNode.attributeId, id: treeNode.id, replaceValue: undefined }, options);
            }
        }
    };

    return (
        <ActionItemDialog
            action="delete"
            item="tree_node"
            primary={deleteOption === DeleteOptions.Replace ? "Replace" : "Delete"}
            onPrimary={onDelete}
            translate={translate}
            open={open}
            onClose={onClose}
            primaryDisabled={
                (deleteOption === DeleteOptions.Replace && replacementId == null) ||
                (deleteOption === DeleteOptions.Replace && replacementId === treeNode.id)
            }
            primaryDanger
        >
            <Form onSubmit={onDelete}>
                <RadioGroup value={deleteOption} onChange={(e) => setDeleteOption(e.target.value as DeleteOptions)} name="language">
                    {Object.values(DeleteOptions).map((option, index) => (
                        <Fragment key={option}>
                            {index > 0 ? <Divider /> : null}
                            <FormControlLabel
                                sx={{ alignItems: "flex-start", paddingY: 1 }}
                                value={option}
                                control={<Radio size="small" />}
                                label={
                                    <Stack>
                                        <Typography>{translate(`VDLANG_TREE_NODE_DELETE_${option}`)}</Typography>
                                        <Typography color="textSecondary">
                                            {translate(`VDLANG_TREE_NODE_DELETE_${option}_DESCRIPTION`)}
                                        </Typography>
                                    </Stack>
                                }
                            />
                        </Fragment>
                    ))}
                </RadioGroup>
            </Form>
            {deleteOption === DeleteOptions.Replace ? (
                <TreeNodes
                    language={language}
                    selected={replacementId}
                    handleSelect={handleReplacementSelect}
                    treeNodes={treeNodes}
                    disabledItems={[treeNode.id]}
                />
            ) : null}
        </ActionItemDialog>
    );
};

export default DeleteTreeNodeDialog;
