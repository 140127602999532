import { CompanyCreateDto, CompanyUpdateDto, zCompanyCreateDto } from "api-shared";
import { TFunction } from "i18next";
import { useState } from "react";
import ActionItemDialog from "../../../components/dialogues/ActionItemDialog";
import CompanyForm from "./CompanyForm";

interface ICreateCompanyDialogProps {
    submitCallback: (company: CompanyCreateDto) => void;
    onClose: () => void;
    open: boolean;
    translate: TFunction;
}
const CreateCompanyDialog = ({ translate, open, submitCallback, onClose }: ICreateCompanyDialogProps) => {
    const [company, setCompany] = useState<CompanyCreateDto>({ name: "" });

    const isValid = zCompanyCreateDto.safeParse(company).success;

    const reset = () => {
        setCompany({ name: "" });
    };

    const submit = () => {
        submitCallback(company);
        reset();
    };

    const handleClose = () => {
        reset();
        onClose();
    };

    return (
        <ActionItemDialog
            open={open}
            onClose={handleClose}
            action="create"
            item="create_company"
            primary="create"
            primaryDisabled={!isValid}
            onPrimary={submit}
            translate={translate}
            title={translate("Create Company")}
        >
            <CompanyForm
                translate={translate}
                filledCallback={(isFilled: boolean, company?: CompanyCreateDto | CompanyUpdateDto) => {
                    if (company && typeof company.name === "string") {
                        setCompany(company as CompanyCreateDto);
                    }
                }}
                onSubmit={submit}
            />
        </ActionItemDialog>
    );
};

export default CreateCompanyDialog;
