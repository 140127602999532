import { AclPermissions, UserTier } from "api-shared";
import { TFunction } from "i18next";
import { translationKeys } from "../../../translations/main-translations";

export const getUserTierOption = (tier: UserTier, translate: TFunction) => ({
    value: tier,
    label: translate(`${translationKeys.VDLANG_USER_TIERS}.${tier}`),
});

export const getUserTierOptions = (t: TFunction) =>
    Object.values(UserTier)
        .filter((tier) => tier !== UserTier.SuperAdmin)
        .map((tier) => getUserTierOption(tier, t));

export const getReadUpdatePermissionOption = (permission: AclPermissions, translate: TFunction) => ({
    value: permission,
    label: translate(`${translationKeys.VDLANG_ACL_PERMISSIONS}.${permission}`),
});

export const getReadUpdatePermissionOptions = (t: TFunction) =>
    Object.values(AclPermissions)
        .filter((perm) => perm === AclPermissions.Read || perm === AclPermissions.Update)
        .map((perm) => getReadUpdatePermissionOption(perm, t));
