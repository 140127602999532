let lastReturnedId = 1;

/**
 * Generate a unique id. Can be used to assign ids to transient objects
 *
 * Ids are monotonically increasing.
 *
 * @export
 * @returns {number} the generated id
 */
export function generateId(): number {
    lastReturnedId++;
    return lastReturnedId;
}
