import { Breadcrumbs, Divider, Paper, Stack, Tab, Tabs, Tooltip, Typography, styled } from "@mui/material";
import { GroupDto, GroupType } from "api-shared";
import { SyntheticEvent, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import DefaultButton from "../../../components/DefaultButton";
import EditIcon from "../../../components/icons/EditIcon";
import InfoIcon from "../../../components/icons/InfoIcon";
import LoadingAnimation from "../../../components/loading/LoadingAnimation";
import { useGroups } from "../../../domain/admin/groups";
import { useAdminUsers } from "../../../domain/admin/users";
import useDialog from "../../../hooks/useDialog";
import { translationKeys } from "../../../translations/main-translations";
import { GroupDialog } from "./GroupDialog";

import { useNavigate, useParams } from "react-router-dom";
import { useLanguage } from "../../../hooks/useLanguage";
import { getFullGroupPath } from "../../../lib/groups";
import { RouteFor } from "../../../lib/routes";
import { translateFromProperty } from "../../../lib/translate";
import { GroupTable } from "./GroupTable";
import { UserTable } from "./UserTable";

const Root = styled(Stack)(({ theme }) => ({
    height: "100%",
}));

enum GroupTabs {
    Users = "groups_users",
    Subgroups = "groups_subgroups",
}

const TabWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(1, 3, 0, 3),
}));

const LinkStyleTypography = styled(Typography)(({ theme }) => ({
    cursor: "pointer",
    "&:hover": {
        textDecoration: "underline",
    },
}));

export const GroupSettings = () => {
    const { t } = useTranslation();
    const language = useLanguage();
    const navigate = useNavigate();

    const { groupId } = useParams<{ groupId: string }>();
    const currentGroupId = groupId !== undefined ? +groupId : null;

    const [activeTab, setActiveTab] = useState(GroupTabs.Users);
    const usersQuery = useAdminUsers();

    const changeTab = (e: SyntheticEvent, v: GroupTabs) => {
        setActiveTab(v);
    };

    const groupsQuery = useGroups();
    const currentGroupDialog = useDialog();

    const groupsToShow = useMemo(() => {
        return groupsQuery.data?.filter((group) => group.parentGroupId === currentGroupId) ?? [];
    }, [currentGroupId, groupsQuery.data]);

    if (!groupsQuery.isSuccess || !usersQuery.isSuccess) {
        return <LoadingAnimation />;
    }

    const currentGroup = groupsQuery.data.find((g) => g.id === currentGroupId) ?? null;
    const ancestorList: GroupDto[] = getFullGroupPath(groupsQuery.data, currentGroup);
    const maximumGroupDepthReached = ancestorList.length > 4;

    const handleGroupNavigate = (groupId: number) => {
        if (ancestorList.length > 3) {
            setActiveTab(GroupTabs.Users);
        }
        navigate(RouteFor.admin.forGroupId(groupId));
    };

    const isExtGroup = currentGroup?.type === GroupType.EXT_SCIM;
    const isSpecialGroup = currentGroup?.type !== GroupType.STANDARD;

    return (
        <Root spacing={2}>
            <Paper sx={{ height: "100%" }}>
                <Stack sx={{ height: "100%" }}>
                    {currentGroup !== null && (
                        <GroupDialog
                            key={currentGroup.id}
                            open={currentGroupDialog.isOpen}
                            onClose={() => currentGroupDialog.close()}
                            currentGroup={currentGroup}
                            parentGroupId={currentGroup.parentGroupId}
                        />
                    )}
                    <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 3, py: 2 }}>
                        <Breadcrumbs>
                            {ancestorList.length === 0 ? (
                                <Typography variant="subtitle1">{t(translationKeys.VDLANG_ADMIN_GROUPS_TITLE_ALLGROUPS)}</Typography>
                            ) : (
                                <LinkStyleTypography variant="subtitle1" onClick={() => navigate("/admin/groups")}>
                                    {t(translationKeys.VDLANG_ADMIN_GROUPS_TITLE_ALLGROUPS)}
                                </LinkStyleTypography>
                            )}
                            {ancestorList.map((p) => (
                                <LinkStyleTypography
                                    variant="subtitle1"
                                    key={p.id}
                                    onClick={() => navigate(RouteFor.admin.forGroupId(p.id))}
                                >
                                    {translateFromProperty(p, "name", language)}
                                </LinkStyleTypography>
                            ))}
                        </Breadcrumbs>
                        {currentGroupId !== null && (
                            <DefaultButton
                                variant="outlined"
                                color="inherit"
                                startIcon={<EditIcon />}
                                disabled={currentGroup == null}
                                onClick={() => currentGroupDialog.open()}
                            >
                                {t(translationKeys.VDLANG_ADMIN_GROUPS_DIALOG_UPDATE)}
                            </DefaultButton>
                        )}
                    </Stack>

                    {currentGroupId !== null && (
                        <Stack>
                            <TabWrapper>
                                <Tabs value={activeTab} onChange={changeTab}>
                                    <Tab
                                        key={GroupTabs.Users}
                                        label={`${t(translationKeys.VDLANG_ADMIN_GROUPS_USERS)} (${currentGroup?.userIds.length ?? 0})`}
                                        value={GroupTabs.Users}
                                    />
                                    <Tab
                                        key={GroupTabs.Subgroups}
                                        label={
                                            <span>
                                                <span>{t(translationKeys.VDLANG_ADMIN_GROUPS_SUBGROUPS)}</span>
                                                {!maximumGroupDepthReached || !isSpecialGroup ? (
                                                    <span>{` (${groupsToShow?.length ?? 0})`}</span>
                                                ) : null}
                                                {maximumGroupDepthReached || isSpecialGroup ? (
                                                    <Tooltip
                                                        title={
                                                            isSpecialGroup
                                                                ? isExtGroup
                                                                    ? t(translationKeys.VDLANG_ADMIN_GROUPS_EXT_EDIT_DISABLED)
                                                                    : t(translationKeys.VDLANG_ADMIN_GROUPS_MANAGED_EDIT_DISABLED)
                                                                : t(translationKeys.VDLANG_ADMIN_GROUPS_SUBGROUPS_DISABLED)
                                                        }
                                                        style={{ pointerEvents: "auto" }}
                                                    >
                                                        <span>
                                                            <InfoIcon sx={{ ml: 0.5 }} />
                                                        </span>
                                                    </Tooltip>
                                                ) : null}
                                            </span>
                                        }
                                        value={GroupTabs.Subgroups}
                                        disabled={maximumGroupDepthReached || isSpecialGroup}
                                    />
                                </Tabs>
                            </TabWrapper>
                        </Stack>
                    )}
                    <Divider />
                    {currentGroupId !== null && activeTab === GroupTabs.Users ? (
                        <UserTable allUsers={usersQuery.data} currentGroup={currentGroup} />
                    ) : (
                        <GroupTable
                            groupsToShow={groupsToShow}
                            allGroups={groupsQuery.data}
                            currentGroupLevel={currentGroupId}
                            onGroupNavigate={handleGroupNavigate}
                        />
                    )}
                </Stack>
            </Paper>
        </Root>
    );
};
