import { SupplierDto, UpdateSupplierAdminDto, UpdateSupplierAdminParamDto } from "api-shared";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ActionItemDialog from "../../../components/dialogues/ActionItemDialog";
import { translationKeys } from "../../../translations/main-translations";
import SupplierForm, { SupplierFormData } from "./SupplierForm";

interface EditSupplierDialogProps {
    open: boolean;
    onClose: () => void;
    supplier: SupplierDto;
    updateSupplier: (changes: UpdateSupplierAdminDto & UpdateSupplierAdminParamDto) => void;
}

const EditSupplierDialog = ({ open, onClose, supplier, updateSupplier, ...formProps }: EditSupplierDialogProps) => {
    const [isValid, setIsValid] = useState(true);
    const [changes, setChanges] = useState<Partial<SupplierFormData>>({});
    const [isSupplierNameInputTouched, setIsSupplierNameInputTouched] = useState(false);

    const { t: translate } = useTranslation();

    const handleSupplierChange = (newChanges: Partial<SupplierFormData>, isValid: boolean) => {
        setChanges((prev) => ({ ...prev, ...newChanges }));
        setIsValid(isValid);
        setIsSupplierNameInputTouched(true);
    };

    const handleClose = () => {
        setIsValid(true);
        setIsSupplierNameInputTouched(false);
        setChanges({});
        onClose();
    };

    const saveAndClose = () => {
        if (!isValid) {
            return;
        }

        const payload = {
            id: supplier.id,
            ...changes,
        };
        updateSupplier(payload);
        handleClose();
    };

    const updatedSupplier = {
        ...supplier,
        supplierNumber: supplier.supplierNumber ?? "",
        ...changes,
    };

    return (
        <ActionItemDialog
            open={open}
            onClose={handleClose}
            action="edit"
            item="partner"
            primary={translationKeys.VDLANG_SAVE}
            primaryDisabled={!isValid}
            onPrimary={saveAndClose}
            translate={translate}
        >
            <SupplierForm
                {...formProps}
                supplier={updatedSupplier}
                updateSupplier={handleSupplierChange}
                onSubmit={saveAndClose}
                isSupplierNameTouched={isSupplierNameInputTouched}
            />
        </ActionItemDialog>
    );
};

export default EditSupplierDialog;
