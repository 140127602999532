import WarningRounded from "@mui/icons-material/WarningRounded";
import { styled } from "@mui/material";
import { UserStatus } from "api-shared";
import { TFunction } from "i18next";
import moment from "moment";
import DateTz from "../../../components/DateTz";
import TableTextCell from "../../../components/table/TableTextCell";

interface IInvitedAtTableCellProps {
    status: UserStatus;
    translate: TFunction;
    invitedAt: Date | null | undefined;
}
const WarningIcon = styled(WarningRounded)(({ theme }) => ({
    position: "relative",
    top: theme.spacing(0.5),
    left: theme.spacing(0.5),
}));

const InvitedAtTableCell = ({ status, invitedAt, translate }: IInvitedAtTableCellProps) => {
    const isInvited = status === UserStatus.STATUS_INVITED;

    const warningDate = moment(invitedAt).add(2, "days");
    const expirationDate = moment(invitedAt).add(3, "days");

    const isInviteAlmostExpired = moment().isBetween(warningDate, expirationDate);
    const isInviteExpired = moment().isAfter(expirationDate);

    if (isInvited && isInviteAlmostExpired) {
        return (
            <TableTextCell title={translate("invite_almost_expired_hint")}>
                <DateTz date={invitedAt} hideTime />
                <WarningIcon color="secondary" />
            </TableTextCell>
        );
    } else if (isInvited && isInviteExpired) {
        return (
            <TableTextCell title={translate("invite_expired_hint")}>
                <DateTz date={invitedAt} hideTime />
                <WarningIcon color="error" />
            </TableTextCell>
        );
    } else {
        return (
            <TableTextCell>
                <DateTz date={invitedAt} hideTime />
            </TableTextCell>
        );
    }
};

export default InvitedAtTableCell;
