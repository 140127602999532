import AddIcon from "@mui/icons-material/AddRounded";
import { Box, Button, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import LanguageSelect from "../../../../components/input/select/LanguageSelect";
import { Language, translationKeys } from "../../../../translations/main-translations";

interface TreeNodeActionsProps {
    language: Language;
    onLanguageChange: (value: Language | null) => void;
    onAdd: () => void;
}

const TreeNodeControls = ({ language, onLanguageChange, onAdd }: TreeNodeActionsProps) => {
    const { t } = useTranslation();

    return (
        <Stack py={1.5} px={3} direction="row" justifyContent="space-between" alignItems="center" spacing={3}>
            <LanguageSelect label={null} translate={t} onChange={onLanguageChange} value={language} margin="none" />
            <Box flexGrow={1} />
            <Button variant="contained" startIcon={<AddIcon />} onClick={onAdd}>
                {t(translationKeys.VDLANG_TREE_NODE_ADD_NODE)}
            </Button>
        </Stack>
    );
};

export default TreeNodeControls;
