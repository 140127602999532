import { TableHeaderRow } from "@devexpress/dx-react-grid-material-ui";
import { styled } from "@mui/material";

const DataGridHeaderCell = styled((props: TableHeaderRow.CellProps) => <TableHeaderRow.Cell {...props} />)(({ theme }) => ({
    "&.CellLayout-cell": {
        padding: theme.spacing(1, 0),
    },
    "&:first-child .CellLayout-container": {
        paddingLeft: 0,
    },
    "&:last-child .CellLayout-container": {
        // hide inset border for last child and columns with full border
        borderRight: "none",
    },
    "& .CellLayout-container": {
        padding: theme.spacing(0, 2),
        borderRight: `1px solid ${theme.palette.divider}`,
    },
}));

export default DataGridHeaderCell;
