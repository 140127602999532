import { FormControlLabel, FormGroup, Switch, TextField } from "@mui/material";
import { TreeNodeDto, zMinMaxShortText } from "api-shared";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { z } from "zod";
import Form from "../../../../../components/Form";
import ActionItemDialog from "../../../../../components/dialogues/ActionItemDialog";
import InfoIcon from "../../../../../components/icons/InfoIcon";
import { useAdminUpdateTreeNode } from "../../../../../domain/admin/tree-nodes";
import { translationKeys } from "../../../../../translations/main-translations";

interface EditTreeNodeDialogProps {
    treeNode: TreeNodeDto;
    open: boolean;
    onClose: () => void;
}

const zNameField = z.string().trim().pipe(zMinMaxShortText);

const EditTreeNodeDialog = ({ treeNode, open, onClose }: EditTreeNodeDialogProps) => {
    const { t: translate } = useTranslation();
    const [nameDe, setNameDe] = useState(treeNode.nameDe);
    const [nameEn, setNameEn] = useState(treeNode.nameEn);
    const [selectable, setSelectable] = useState(treeNode.selectable);

    const parsedNameDe = zNameField.safeParse(nameDe);
    const parsedNameEn = zNameField.safeParse(nameEn);

    const isValid = parsedNameDe.success && parsedNameEn.success;

    const updateAdminTreeNode = useAdminUpdateTreeNode().mutate;

    const onSave = () => {
        if (!isValid) {
            return;
        }
        updateAdminTreeNode(
            {
                treeNodeId: treeNode.id,
                body: {
                    nameDe: parsedNameDe.data,
                    nameEn: parsedNameEn.data,
                    selectable,
                },
            },
            { onSuccess: onClose },
        );
    };

    return (
        <ActionItemDialog
            action="update"
            item="tree_node"
            primary={translationKeys.VDLANG_SAVE}
            onPrimary={onSave}
            translate={translate}
            open={open}
            onClose={onClose}
            primaryDisabled={!isValid}
        >
            <Form onSubmit={onSave}>
                <FormGroup>
                    <TextField
                        required
                        label={translate("displaynameDe")}
                        name="nameDe"
                        value={nameDe}
                        onChange={(event) => setNameDe(event.target.value)}
                        margin="normal"
                        error={!parsedNameDe.success}
                    />
                    <TextField
                        required
                        label={translate("displaynameEn")}
                        name="nameEn"
                        value={nameEn}
                        onChange={(event) => setNameEn(event.target.value)}
                        margin="normal"
                        error={!parsedNameEn.success}
                    />

                    {treeNode.children.length > 0 ? (
                        <FormControlLabel
                            name="selectable"
                            control={<Switch value={1} checked={selectable} onChange={() => setSelectable(!selectable)} />}
                            label={
                                <>
                                    {`${translate(translationKeys.VDLANG_TREE_NODE_EDIT_SELECTABLE_LABEL)} `}
                                    <InfoIcon title={<Trans i18nKey={translationKeys.VDLANG_TREE_NODE_EDIT_SELECTABLE_HINT} />} />
                                </>
                            }
                        />
                    ) : null}
                </FormGroup>
            </Form>
        </ActionItemDialog>
    );
};

export default EditTreeNodeDialog;
