import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
    CreateDepartmentRequestBody,
    DepartmentDto,
    DepartmentListDto,
    UpdateDeleteDepartmentRequestParams,
    UpdateDepartmentRequestBody,
} from "api-shared";
import { useDispatch } from "react-redux";
import { showNotificationEvent } from "../../infrastructure/notifications";
import { apiDelete, apiGet, apiPatch, apiPost } from "../../lib/api";
import { NotificationType } from "../../lib/notifications";
import { FeedbackTranslationKeys } from "../../translations/notification-translations";

const ADMIN_DEPARTMENTS_PATH = "admin/departments";

export const useAdminDepartments = () => {
    return useQuery({
        queryKey: [ADMIN_DEPARTMENTS_PATH],
        queryFn: ({ signal }) => apiGet<DepartmentListDto>(ADMIN_DEPARTMENTS_PATH, { signal }),
    });
};

export const useAdminAddDepartment = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: (data: CreateDepartmentRequestBody) => apiPost<DepartmentDto>(ADMIN_DEPARTMENTS_PATH, data),
        onSuccess: () => {
            queryClient.invalidateQueries([ADMIN_DEPARTMENTS_PATH]);
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_DEPARTMENT_CREATED));
        },
    });
};

export const useAdminDeleteDepartment = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: (id: number) => apiDelete(`${ADMIN_DEPARTMENTS_PATH}/${id}`),
        onSuccess: () => {
            queryClient.invalidateQueries([ADMIN_DEPARTMENTS_PATH]);
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_DEPARTMENT_DELETED));
        },
    });
};

export const useAdminUpdateDepartment = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: ({ id, ...changes }: UpdateDepartmentRequestBody & UpdateDeleteDepartmentRequestParams) =>
            apiPatch<DepartmentDto>(`${ADMIN_DEPARTMENTS_PATH}/${id}`, changes),
        onSuccess: () => {
            queryClient.invalidateQueries([ADMIN_DEPARTMENTS_PATH]);
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_DEPARTMENT_UPDATED));
        },
    });
};
