import { zodResolver } from "@hookform/resolvers/zod";
import { Checkbox, FormControlLabel, FormGroup, Stack, styled, Typography } from "@mui/material";
import {
    AclConditionStar,
    AclNamespaces,
    AclPermissions,
    AclRule,
    CreateAclsRequestBody,
    FeatureFlags,
    GroupDto,
    UserDto,
    UserTier,
} from "api-shared";
import { Controller, Resolver, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import Alert from "../../../components/Alert";
import ActionItemDialog from "../../../components/dialogues/ActionItemDialog";
import Tooltip from "../../../components/Tooltip";
import { useClientHasFeature } from "../../../domain/client";
import { translationKeys } from "../../../translations/main-translations";
import { DialogForm } from "./DialogForm";
import { GroupSelect } from "./Inputs/GroupSelect";
import { UserSelect } from "./Inputs/UserSelect";
import { zFormUserGroupSchema } from "./PermissionsConstants";

type FormValues = Pick<CreateAclsRequestBody, "userIds" | "groupIds"> & {
    api: boolean;
    idea: boolean;
    activity: boolean;
    dashboard: boolean;
    feed: boolean;
    method: boolean;
    process: boolean;
    admin: boolean;
};

type AccessPermissionDialogProps = {
    open: boolean;
    onClose: () => void;
    users: UserDto[];
    groups: GroupDto[];
    onPermissionSubmit: (data: CreateAclsRequestBody) => void;
};

const customResolver: Resolver<FormValues> = async (values, context, options) => {
    if (
        !values.api &&
        !values.idea &&
        !values.activity &&
        !values.dashboard &&
        !values.feed &&
        !values.method &&
        !values.process &&
        !values.admin
    ) {
        return {
            values,
            errors: { read: "At least one permission type is required", write: "At least one permission type is required" },
        };
    }
    const zod = zodResolver(
        zFormUserGroupSchema.and(
            z.object({
                api: z.boolean(),
                idea: z.boolean(),
                activity: z.boolean(),
                dashboard: z.boolean(),
                feed: z.boolean(),
                method: z.boolean(),
                process: z.boolean(),
                admin: z.boolean(),
            }),
        ),
    );
    return zod(values, context, options);
};

const ReducedPaddingCheckbox = styled(Checkbox)(({ theme }) => ({
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
}));

export const AccessPermissionDialog = ({
    open = false,
    onClose = () => {},
    users,
    groups,
    onPermissionSubmit,
}: AccessPermissionDialogProps) => {
    const { t } = useTranslation();
    const {
        handleSubmit,
        control,
        formState: { isValid },
        reset,
    } = useForm<FormValues>({
        mode: "onChange",
        resolver: customResolver,
        defaultValues: {
            userIds: [],
            groupIds: [],
            api: false,
            idea: false,
            activity: false,
            dashboard: false,
            feed: false,
            method: false,
            process: false,
            admin: false,
        },
    });

    const hasIdeaFeature = useClientHasFeature(FeatureFlags.FEATURE_IDEA_SECTION);
    const hasApiFeature = useClientHasFeature(FeatureFlags.FEATURE_INTEGRATIONS);
    const hasMethodFeature = useClientHasFeature(FeatureFlags.FEATURE_METHOD_SECTION);

    const createPermissionAcl = (data: FormValues, namespace: AclNamespaces) => {
        const rule: AclRule = [];
        rule.push(AclConditionStar);

        onPermissionSubmit({
            groupIds: data.groupIds,
            userIds: data.userIds,
            acl: {
                namespace: namespace,
                permission: AclPermissions.Access,
                rule: rule,
                simpleEntityId: null,
            },
        });
    };

    const onSubmit = handleSubmit((data) => {
        if (data.api && hasApiFeature) {
            createPermissionAcl(data, AclNamespaces.Api);
        }
        if (data.idea && hasIdeaFeature) {
            createPermissionAcl(data, AclNamespaces.Idea);
        }
        if (data.activity) {
            createPermissionAcl(data, AclNamespaces.Activity);
        }
        if (data.dashboard) {
            createPermissionAcl(data, AclNamespaces.Dashboard);
        }
        if (data.feed) {
            createPermissionAcl(data, AclNamespaces.Feed);
        }
        if (data.method && hasMethodFeature) {
            createPermissionAcl(data, AclNamespaces.Method);
        }
        if (data.process) {
            createPermissionAcl(data, AclNamespaces.Process);
        }
        if (data.admin) {
            createPermissionAcl(data, AclNamespaces.Admin);
        }

        onClose();
    });

    const onCancel = () => {
        onClose();
        reset();
    };

    return (
        <ActionItemDialog
            open={open}
            title={`${t(translationKeys.VDLANG_ACL_ADD_RULE)} - ${t(translationKeys.VDLANG_ACL_TITLE_ACCESS_PERMISSION)}`}
            primary={t("Create")}
            primaryIsTranslated
            onPrimary={onSubmit}
            primaryDisabled={!isValid}
            onClose={onCancel}
            translate={t}
            disableContentPadding
            maxWidth="sm"
        >
            <DialogForm onSubmit={onSubmit} style={{ margin: 0 }}>
                <Stack gap={1}>
                    <Alert severity="info">{t(translationKeys.VDLANG_ACL_HINT_ACCESS_PERMISSION)}</Alert>

                    <Typography variant="subtitle2" pt={2}>
                        {t(`${translationKeys.VDLANG_USER_TIERS}.${UserTier.Light}`)}
                    </Typography>
                    <Controller
                        name="feed"
                        control={control}
                        render={({ field }) => {
                            const { value, onChange } = field;
                            return (
                                <FormGroup>
                                    <Tooltip title={t(translationKeys.VDLANG_ACL_ACCESS_PERMISSION_FEED_HINT)}>
                                        <FormControlLabel
                                            control={
                                                <ReducedPaddingCheckbox
                                                    size="small"
                                                    checked={value}
                                                    onChange={(_, checked) => onChange(checked)}
                                                />
                                            }
                                            label={t(translationKeys.VDLANG_ACL_ACCESS_PERMISSION_FEED)}
                                        />
                                    </Tooltip>
                                </FormGroup>
                            );
                        }}
                    />
                    {hasIdeaFeature && (
                        <Controller
                            name="idea"
                            control={control}
                            render={({ field }) => {
                                const { value, onChange } = field;
                                return (
                                    <FormGroup>
                                        <Tooltip title={t(translationKeys.VDLANG_ACL_ACCESS_PERMISSION_IDEA_HINT)}>
                                            <FormControlLabel
                                                control={
                                                    <ReducedPaddingCheckbox
                                                        size="small"
                                                        checked={value}
                                                        onChange={(_, checked) => onChange(checked)}
                                                    />
                                                }
                                                label={t(translationKeys.VDLANG_ACL_ACCESS_PERMISSION_IDEA)}
                                            />
                                        </Tooltip>
                                    </FormGroup>
                                );
                            }}
                        />
                    )}

                    <Typography variant="subtitle2" pt={2}>
                        {t(`${translationKeys.VDLANG_USER_TIERS}.${UserTier.Basic}`)}
                    </Typography>
                    <Controller
                        name="activity"
                        control={control}
                        render={({ field }) => {
                            const { value, onChange } = field;
                            return (
                                <FormGroup>
                                    <Tooltip title={t(translationKeys.VDLANG_ACL_ACCESS_PERMISSION_ACTIVITY_HINT)}>
                                        <FormControlLabel
                                            control={
                                                <ReducedPaddingCheckbox
                                                    size="small"
                                                    checked={value}
                                                    onChange={(_, checked) => onChange(checked)}
                                                />
                                            }
                                            label={t(translationKeys.VDLANG_ACL_ACCESS_PERMISSION_ACTIVITY)}
                                        />
                                    </Tooltip>
                                </FormGroup>
                            );
                        }}
                    />
                    <Controller
                        name="process"
                        control={control}
                        render={({ field }) => {
                            const { value, onChange } = field;
                            return (
                                <FormGroup>
                                    <Tooltip title={t(translationKeys.VDLANG_ACL_ACCESS_PERMISSION_PROCESS_HINT)}>
                                        <FormControlLabel
                                            control={
                                                <ReducedPaddingCheckbox
                                                    size="small"
                                                    checked={value}
                                                    onChange={(_, checked) => onChange(checked)}
                                                />
                                            }
                                            label={t(translationKeys.VDLANG_ACL_ACCESS_PERMISSION_PROCESS)}
                                        />
                                    </Tooltip>
                                </FormGroup>
                            );
                        }}
                    />

                    <Typography variant="subtitle2" pt={2}>
                        {t(`${translationKeys.VDLANG_USER_TIERS}.${UserTier.Advanced}`)}
                    </Typography>
                    <Controller
                        name="dashboard"
                        control={control}
                        render={({ field }) => {
                            const { value, onChange } = field;
                            return (
                                <FormGroup>
                                    <Tooltip title={t(translationKeys.VDLANG_ACL_ACCESS_PERMISSION_DASHBOARD_HINT)}>
                                        <FormControlLabel
                                            control={
                                                <ReducedPaddingCheckbox
                                                    size="small"
                                                    checked={value}
                                                    onChange={(_, checked) => onChange(checked)}
                                                />
                                            }
                                            label={t(translationKeys.VDLANG_ACL_ACCESS_PERMISSION_DASHBOARD)}
                                        />
                                    </Tooltip>
                                </FormGroup>
                            );
                        }}
                    />
                    {hasMethodFeature && (
                        <Controller
                            name="method"
                            control={control}
                            render={({ field }) => {
                                const { value, onChange } = field;
                                return (
                                    <FormGroup>
                                        <Tooltip title={t(translationKeys.VDLANG_ACL_ACCESS_PERMISSION_METHOD_HINT)}>
                                            <FormControlLabel
                                                control={
                                                    <ReducedPaddingCheckbox
                                                        size="small"
                                                        checked={value}
                                                        onChange={(_, checked) => onChange(checked)}
                                                    />
                                                }
                                                label={t(translationKeys.VDLANG_ACL_ACCESS_PERMISSION_METHOD)}
                                            />
                                        </Tooltip>
                                    </FormGroup>
                                );
                            }}
                        />
                    )}
                    {hasApiFeature && (
                        <Controller
                            name="api"
                            control={control}
                            render={({ field }) => {
                                const { value, onChange } = field;
                                return (
                                    <FormGroup>
                                        <Tooltip title={t(translationKeys.VDLANG_ACL_ACCESS_PERMISSION_API_HINT)}>
                                            <FormControlLabel
                                                control={
                                                    <ReducedPaddingCheckbox
                                                        size="small"
                                                        checked={value}
                                                        onChange={(_, checked) => onChange(checked)}
                                                    />
                                                }
                                                label={t(translationKeys.VDLANG_ACL_ACCESS_PERMISSION_API)}
                                            />
                                        </Tooltip>
                                    </FormGroup>
                                );
                            }}
                        />
                    )}

                    <Typography variant="subtitle2" pt={2}>
                        {t(`${translationKeys.VDLANG_USER_TIERS}.${UserTier.Admin}`)}
                    </Typography>
                    <Controller
                        name="admin"
                        control={control}
                        render={({ field }) => {
                            const { value, onChange } = field;
                            return (
                                <FormGroup>
                                    <Tooltip title={t(translationKeys.VDLANG_ACL_ACCESS_PERMISSION_ADMIN_HINT)}>
                                        <FormControlLabel
                                            control={
                                                <ReducedPaddingCheckbox
                                                    size="small"
                                                    checked={value}
                                                    onChange={(_, checked) => onChange(checked)}
                                                />
                                            }
                                            label={t(translationKeys.VDLANG_ACL_ACCESS_PERMISSION_ADMIN)}
                                        />
                                    </Tooltip>
                                </FormGroup>
                            );
                        }}
                    />

                    <Controller
                        name="groupIds"
                        control={control}
                        render={({ field }) => {
                            const { value, onChange } = field;
                            return <GroupSelect onChange={onChange} value={value} groups={groups} />;
                        }}
                    />
                    <Controller
                        name="userIds"
                        control={control}
                        render={({ field }) => {
                            const { value, onChange } = field;
                            return <UserSelect value={value} onChange={onChange} users={users} />;
                        }}
                    />
                </Stack>
            </DialogForm>
        </ActionItemDialog>
    );
};
