import { TreeNodeDto } from "api-shared";

function hasParentTreeNode(treeNodeId: number | undefined, treeNodes: TreeNodeDto[]): boolean {
    if (treeNodeId === undefined) {
        return false;
    }

    return treeNodes.some((treeNode) => {
        if (treeNode.children && treeNode.children.length > 0) {
            return treeNode.children.some((child) => child.id === treeNodeId) || hasParentTreeNode(treeNodeId, treeNode.children);
        }
        return false;
    });
}

export default hasParentTreeNode;
