import ArrowDownIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { Button, Divider, MenuItem, MenuList as MuiMenuList, Popover, styled, Typography } from "@mui/material";
import { AdminAttributeDto } from "api-shared";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import SearchInput from "../../../../components/input/SearchInput";
import useMenu from "../../../../hooks/useMenu";
import { translationKeys } from "../../../../translations/main-translations";

const MenuList = styled(MuiMenuList)(({ theme }) => ({
    width: theme.spacing(40),
    paddingTop: 0,
}));

const TitleButton = styled(Button)(({ theme }) => ({
    padding: theme.spacing(1.5, 3),
}));

interface TreeNodeTitleProps {
    attributes: AdminAttributeDto[];
    selectedAttributeId: number | null;
    onSelect: (newAttributeId: number) => void;
}

const AttributeTitleSelect = ({ attributes, selectedAttributeId, onSelect }: TreeNodeTitleProps) => {
    const { t } = useTranslation();
    const [searchKey, setSearchKey] = useState("");
    const menu = useMenu({ onClose: () => setSearchKey("") });

    const filteredAttributes = attributes.filter(({ title }) => {
        return title.toLowerCase().indexOf(searchKey.toLowerCase()) >= 0;
    });

    const selectedAttribute = filteredAttributes.find(({ id }) => id === selectedAttributeId) ?? null;

    function handleChange(attributeId: number) {
        menu.close();
        onSelect(attributeId);
        setSearchKey("");
    }

    if (attributes.length === 0) {
        return (
            <Typography variant="h5" noWrap px={3} py={1.5}>
                {t(translationKeys.VDLANG_ADMIN_SIDEBAR_DATA_TREE_NODES)}
            </Typography>
        );
    }

    return (
        <>
            <Popover {...menu.menuProps}>
                <SearchInput translate={t} onChange={setSearchKey} searchKey={searchKey} autoFocus sx={{ p: 1 }} />
                <Divider />
                <MenuList dense>
                    {filteredAttributes.map(({ id, title }) => (
                        <MenuItem key={id} onClick={() => handleChange(id)}>
                            <Typography>{title}</Typography>
                        </MenuItem>
                    ))}
                </MenuList>
            </Popover>
            <TitleButton variant="text" color="inherit" onClick={menu.open} endIcon={<ArrowDownIcon color="inherit" />}>
                <Typography variant="subtitle1" noWrap>
                    {selectedAttribute === null ? t(translationKeys.VDLANG_ADMIN_SIDEBAR_DATA_TREE_NODES) : selectedAttribute.title}
                </Typography>
            </TitleButton>
        </>
    );
};

export default AttributeTitleSelect;
