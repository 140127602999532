import { Divider, Paper, Stack, styled, Typography } from "@mui/material";
import { AttributeTable } from "api-shared";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import LoadingAnimation from "../../../../components/loading/LoadingAnimation";
import { useAdminAttributes } from "../../../../domain/admin/attributes";
import NoTree from "../../../../static/images/trees.svg";
import { translationKeys } from "../../../../translations/main-translations";
import AttributeTitleSelect from "./AttributeTitleSelect";
import ManageTreeNodes from "./ManageTreeNodes";

const Root = styled(Paper)({ height: "100%" });

const ContentStack = styled(Stack)({ height: "100%" });

const Image = styled("img")(({ theme }) => ({
    maxHeight: theme.spacing(25),
    maxWidth: "100%",
}));

const TreeNodeSettings = () => {
    const { t } = useTranslation();
    const attributes = useAdminAttributes();
    const [selectedAttributeId, setSelectedAttributeId] = useState<number | null>(null);
    const treeAttributes =
        attributes.data
            ?.filter((attribute) => attribute.tableName === AttributeTable.TreeNodes)
            .map((attribute) => ({
                ...attribute,
                title: t(attribute.title),
            })) ?? [];

    const resolvedSelectedAttributeId = selectedAttributeId ?? treeAttributes[0]?.id ?? null;
    const selectedAttribute = treeAttributes.find(({ id }) => id === resolvedSelectedAttributeId) ?? null;

    return (
        <Root>
            <ContentStack divider={<Divider />}>
                <Stack direction="row" py={0.5}>
                    {/* Use stack to avoid AttributeTitleSelect stretching full-width */}
                    <AttributeTitleSelect
                        attributes={treeAttributes}
                        selectedAttributeId={resolvedSelectedAttributeId}
                        onSelect={setSelectedAttributeId}
                    />
                </Stack>
                {selectedAttribute == null ? (
                    <Stack height="100%" alignItems="center" justifyContent="center" spacing={2}>
                        {attributes.isLoading && <LoadingAnimation />}
                        {attributes.isSuccess && (
                            <>
                                <Image src={NoTree} alt="No Tree" />
                                <Typography>{t(translationKeys.VDLANG_TREE_NODE_NO_ATTRIBUTE)}</Typography>
                            </>
                        )}
                    </Stack>
                ) : (
                    <ManageTreeNodes attributeId={selectedAttribute.id} />
                )}
            </ContentStack>
        </Root>
    );
};

export default TreeNodeSettings;
