import { Box, Stack, Typography, styled } from "@mui/material";
import { TreeNodeDto } from "api-shared";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Form from "../../../../../components/Form";
import ActionItemDialog from "../../../../../components/dialogues/ActionItemDialog";
import { useAdminUpdateTreeNode } from "../../../../../domain/admin/tree-nodes";
import { translateFromProperty } from "../../../../../lib/translate";
import { Language, translationKeys } from "../../../../../translations/main-translations";
import hasParentTreeNode from "../../util/hasParentTreeNode";
import recursiveTreeSearch from "../../util/recursiveTreeSearch";
import TreeNodes from "../../../../../components/TreeNodes";

const NewParentNodeDisplay = styled(Box)(({ theme }) => ({
    padding: theme.spacing(1),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.default,
}));

interface IMoveTreeNodeDialogProps {
    treeNode: TreeNodeDto;
    treeNodes: TreeNodeDto[];
    open: boolean;
    onClose: () => void;
    language: Language;
}

const MoveTreeNodeDialog = ({ language, open, onClose, treeNode, treeNodes }: IMoveTreeNodeDialogProps) => {
    const { t } = useTranslation();
    const [newParent, setNewParent] = useState<number | null>(null);
    const moveTreeNode = useAdminUpdateTreeNode().mutate;
    const hasParent = useMemo(() => hasParentTreeNode(treeNode.id, treeNodes), [treeNode, treeNodes]);

    const handleSelect = (_event: React.SyntheticEvent, nodeId: number | null) => {
        setNewParent(nodeId);
    };

    function resetDialog() {
        setNewParent(null);
    }

    function onMove(parentId: number | null) {
        moveTreeNode(
            { treeNodeId: treeNode.id, body: { parentId } },
            {
                onSuccess: () => {
                    resetDialog();
                    onClose();
                },
            },
        );
    }

    function handleSubmit() {
        onMove(newParent);
    }

    const newParentTreeNode = useMemo(() => (newParent != null ? recursiveTreeSearch(treeNodes, newParent) : null), [newParent, treeNodes]);

    const parentName = newParentTreeNode != null ? translateFromProperty(newParentTreeNode, "name", language) : null;

    return (
        <ActionItemDialog
            action="move"
            item="tree_node"
            primary="move"
            onPrimary={handleSubmit}
            tertiary={translationKeys.VDLANG_TREE_NODE_MOVE_TO_ROOT}
            onTertiary={() => onMove(null)}
            tertiaryDisabled={!hasParent && newParent === null}
            translate={t}
            open={open}
            onClose={() => {
                resetDialog();
                onClose();
            }}
        >
            <Form onSubmit={handleSubmit}>
                <Stack spacing={2}>
                    <Typography>
                        {t(translationKeys.VDLANG_TREE_NODE_MOVE_DESCRIPTION, {
                            nodeName: translateFromProperty(treeNode, "name", language),
                        })}
                    </Typography>
                    {newParentTreeNode !== undefined && (
                        <Stack spacing={1}>
                            <Typography fontWeight="bold">{t(translationKeys.VDLANG_TREE_NODE_NEW_PARENT)}:</Typography>
                            <NewParentNodeDisplay>
                                <Typography>{parentName ?? t(translationKeys.VDLANG_TREE_NODE_NO_PARENT_SELECTED)}</Typography>
                            </NewParentNodeDisplay>
                        </Stack>
                    )}
                    <Stack maxHeight={500}>
                        <TreeNodes
                            language={language}
                            selected={newParent}
                            disabledItems={[treeNode.id]}
                            handleSelect={handleSelect}
                            treeNodes={treeNodes}
                        />
                    </Stack>
                </Stack>
            </Form>
        </ActionItemDialog>
    );
};

export default MoveTreeNodeDialog;
