import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
    CreateTreeNodeDto,
    DeleteTreeNodeDto,
    NumericIdParam,
    UpdateTreeNodeDto,
    TreeNodeDto,
    TreeNodeListDto,
    UpdateTreeNodeOrderRequestBody,
} from "api-shared";
import { useDispatch } from "react-redux";
import { showNotificationEvent } from "../../infrastructure/notifications";
import { apiDelete, apiGet, apiPatch, apiPost } from "../../lib/api";
import { NotificationType } from "../../lib/notifications";
import { FeedbackTranslationKeys } from "../../translations/notification-translations";

const ADMIN_TREE_NODES_PATH = "admin/tree-nodes";

export const AdminTreeNodeQueryKeys = {
    forAttribute: (attributeId: number) => [ADMIN_TREE_NODES_PATH, attributeId] as const,
};

export const useAdminTreeNodes = (attributeId: number) => {
    return useQuery({
        queryKey: AdminTreeNodeQueryKeys.forAttribute(attributeId),
        queryFn: ({ queryKey, signal }) => {
            return apiGet<TreeNodeListDto>(`${ADMIN_TREE_NODES_PATH}?attributeId=${queryKey[1]}`, { signal });
        },
    });
};

export const useAdminCreateTreeNode = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: (data: CreateTreeNodeDto) => apiPost<TreeNodeDto>(`${ADMIN_TREE_NODES_PATH}`, data),
        onSuccess: (data) => {
            queryClient.invalidateQueries(AdminTreeNodeQueryKeys.forAttribute(data.attributeId));
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_TREE_NODE_CREATED_SUCCESS));
        },
    });
};

type DeleteTreeNodeInput = DeleteTreeNodeDto & NumericIdParam & { attributeId: number };

export const useAdminDeleteTreeNode = (skipReportToSentry = false) => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: (data: DeleteTreeNodeInput) =>
            apiDelete<TreeNodeDto>(`${ADMIN_TREE_NODES_PATH}/${data.id}`, { replaceValue: data.replaceValue }),
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries(AdminTreeNodeQueryKeys.forAttribute(variables.attributeId));
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_TREE_NODE_DELETED_SUCCESS));
        },
        meta: {
            skipReportToSentry,
        },
    });
};

interface UpdateTreeNodesInputDto {
    treeNodeId: number;
    body: UpdateTreeNodeDto;
}

export const useAdminUpdateTreeNode = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: (data: UpdateTreeNodesInputDto) => apiPatch<TreeNodeDto>(`${ADMIN_TREE_NODES_PATH}/${data.treeNodeId}`, data.body),
        onSuccess: (data) => {
            queryClient.invalidateQueries(AdminTreeNodeQueryKeys.forAttribute(data.attributeId));
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_TREE_NODE_UPDATED_SUCCESS));
        },
    });
};

export const useAdminReorderTreeNodes = (attributeId: number) => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: (data: UpdateTreeNodeOrderRequestBody) => apiPost(`${ADMIN_TREE_NODES_PATH}/change-order`, data),
        onSuccess: () => {
            queryClient.invalidateQueries(AdminTreeNodeQueryKeys.forAttribute(attributeId));
            dispatch(
                showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_TREE_NODE_ORDER_UPDATED_SUCCESS),
            );
        },
    });
};
