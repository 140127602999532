import { IdentityProviderConfig } from "api-shared";

function nsResolver(prefix: string | null) {
    if (!prefix) {
        return null;
    }

    const ns: { [key: string]: string } = {
        ds: "http://www.w3.org/2000/09/xmldsig#",
        md: "urn:oasis:names:tc:SAML:2.0:metadata",
    };

    return ns[prefix] ?? null;
}

function getByXPath(xmlDoc: Document, xPath: string) {
    try {
        const result = xmlDoc.evaluate(xPath, xmlDoc, nsResolver, XPathResult.ANY_TYPE, null);
        const y = result.iterateNext();
        return y?.textContent;
    } catch (e) {
        return undefined;
    }
}

/**
 * Try to parse the to json converted object according to the OASIS specs
 * https://docs.oasis-open.org/security/saml/v2.0/saml-metadata-2.0-os.pdf
 * https://github.com/italia/spid-perl/blob/master/lib/Net/SPID/SAML/IdP.pm
 */
export async function xmlToConfig(xml: string, isValuedeskXml: boolean): Promise<IdentityProviderConfig> {
    const xmlDoc = new DOMParser().parseFromString(xml, "application/xml");

    const xPathX509Certificate =
        '/md:EntityDescriptor/md:IDPSSODescriptor/md:KeyDescriptor[@use="signing"]/ds:KeyInfo/ds:X509Data/ds:X509Certificate/text()';
    const x509Certificate = getByXPath(xmlDoc, xPathX509Certificate);

    const xPathEntryPoint =
        '/md:EntityDescriptor/md:IDPSSODescriptor/md:SingleSignOnService[@Binding="urn:oasis:names:tc:SAML:2.0:bindings:HTTP-Redirect"]/@Location';
    const entryPoint = getByXPath(xmlDoc, xPathEntryPoint);

    return {
        issuer: isValuedeskXml ? "valuedesk" : "",
        entryPoint: entryPoint ?? "",
        cert: x509Certificate ?? "",
    };
}
