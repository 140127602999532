import { TableHeaderRow } from "@devexpress/dx-react-grid-material-ui";
import { styled } from "@mui/material";
import DataGridHeaderCell from "../../../components/datagrid/DataGridHeaderCell";
import { isDividerColumn } from "./CurrencyTable";

const CurrencyTableHeaderCell = styled((props: TableHeaderRow.CellProps) => <DataGridHeaderCell {...props} />)(({ theme, column }) => ({
    ...(isDividerColumn(column) && {
        borderRight: `2px solid ${theme.palette.divider}`,
        " .CellLayout-container": {
            // hide inset border for columns with full border
            borderRight: "none",
        },
    }),
}));

export default CurrencyTableHeaderCell;
