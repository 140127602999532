import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
    AclListDto,
    AclNamespaces,
    AclPermissions,
    AclSubjectType,
    EntityPermissionRequestBody,
    type CreateAclsRequestBody,
} from "api-shared";
import queryString from "query-string";
import { useDispatch } from "react-redux";
import { showNotificationEvent } from "../../infrastructure/notifications";
import { apiDelete, apiGet, apiPost } from "../../lib/api";
import { NotificationType } from "../../lib/notifications";
import { FeedbackTranslationKeys } from "../../translations/notification-translations";

const ADMIN_PERMISSIONS_PATH = "admin/permissions";

export const PermissionsQueryKeys = {
    all: ["permissions"] as const,
    directPermissionList: (
        showAllRules: boolean,
        namespacesFilter?: AclNamespaces[],
        permissionsFilter?: AclPermissions[],
        groupIdsFilter?: number[],
        userIdsFilter?: number[],
    ) =>
        [
            ...PermissionsQueryKeys.all,
            `${ADMIN_PERMISSIONS_PATH}/direct`,
            showAllRules,
            namespacesFilter,
            permissionsFilter,
            groupIdsFilter,
            userIdsFilter,
        ] as const,
    effectivePermissionList: (showAllRules: boolean, aclSubjectId?: number, aclSubjectType?: AclSubjectType) =>
        [...PermissionsQueryKeys.all, `${ADMIN_PERMISSIONS_PATH}/effective`, showAllRules, aclSubjectId, aclSubjectType] as const,
    entityPermissions: (data: EntityPermissionRequestBody) => ["entityPermissions", data] as const,
};

export const useAdminDirectPermissions = (
    showAllRules: boolean,
    namespacesFilter?: AclNamespaces[],
    permissionsFilter?: AclPermissions[],
    groupIdsFilter?: number[],
    userIdsFilter?: number[],
) => {
    return useQuery({
        queryKey: PermissionsQueryKeys.directPermissionList(
            showAllRules,
            namespacesFilter,
            permissionsFilter,
            groupIdsFilter,
            userIdsFilter,
        ),
        queryFn: ({ signal }) => {
            const params = {
                showAllRules,
                namespaces: namespacesFilter,
                permissions: permissionsFilter,
                groupIds: groupIdsFilter,
                userIds: userIdsFilter,
            };
            return apiGet<AclListDto>(`${ADMIN_PERMISSIONS_PATH}/direct?${queryString.stringify(params, { arrayFormat: "bracket" })}`, {
                signal,
            });
        },
    });
};

export const useAdminEffectivePermissions = (showAllRules: boolean, aclSubjectId?: number, aclSubjectType?: AclSubjectType) => {
    return useQuery({
        queryKey: PermissionsQueryKeys.effectivePermissionList(showAllRules, aclSubjectId, aclSubjectType),
        queryFn: ({ signal }) => {
            const params = {
                showAllRules,
                aclSubjectId,
                aclSubjectType,
            };
            return apiGet<AclListDto>(`${ADMIN_PERMISSIONS_PATH}/effective?${queryString.stringify(params, { arrayFormat: "bracket" })}`, {
                signal,
            });
        },
        enabled: aclSubjectId !== undefined && aclSubjectType !== undefined,
    });
};

export const useFilterUsersWithPermissionOnEntity = ({
    permission,
    entityIds,
    userIds,
    namespace,
    enabled = true,
}: EntityPermissionRequestBody & { enabled: boolean }) => {
    return useQuery({
        queryKey: PermissionsQueryKeys.entityPermissions({ permission, entityIds, userIds, namespace }),
        queryFn: ({ signal }) =>
            apiPost<number[]>(`${ADMIN_PERMISSIONS_PATH}/entity`, { permission, entityIds, userIds, namespace }, { signal }),
        enabled,
    });
};

export const useCreatePermissions = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: (data: CreateAclsRequestBody) => apiPost<AclListDto>(ADMIN_PERMISSIONS_PATH, data),
        onSuccess: () => {
            queryClient.invalidateQueries(PermissionsQueryKeys.all);
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_PERMISSION_CREATED));
        },
    });
};

export const useAdminPermissionsDeleteUserAcl = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: (aclId: number) => apiDelete(`${ADMIN_PERMISSIONS_PATH}/user/${aclId}`),
        onSuccess: () => {
            queryClient.invalidateQueries(PermissionsQueryKeys.all);
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_USER_PERMISSION_DELETED));
        },
    });
};

export const useAdminPermissionsDeleteGroupAcl = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: (aclId: number) => apiDelete(`${ADMIN_PERMISSIONS_PATH}/group/${aclId}`),
        onSuccess: () => {
            queryClient.invalidateQueries(PermissionsQueryKeys.all);
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_GROUP_PERMISSION_DELETED));
        },
    });
};
