import ChevronRightIcon from "@mui/icons-material/ChevronRightRounded";
import ExpandMoreIcon from "@mui/icons-material/ExpandMoreRounded";
import { Checkbox, Collapse, FormControlLabel, Grid, styled } from "@mui/material";
import {
    IdentityProviderConfig,
    IdentityProviderConfigPassword,
    IdentityProviderConfigSAML,
    IdentityProviderDto,
    IdentityProviderType,
} from "api-shared";
import { TFunction } from "i18next";
import { camelCase } from "lodash";
import React, { useState } from "react";
import Form from "../../../components/Form";
import Tooltip from "../../../components/Tooltip";
import ActionItemDialog from "../../../components/dialogues/ActionItemDialog";
import IntegerInput from "../../../components/input/IntegerInput";
import ValidatedInput from "../../../components/input/ValidatedInput";
import { useAdminAddIdentityProviders, useAdminUpdateIdentityProviders } from "../../../domain/admin/signon";
import { translationKeys } from "../../../translations/main-translations";

const CertificationTextbox = styled(ValidatedInput)(() => ({
    overflow: "auto",
    maxHeight: "240px",
}));

interface ICreateEditSignOnDialogProps {
    open: boolean;
    onClose: () => void;
    translate: TFunction;
    identityProvider: IdentityProviderDto | undefined;
    isDefaultProviderSet: boolean;
    parsedMetadataConfig: IdentityProviderConfig;
    isLastActiveProvider: boolean;
}

const defaultIdentityProvider: IdentityProviderDto = {
    name: "",
    description: "",
    id: -1,
    isEnabled: true,
    allowSelfRegister: false,
    type: IdentityProviderType.SAML,
    config: {
        entryPoint: "",
        issuer: "",
        cert: "",
        disableRequestedAuthnContext: true,
        authnContext: "urn:oasis:names:tc:SAML:2.0:ac:classes:PasswordProtectedTransport",
    },
    isDefault: false,
    isTested: false,
    tokenExpirationHours: 12,
    userProfileFirstname: "",
    userProfileLastname: "",
    userProfileDepartment: "",
    userProfilePosition: "",
    userProfileMobileNumber: "",
    userProfileTelephoneNumber: "",
};

const CreateEditSignOnDialog = ({
    open,
    onClose,
    translate,
    identityProvider,
    isDefaultProviderSet,
    parsedMetadataConfig,
    isLastActiveProvider,
}: ICreateEditSignOnDialogProps) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const createIdentityProviderMutation = useAdminAddIdentityProviders();
    const updateIdentityProviderMutation = useAdminUpdateIdentityProviders();

    const [changes, setChanges] = useState<IdentityProviderDto>(
        identityProvider != null
            ? { ...identityProvider, id: identityProvider.id }
            : { ...defaultIdentityProvider, config: { ...defaultIdentityProvider.config, ...parsedMetadataConfig } },
    );
    const providerSpecificDefaultConfig =
        identityProvider != null && identityProvider.type !== IdentityProviderType.Password
            ? defaultIdentityProvider.config
            : { twoFactorAuthenticationEnforce: false };
    const updatedIdentityProvider: IdentityProviderDto = {
        ...defaultIdentityProvider,
        ...identityProvider,
        ...changes,
        config: { ...providerSpecificDefaultConfig, ...parsedMetadataConfig, ...identityProvider?.config, ...changes?.config },
    };
    const handleValueChange = (event: any) => {
        const { name: fieldName, value: fieldValue } = event.target;
        setChanges((oldIdentityProvider) => ({ ...oldIdentityProvider, [fieldName]: fieldValue }));
    };

    const handleConfigValueChange = (event: any) => {
        const { name: fieldName, value: fieldValue } = event.target;
        setChanges((oldIdentityProvider) => ({
            ...oldIdentityProvider,
            config: { ...oldIdentityProvider.config, [fieldName]: fieldValue },
        }));
    };

    const handleTokenChange = (value: number) => {
        setChanges((oldIdentityProvider) => ({ ...oldIdentityProvider, tokenExpirationHours: value }));
    };

    const handle2FAChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChanges((oldIdentityProvider) => ({ ...oldIdentityProvider, config: { twoFactorAuthenticationEnforce: event.target.checked } }));
    };

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        const target = event.target;
        setChanges((oldIdentityProvider) => ({ ...oldIdentityProvider, [target.name]: target.checked }));
    };

    const handleConfigCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        const target = event.target;
        setChanges((oldIdentityProvider) => ({
            ...oldIdentityProvider,
            config: { ...oldIdentityProvider.config, [target.name]: target.checked },
        }));
    };

    const isValidPassword = updatedIdentityProvider.type === IdentityProviderType.Password;
    const isValidSAML =
        updatedIdentityProvider.type === IdentityProviderType.SAML &&
        updatedIdentityProvider.description !== "" &&
        updatedIdentityProvider.name !== "" &&
        "entryPoint" in updatedIdentityProvider.config &&
        updatedIdentityProvider.config.entryPoint !== "" &&
        "issuer" in updatedIdentityProvider.config &&
        updatedIdentityProvider.config.issuer !== "" &&
        "cert" in updatedIdentityProvider.config &&
        updatedIdentityProvider.config.cert !== "";
    const isValid = isValidPassword || isValidSAML;

    const submit = () => {
        if (!isValid) {
            return;
        }

        const { id, ...newIdentityProvider } = changes;
        if (id === -1 || id === undefined) {
            createIdentityProviderMutation.mutate(newIdentityProvider as IdentityProviderDto);
        } else {
            updateIdentityProviderMutation.mutate({ id, ...newIdentityProvider });
        }

        onClose();
    };

    const userProfileFields = ["Firstname", "Lastname", "TelephoneNumber", "MobileNumber", "Department", "Position"] as const;

    return (
        <ActionItemDialog
            open={open}
            onClose={onClose}
            title={translate(translationKeys.VDLANG_ADMIN_SIGNON)}
            primary={translationKeys.VDLANG_SAVE}
            primaryDisabled={!isValid}
            onPrimary={submit}
            translate={translate}
        >
            <Form onSubmit={submit}>
                <Grid container spacing={2}>
                    {/* Item enabler */}
                    <Grid item xs={6}>
                        <FormControlLabel
                            key="isEnabled"
                            label={translate("Enabled")}
                            control={
                                <Checkbox name="isEnabled" checked={updatedIdentityProvider.isEnabled} onChange={handleCheckboxChange} />
                            }
                            disabled={updatedIdentityProvider.isDefault || isLastActiveProvider}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Tooltip title={isDefaultProviderSet ? translate(translationKeys.VDLANG_DEFAULT_IDENTITY_PROVIDER_EXISTS) : ""}>
                            <FormControlLabel
                                key="isDefault"
                                label={translate(translationKeys.VDLANG_DEFAULT)}
                                control={
                                    <Checkbox
                                        name="isDefault"
                                        checked={updatedIdentityProvider.isDefault}
                                        onChange={handleCheckboxChange}
                                    />
                                }
                                disabled={!updatedIdentityProvider.isEnabled || isDefaultProviderSet}
                            />
                        </Tooltip>
                    </Grid>

                    {/* Non password base config */}
                    {updatedIdentityProvider.type !== IdentityProviderType.Password ? (
                        <>
                            <Grid item xs={12}>
                                <ValidatedInput
                                    name="name"
                                    mandatory
                                    label={translate("Name")}
                                    type="text"
                                    value={updatedIdentityProvider.name}
                                    onChange={handleValueChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <ValidatedInput
                                    name="description"
                                    mandatory
                                    label={translate("description")}
                                    type="text"
                                    value={updatedIdentityProvider.description}
                                    onChange={handleValueChange}
                                    fullWidth
                                />
                            </Grid>
                        </>
                    ) : null}

                    {/* IP related fieleds */}
                    {"issuer" in updatedIdentityProvider.config ? (
                        <Grid item xs={12}>
                            <ValidatedInput
                                name="issuer"
                                mandatory
                                label={translate(translationKeys.VDLANG_IDENTITY_PROVIDER_ISSUER)}
                                type="text"
                                value={updatedIdentityProvider.config.issuer}
                                onChange={handleConfigValueChange}
                                fullWidth
                            />
                        </Grid>
                    ) : null}
                    {"entryPoint" in updatedIdentityProvider.config ? (
                        <Grid item xs={12}>
                            <ValidatedInput
                                name="entryPoint"
                                mandatory
                                label={translate(translationKeys.VDLANG_IDENTITY_PROVIDER_LOGIN_URL)}
                                type="text"
                                value={updatedIdentityProvider.config.entryPoint}
                                onChange={handleConfigValueChange}
                                fullWidth
                            />
                        </Grid>
                    ) : null}
                    {"cert" in updatedIdentityProvider.config ? (
                        <Grid item xs={12}>
                            <CertificationTextbox
                                name="cert"
                                mandatory
                                label={translate(translationKeys.VDLANG_IDENTITY_PROVIDER_CERT)}
                                type="text"
                                value={updatedIdentityProvider.config.cert}
                                onChange={handleConfigValueChange}
                                fullWidth
                                multiline
                            />
                        </Grid>
                    ) : null}

                    {/* SAML only (opt.) fields */}
                    {updatedIdentityProvider.type === IdentityProviderType.SAML ? (
                        <Grid item xs={12}>
                            <FormControlLabel
                                key="disableRequestedAuthnContext"
                                label={translate(translationKeys.VDLANG_IDENTITY_PROVIDER_AUTHN_DISABLE)}
                                control={
                                    <Checkbox
                                        name="disableRequestedAuthnContext"
                                        checked={
                                            (updatedIdentityProvider.config as IdentityProviderConfigSAML).disableRequestedAuthnContext
                                        }
                                        onChange={handleConfigCheckboxChange}
                                    />
                                }
                            />
                        </Grid>
                    ) : null}
                    {updatedIdentityProvider.type === IdentityProviderType.SAML &&
                    !(updatedIdentityProvider.config as IdentityProviderConfigSAML).disableRequestedAuthnContext ? (
                        <Grid item xs={12}>
                            <CertificationTextbox
                                name="authnContext"
                                label={translate(translationKeys.VDLANG_IDENTITY_PROVIDER_AUTHN_CONTEXT)}
                                type="text"
                                value={(updatedIdentityProvider.config as IdentityProviderConfigSAML).authnContext}
                                onChange={handleConfigValueChange}
                                fullWidth
                                multiline
                            />
                        </Grid>
                    ) : null}

                    {/* 2FA enforcement only for password */}
                    {updatedIdentityProvider.type === IdentityProviderType.Password ? (
                        <Grid item xs={12}>
                            <FormControlLabel
                                key="twoFactorAuthenticationEnforce"
                                label={translate(translationKeys.VDLANG_IDENTITY_PROVIDER_2FA)}
                                control={
                                    <Checkbox
                                        name="twoFactorAuthenticationEnforce"
                                        checked={
                                            (updatedIdentityProvider.config as IdentityProviderConfigPassword)
                                                .twoFactorAuthenticationEnforce
                                        }
                                        onChange={handle2FAChange}
                                    />
                                }
                            />
                        </Grid>
                    ) : null}

                    {/* Token configuration */}
                    <Grid item xs={12}>
                        <IntegerInput
                            name="tokenExpirationHours"
                            required
                            label={translate(translationKeys.VDLANG_TOKEN_EXPIRATION_TIME)}
                            value={updatedIdentityProvider.tokenExpirationHours}
                            onChange={handleTokenChange}
                            fullWidth
                        />
                    </Grid>

                    {/* Self register field */}
                    {updatedIdentityProvider.type !== IdentityProviderType.Password ? (
                        <Grid item xs={12}>
                            <FormControlLabel
                                key="allowSelfRegister"
                                label={translate(translationKeys.VDLANG_ALLOW_SELF_REGISTER)}
                                control={
                                    <Checkbox
                                        name="allowSelfRegister"
                                        checked={updatedIdentityProvider.allowSelfRegister}
                                        onChange={handleCheckboxChange}
                                    />
                                }
                            />
                        </Grid>
                    ) : null}

                    {/* Custom user fields */}
                    {updatedIdentityProvider.type !== IdentityProviderType.Password ? (
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        size="small"
                                        color="default"
                                        disableRipple
                                        icon={<ChevronRightIcon />}
                                        checkedIcon={<ExpandMoreIcon />}
                                    />
                                }
                                label={translate(translationKeys.VDLANG_USER_PROFILE_CUSTOM, {
                                    count: userProfileFields.filter((sel) => updatedIdentityProvider[`userProfile${sel}`]).length,
                                })}
                                checked={isExpanded}
                                onChange={(event, checked) => setIsExpanded(checked)}
                            />
                            <Collapse in={isExpanded}>
                                <div>
                                    {userProfileFields.map((name) => (
                                        <ValidatedInput
                                            key={`userProfile${name}`}
                                            name={`userProfile${name}`}
                                            label={translate([camelCase(name), name])}
                                            type="text"
                                            value={updatedIdentityProvider[`userProfile${name}`]}
                                            onChange={handleValueChange}
                                            fullWidth
                                        />
                                    ))}
                                </div>
                            </Collapse>
                        </Grid>
                    ) : null}
                </Grid>
            </Form>
        </ActionItemDialog>
    );
};

export default CreateEditSignOnDialog;
