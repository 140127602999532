import { Typography, TypographyProps } from "@mui/material";
import { AclNamespaces, AdminMeasureListDto } from "api-shared";
import { CellProps } from "react-table";
import MeasureLink from "../../../components/tasks/MeasureLink";
import { AclWithType } from "./AclTable";

export interface AclTableRuleCellProps<D extends AclWithType>
    extends Partial<Pick<CellProps<D>, "value" | "cell">>,
        Pick<TypographyProps, "align" | "variant" | "className"> {
    measures: AdminMeasureListDto;
}

const AclTableRuleCell = <D extends AclWithType>({ value, measures, align, variant, className, cell }: AclTableRuleCellProps<D>) => {
    const acl = cell?.row.original;
    const aclTableRuleCellWithValue = (
        <Typography variant={variant} align={align} noWrap className={className}>
            {value}
        </Typography>
    );
    if (
        acl?.rule.length === 1 &&
        acl.namespace === AclNamespaces.Process &&
        acl.rule[0].operator === "equal" &&
        acl.rule[0].fact === "id"
    ) {
        const measure = measures.find((m) => m.id === acl.rule[0].value);
        if (measure === undefined) {
            return aclTableRuleCellWithValue;
        }

        return (
            <Typography variant={variant} align={align} noWrap className={className}>
                <MeasureLink measureId={measure.id} title={measure.title} clientIid={measure.clientIid} />
            </Typography>
        );
    }

    return acl != null ? aclTableRuleCellWithValue : null;
};

export default AclTableRuleCell;
