import { Checkbox, Divider, FormControlLabel, FormGroup, Stack, styled, Typography } from "@mui/material";
import { AdminMeasureConfigListDto, AdminMeasureListDto, AdminUserListDto, DashboardListDto, GroupListDto } from "api-shared";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { type SingleValue, components } from "react-select";
import Select from "../../../components/input/select/Select";
import { useAdminEffectivePermissions } from "../../../domain/admin/permissions";
import { translationKeys } from "../../../translations/main-translations";
import { AclTable } from "./AclTable";
import { UserOrGroupOption } from "./PermissionsConstants";

const FixWidthSelect = styled(Select)(({ theme }) => ({
    width: theme.spacing(40),
})) as typeof Select;

const SelectUserOrGroupMessage = styled(Typography)({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
});

type EffectivePermissionsViewerProps = {
    userOrGroupOptions: UserOrGroupOption[];
    filter: string;
    measureConfigs: AdminMeasureConfigListDto;
    isLoading: boolean;
    dashboards: DashboardListDto;
    measures: AdminMeasureListDto;
    users: AdminUserListDto;
    groups: GroupListDto;
};

const EffectivePermissionsViewer = ({
    userOrGroupOptions,
    filter,
    measureConfigs,
    isLoading,
    measures,
    users,
    groups,
    dashboards,
}: EffectivePermissionsViewerProps) => {
    const { t } = useTranslation();

    const [showAllPermissions, setShowAllPermissions] = useState(false);
    const [selectedEffectivePermissionsFilter, setSelectedEffectivePermissionsFilter] = useState<UserOrGroupOption | null>(null);

    const effectivePermissionsQuery = useAdminEffectivePermissions(
        showAllPermissions,
        selectedEffectivePermissionsFilter?.entityId,
        selectedEffectivePermissionsFilter?.type,
    );

    const handleShowAllPermissions = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShowAllPermissions(event.target.checked);
    };

    const handleEffectivePermissionsFilterChange = (newSelectedOption: SingleValue<UserOrGroupOption>) => {
        setSelectedEffectivePermissionsFilter(newSelectedOption);
    };

    return (
        <Stack sx={{ height: "100%" }}>
            <Stack sx={{ px: 3, py: 1.5 }} display="flex" direction="row" justifyContent="space-between">
                <Stack direction="row" spacing={1}>
                    <FormGroup>
                        <FixWidthSelect
                            options={userOrGroupOptions}
                            value={selectedEffectivePermissionsFilter}
                            onChange={(newSelectedOptions) =>
                                handleEffectivePermissionsFilterChange(newSelectedOptions as SingleValue<UserOrGroupOption>)
                            }
                            isSearchable
                            placeholder={`${t(translationKeys.VDLANG_ACL_SELECT_USER_GROUP_PLACEHOLDER)}...`}
                            margin="none"
                            components={{
                                Placeholder: components.Placeholder,
                            }}
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox size="small" defaultChecked={showAllPermissions} onChange={handleShowAllPermissions} />}
                            label={t(translationKeys.VDLANG_ACL_SHOW_ALL_PERMISSIONS)}
                        />
                    </FormGroup>
                </Stack>
            </Stack>
            <Divider />
            {selectedEffectivePermissionsFilter === null ? (
                <SelectUserOrGroupMessage color="textSecondary">
                    {t(translationKeys.VDLANG_ACL_EFFECTIVE_PERMISSIONS_SELECTION_REQUIRED)}
                </SelectUserOrGroupMessage>
            ) : null}
            {effectivePermissionsQuery.isSuccess ? (
                <AclTable
                    key={`acl-table-effective-permissions-${showAllPermissions}-${selectedEffectivePermissionsFilter?.entityId}-${selectedEffectivePermissionsFilter?.type}`}
                    acls={effectivePermissionsQuery.data}
                    isFetching={effectivePermissionsQuery.isLoading || isLoading}
                    measureConfigs={measureConfigs}
                    dashboards={dashboards}
                    measures={measures}
                    users={users}
                    groups={groups}
                    globalFilter={filter}
                    groupId={selectedEffectivePermissionsFilter?.entityId}
                    indicateRuleOrigin
                />
            ) : null}
        </Stack>
    );
};

export default EffectivePermissionsViewer;
