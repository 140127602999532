import { GroupDto } from "api-shared";
import { useTranslation } from "react-i18next";
import { CellProps } from "react-table";
import EditIcon from "../../../components/icons/EditIcon";
import TableIconButton from "../../../components/table/TableIconButton";
import { translationKeys } from "../../../translations/main-translations";

interface GroupTableEditCellProps {
    cell: CellProps<GroupDto>;
    onClick: (value: any) => void;
}

export const GroupTableEditCell = ({ cell, onClick }: GroupTableEditCellProps) => {
    const { t } = useTranslation();
    const label = t(translationKeys.VDLANG_ADMIN_GROUPS_EDIT_BUTTON);

    return (
        <TableIconButton onClick={() => onClick(cell.value)} title={label} aria-label={label}>
            <EditIcon />
        </TableIconButton>
    );
};
