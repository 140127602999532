import { zodResolver } from "@hookform/resolvers/zod";
import { Checkbox, FormControlLabel, FormGroup, Stack, styled, Typography } from "@mui/material";
import { AclNamespaces, AclPermissions, AclRule, CreateAclsRequestBody, GroupDto, MeasureStatus, UserDto, UserTier } from "api-shared";
import { Controller, Resolver, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ActionItemDialog from "../../../components/dialogues/ActionItemDialog";

import { z } from "zod";
import Select from "../../../components/input/select/Select";
import { translationKeys } from "../../../translations/main-translations";
import { DialogForm } from "./DialogForm";
import { GroupSelect } from "./Inputs/GroupSelect";
import { UserSelect } from "./Inputs/UserSelect";
import { zFormUserGroupSchema } from "./PermissionsConstants";

type FormValues = Pick<CreateAclsRequestBody, "userIds" | "groupIds"> & {
    read: boolean;
    write: boolean;
    status: MeasureStatus | "*";
};

type MeasureStatusDialogProps = {
    open: boolean;
    onClose: () => void;
    users: UserDto[];
    groups: GroupDto[];
    onPermissionSubmit: (data: CreateAclsRequestBody) => void;
};

const ReducedPaddingCheckbox = styled(Checkbox)(({ theme }) => ({
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
}));

const customResolver: Resolver<FormValues> = async (values, context, options) => {
    if (values.read === false && values.write === false) {
        return {
            values,
            errors: { read: "At least one permission type is required", write: "At least one permission type is required" },
        };
    }
    const zod = zodResolver(
        zFormUserGroupSchema.and(
            z.object({ status: z.union([z.nativeEnum(MeasureStatus), z.string()]), read: z.boolean(), write: z.boolean() }),
        ),
    );
    return zod(values, context, options);
};

export const MeasureStatusDialog = ({ open = false, onClose = () => {}, users, groups, onPermissionSubmit }: MeasureStatusDialogProps) => {
    const { t } = useTranslation();
    const {
        handleSubmit,
        control,
        formState: { isValid },
        reset,
    } = useForm<FormValues>({
        mode: "onChange",
        resolver: customResolver,
        defaultValues: { userIds: [], groupIds: [], read: false, write: false, status: MeasureStatus.STATUS_OPEN },
    });

    const statusOptions = [
        { label: "*", value: "*" },
        { label: t("measure_status_1"), value: MeasureStatus.STATUS_OPEN },
        { label: t("measure_status_0"), value: MeasureStatus.STATUS_DISCARDED },
        { label: t("measure_status_2"), value: MeasureStatus.STATUS_CLOSED },
    ];

    const onSubmit = handleSubmit((data) => {
        const rule: AclRule = [];
        if (data.status === "*") {
            rule.push({ fact: "status", operator: "*", value: "*" });
        } else {
            rule.push({ fact: "status", operator: "equal", value: data.status });
        }

        if (data.read) {
            onPermissionSubmit({
                groupIds: data.groupIds,
                userIds: data.userIds,
                acl: {
                    namespace: AclNamespaces.Process,
                    permission: AclPermissions.Read,
                    rule: rule,
                    simpleEntityId: null,
                },
            });
        }
        if (data.write) {
            onPermissionSubmit({
                groupIds: data.groupIds,
                userIds: data.userIds,
                acl: {
                    namespace: AclNamespaces.Process,
                    permission: AclPermissions.Update,
                    rule: rule,
                    simpleEntityId: null,
                },
            });
        }
        onClose();
    });

    const onCancel = () => {
        onClose();
        reset();
    };

    return (
        <ActionItemDialog
            open={open}
            title={`${t(translationKeys.VDLANG_ACL_ADD_RULE)} - ${t(translationKeys.VDLANG_ACL_TITLE_PROCESS_STATUS_VISBILITY)}`}
            primary={t("Create")}
            primaryIsTranslated
            onPrimary={onSubmit}
            primaryDisabled={!isValid}
            onClose={onCancel}
            translate={t}
            disableContentPadding
            maxWidth="sm"
        >
            <DialogForm onSubmit={onSubmit} style={{ margin: 0 }}>
                <Stack gap={1}>
                    <Typography variant="subtitle2">{t(`${translationKeys.VDLANG_USER_TIERS}.${UserTier.Advanced}`)}</Typography>
                    <Controller
                        name="status"
                        control={control}
                        render={({ field }) => {
                            const { value, onChange } = field;
                            return (
                                <Select
                                    margin="none"
                                    label="Status"
                                    value={statusOptions.find((o) => o.value === value)}
                                    options={statusOptions}
                                    onChange={(o) => onChange(o?.value ?? MeasureStatus.STATUS_OPEN)}
                                    menuPortalTarget={document.body}
                                />
                            );
                        }}
                    />
                    <Controller
                        name="read"
                        control={control}
                        render={({ field }) => {
                            const { value, onChange } = field;
                            return (
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <ReducedPaddingCheckbox
                                                size="small"
                                                checked={value}
                                                onChange={(_, checked) => onChange(checked)}
                                            />
                                        }
                                        label={t(`${translationKeys.VDLANG_ACL_PERMISSIONS}.${AclPermissions.Read}`)}
                                    />
                                </FormGroup>
                            );
                        }}
                    />
                    <Controller
                        name="write"
                        control={control}
                        render={({ field }) => {
                            const { value, onChange } = field;
                            return (
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <ReducedPaddingCheckbox
                                                size="small"
                                                checked={value}
                                                onChange={(_, checked) => onChange(checked)}
                                            />
                                        }
                                        label={t(`${translationKeys.VDLANG_ACL_PERMISSIONS}.${AclPermissions.Update}`)}
                                    />
                                </FormGroup>
                            );
                        }}
                    />
                    <Controller
                        name="groupIds"
                        control={control}
                        render={({ field }) => {
                            const { value, onChange } = field;
                            return <GroupSelect onChange={onChange} value={value} groups={groups} />;
                        }}
                    />
                    <Controller
                        name="userIds"
                        control={control}
                        render={({ field }) => {
                            const { value, onChange } = field;
                            return <UserSelect value={value} onChange={onChange} users={users} />;
                        }}
                    />
                </Stack>
            </DialogForm>
        </ActionItemDialog>
    );
};
