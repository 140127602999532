import { Box, Button, Card, CardActions, CardContent, CardHeader, Grid, Stack, Typography, alpha, styled } from "@mui/material";
import { DEFAULT_DASHBOARD_COLORS, DEFAULT_WHITE_SPOT_COLOR, zHexColor } from "api-shared";
import { isEqual, times } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import ColorInput from "../../../components/input/ColorInput";
import { useAdminUpdateClient } from "../../../domain/admin/client";
import { useCurrentClient } from "../../../domain/client";
import { translationKeys } from "../../../translations/main-translations";
import { scaleStep } from "../../dashboards/whitespot/utils";

const zDashboardColors = z.array(zHexColor).min(12).nullable();

const legendStepSymbolClass = "legend-step-symbol";
const StepContainer = styled(Stack)(({ theme }) => ({
    height: "100%",
    width: "100%",
    [`& > :first-of-type .${legendStepSymbolClass}`]: {
        borderTopLeftRadius: theme.shape.borderRadius,
        borderBottomLeftRadius: theme.shape.borderRadius,
    },
    [`& > :last-of-type .${legendStepSymbolClass}`]: {
        borderTopRightRadius: theme.shape.borderRadius,
        borderBottomRightRadius: theme.shape.borderRadius,
    },
}));

const LegendStepSymbol = styled("div")<{ color: string; opacity: number }>(({ theme, color, opacity }) => ({
    backgroundColor: alpha(color, opacity),
    border: `1px solid ${theme.palette.divider}`,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    ...theme.typography.body2,
}));

const WhiteSpotRoot = styled(Grid)(({ theme }) => ({
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
}));

function AdminDashboardColorConfiguration() {
    const client = useCurrentClient();
    const { t: translate } = useTranslation();

    const updateCurrentClientMutation = useAdminUpdateClient();

    const [dashboardColors, setDashboardColors] = useState(client.dashboardColors);
    const [whiteSpotColor, setWhiteSpotColor] = useState(client.whiteSpotColor);

    const updateDashboardColor = (newColor: string, updateIndex: number) => {
        setDashboardColors((previousColors) =>
            (previousColors ?? DEFAULT_DASHBOARD_COLORS).map((color, index) => (index === updateIndex ? newColor : color)),
        );
    };

    const areColorsValid = zDashboardColors.safeParse(dashboardColors).success && zHexColor.safeParse(whiteSpotColor).success;
    const hasChanged = !isEqual(dashboardColors, client.dashboardColors) || whiteSpotColor !== client.whiteSpotColor;
    const canSave = hasChanged && areColorsValid;

    const submit = () => {
        if (!canSave) {
            return;
        }
        updateCurrentClientMutation.mutate(
            { dashboardColors, whiteSpotColor },
            {
                onError: () => {
                    // reset local state to original values when saving fails
                    setDashboardColors(client.dashboardColors);
                    setWhiteSpotColor(client.whiteSpotColor);
                },
            },
        );
    };

    const shownColors = dashboardColors ?? DEFAULT_DASHBOARD_COLORS;

    return (
        <Card>
            <CardHeader title={translate(translationKeys.VDLANG_UPDATE_DASHBOARD_COLORS)} />
            <CardContent>
                <Grid container columnSpacing={1} rowSpacing={2}>
                    {shownColors.map((color, index) => (
                        <Grid item xs={6} sm={4} md={3} key={index}>
                            <ColorInput
                                fullWidth
                                value={color}
                                onChange={(newColor) => updateDashboardColor(newColor, index)}
                                label={translate(translationKeys.VDLANG_ADMIN_DASHBOARD_COLOR_LABEL, { index: index + 1 })}
                            />
                        </Grid>
                    ))}
                </Grid>
                <Grid container columnSpacing={1} rowSpacing={1} marginTop={2}>
                    <Grid item xs={12} sm={12} md={12} marginBottom={1}>
                        <Typography variant="subtitle1">
                            {translate(translationKeys.VDLANG_ADMIN_DASHBOARD_WHITE_SPOT_COLOR_LABEL)}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            {translate(translationKeys.VDLANG_ADMIN_DASHBOARD_WHITE_SPOT_COLOR_WARNING)}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sm={4} md={3}>
                        <ColorInput fullWidth value={whiteSpotColor} onChange={(newColor) => setWhiteSpotColor(newColor)} />
                    </Grid>
                    <WhiteSpotRoot item xs={6} sm={8} md={9} display="flex">
                        <Stack gap={1} flexGrow={1} direction="row" alignItems="center">
                            <Typography marginLeft={2}>{translate(translationKeys.VDLANG_ADMIN_DASHBOARD_COLOR_LABEL_PREVIEW)}</Typography>
                            <StepContainer direction="row" spacing={0.25}>
                                {times(6).map((i) => (
                                    <Box key={i} flexGrow={1}>
                                        <LegendStepSymbol
                                            key={i}
                                            className={legendStepSymbolClass}
                                            color={whiteSpotColor}
                                            opacity={scaleStep(5, 1, i, 6)}
                                        >
                                            {i + 1}
                                        </LegendStepSymbol>
                                    </Box>
                                ))}
                            </StepContainer>
                        </Stack>
                    </WhiteSpotRoot>
                </Grid>
            </CardContent>
            <CardActions>
                <Button onClick={submit} disabled={!canSave} variant={hasChanged ? "contained" : "outlined"} color="primary">
                    {translate(translationKeys.VDLANG_SAVE)}
                </Button>
                <Button
                    disabled={dashboardColors === null && whiteSpotColor === DEFAULT_WHITE_SPOT_COLOR}
                    onClick={() => {
                        setDashboardColors(null);
                        setWhiteSpotColor(DEFAULT_WHITE_SPOT_COLOR);
                    }}
                >
                    {translate(translationKeys.VDLANG_ADMIN_DASHBOARD_RESET_DASHBOARD_COLORS_TO_DEFAULT)}
                </Button>
            </CardActions>
        </Card>
    );
}
export default AdminDashboardColorConfiguration;
