import { GroupDto, GroupType } from "api-shared";
import { useTranslation } from "react-i18next";
import { CellProps } from "react-table";
import DeleteIcon from "../../../components/icons/DeleteIcon";
import TableIconButton from "../../../components/table/TableIconButton";
import { translationKeys } from "../../../translations/main-translations";

interface GroupTableDeleteCellProps {
    cell: CellProps<GroupDto>;
    onClick: (value: any) => void;
}

export const GroupTableDeleteCell = ({ cell, onClick }: GroupTableDeleteCellProps) => {
    const { t } = useTranslation();
    const label = t(translationKeys.VDLANG_ADMIN_GROUPS_DELETE_BUTTON);
    const { isUsed, type } = cell.row.original;
    const hasChildren = cell.row.original.childGroupIds.length > 0 || cell.row.original.userIds.length > 0;

    let tooltip = label;
    if (isUsed || hasChildren) {
        tooltip = t(translationKeys.VDLANG_ADMIN_GROUPS_DELETE_IN_USE_DISABLED);
    }

    if (type === GroupType.EXT_SCIM) {
        tooltip = t(translationKeys.VDLANG_ADMIN_GROUPS_EXT_DELETE_DISABLED);
    } else if (type !== GroupType.STANDARD) {
        tooltip = t(translationKeys.VDLANG_ADMIN_GROUPS_MANAGED_DELETE_DISABLED);
    }

    const disabled = isUsed || hasChildren || type !== GroupType.STANDARD;

    return (
        <TableIconButton onClick={() => onClick(cell.value)} disabled={disabled} title={tooltip} aria-label={label}>
            <DeleteIcon />
        </TableIconButton>
    );
};
