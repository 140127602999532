import DeleteIcon from "@mui/icons-material/DeleteRounded";
import EditIcon from "@mui/icons-material/EditRounded";
import { Button, Divider, Grid, Paper, Stack, styled } from "@mui/material";
import {
    DomainDto,
    IdentityProviderConfig,
    IdentityProviderConfigPassword,
    IdentityProviderDto,
    IdentityProviderType,
    UserDto,
} from "api-shared";
import { TFunction } from "i18next";
import { useCallback, useMemo, useState } from "react";
import { CellProps } from "react-table";
import DeleteDialog from "../../../components/dialogues/DeleteDialog";
import UploadFileDialog from "../../../components/dialogues/UploadFileDialog";
import BaseTable from "../../../components/table/BaseTable";
import TableBooleanCell from "../../../components/table/TableBooleanCell";
import TableHeaderCell from "../../../components/table/TableHeaderCell";
import TableIconButton from "../../../components/table/TableIconButton";
import TableTextCell, { ITableTextCellProps } from "../../../components/table/TableTextCell";
import { useAdminDeleteIdentityProviders } from "../../../domain/admin/signon";
import useDialog from "../../../hooks/useDialog";
import { formatUser } from "../../../lib/formatters";
import { xmlToConfig } from "../../../lib/signon-xml";
import { translationKeys } from "../../../translations/main-translations";
import CreateEditSignOnDialog from "./CreateEditSignOnDialog";

const Root = styled(Paper)({ height: "100%" });
const RootGrid = styled(Grid)({ height: "100%" });

const Grow = styled(Grid)(() => ({
    flexGrow: 1,
    flexShrink: 1,
    height: 0, // work around bug in chrome 69/safari with unscollable page
}));

interface ISignOnSettingsProps {
    translate: TFunction;
    identityProviders: IdentityProviderDto[];
    domains: DomainDto[];
    users: UserDto[];
}

function yesNoTableCell(c: ITableTextCellProps<IdentityProviderDto>, translate: TFunction) {
    return (
        <TableBooleanCell
            value={c?.value}
            falseText={translate(translationKeys.VDLANG_NO)}
            trueText={translate(translationKeys.VDLANG_YES)}
        />
    );
}

const SignOnSettings = ({ translate, identityProviders, domains, users }: ISignOnSettingsProps) => {
    const [activeIdentityProviderId, setActiveIdentityProviderId] = useState<number | undefined>(undefined);
    const [identityProviderToDeleteId, setIdentityProviderToDeleteId] = useState<number | undefined>(undefined);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [selectedFileIsValid, setSelectedFileIsValid] = useState<boolean>(false);
    const [parsedMetadataConfig, setParsedMetadataConfig] = useState<IdentityProviderConfig>({ twoFactorAuthenticationEnforce: false });
    const [helperText, setHelperText] = useState<string | null>(null);

    const deleteIdentityProviderMutation = useAdminDeleteIdentityProviders();

    const { isOpen: isDialogOpen, closeDialog, openDialog } = useDialog();
    const { isOpen: isFileDialogOpen, closeDialog: closeFileDialog, openDialog: openFileDialog } = useDialog();

    const onNew = () => {
        setActiveIdentityProviderId(undefined);
        openDialog();
    };

    const onNewMetadata = () => {
        setActiveIdentityProviderId(undefined);
        setSelectedFile(null);
        openFileDialog();
    };

    const selectedFileChanged = (selectedFile: File, isValid: boolean) => {
        setSelectedFile(selectedFile);
        setSelectedFileIsValid(isValid);
    };

    const parseFile = async () => {
        if (selectedFile === null) {
            // Shouldn't happen
            return;
        }

        try {
            const content = await selectedFile.text();
            const config = await xmlToConfig(content, selectedFile.name.toLowerCase() === "valuedesk.xml");
            setParsedMetadataConfig(config);
            closeFileDialog();
            openDialog();
        } catch {
            setHelperText(translate(translationKeys.VDLANG_PARSING_ERROR).toString());
            openFileDialog();
        }
    };

    const onSelect = useCallback(
        (value: any) => {
            setParsedMetadataConfig({ twoFactorAuthenticationEnforce: false });
            setActiveIdentityProviderId(value);
            openDialog();
        },
        [setActiveIdentityProviderId, openDialog],
    );

    const onRemove = () => {
        if (identityProviderToDeleteId === undefined) {
            return;
        }
        deleteIdentityProviderMutation.mutate(identityProviderToDeleteId);
        setIdentityProviderToDeleteId(undefined);
    };

    const providersInUse = useMemo(() => new Set(users.map((u) => u.identityProviderId)), [users]);

    const columns: any = useMemo(() => {
        return [
            {
                Header: TableHeaderCell,
                label: translate("Name"),
                accessor: "name",
                Cell: TableTextCell,
                id: "name",
            },
            {
                Header: TableHeaderCell,
                label: translate("description"),
                accessor: "description",
                Cell: TableTextCell,
                id: "description",
            },
            {
                Header: TableHeaderCell,
                label: translate(translationKeys.VDLANG_TYPE),
                accessor: "type",
                Cell: (c: CellProps<IdentityProviderDto, IdentityProviderType>) => {
                    const is2FAActive = (c.cell?.row.original.config as IdentityProviderConfigPassword).twoFactorAuthenticationEnforce;
                    const translationKey = `${translationKeys.VDLANG_IDENTITY_PROVIDER_TYPE}.${c.value}`;
                    return <TableTextCell {...c} value={translate(is2FAActive ? `${translationKey}_2FA` : `${translationKey}`)} />;
                },
                id: "type",
            },
            {
                Header: TableHeaderCell,
                label: translate("Enabled"),
                accessor: "isEnabled",
                Cell: (c: CellProps<IdentityProviderDto>) => yesNoTableCell(c, translate),
                id: "isEnabled",
            },
            {
                Header: TableHeaderCell,
                label: translate(translationKeys.VDLANG_DEFAULT),
                accessor: (row: any) => (row.isDefault ? translate(translationKeys.VDLANG_DEFAULT) : "-"),
                Cell: TableTextCell,
                id: "isDefault",
            },
            {
                Header: TableHeaderCell,
                label: translate("domains"),
                accessor: "id",
                id: "domains",
                width: 192,
                disableResizing: true,
                disableSortBy: true,
                Cell: (c: CellProps<IdentityProviderDto>) => (
                    <TableTextCell
                        {...c}
                        value={domains
                            .filter((sel) => sel.identityProviderId === c?.value)
                            .map((sel) => sel.name)
                            .join(", ")}
                    />
                ),
            },
            {
                Header: TableHeaderCell,
                label: translate("Members"),
                accessor: "id",
                id: "users",
                width: 192,
                disableResizing: true,
                disableSortBy: true,
                Cell: (c: CellProps<IdentityProviderDto>) => (
                    <TableTextCell
                        {...c}
                        value={users
                            .filter((sel) => sel.identityProviderId === c?.value)
                            .map((sel) => formatUser(sel, { translate }))
                            .join(", ")}
                    />
                ),
            },
            {
                Header: TableHeaderCell,
                label: translate(translationKeys.VDLANG_ALLOW_SELF_REGISTER),
                accessor: "allowSelfRegister",
                Cell: (c: CellProps<IdentityProviderDto>) => (
                    <TableBooleanCell
                        value={c?.value}
                        falseText={translate(translationKeys.VDLANG_DEACTIVATED)}
                        trueText={translate(translationKeys.VDLANG_ACTIVATED)}
                    />
                ),
                id: "allowSelfRegister",
            },
            {
                Header: TableHeaderCell,
                label: translate(translationKeys.VDLANG_IS_TESTED),
                accessor: "isTested",
                Cell: (c: CellProps<IdentityProviderDto>) => yesNoTableCell(c, translate),
                id: "isTested",
            },
            {
                Header: "",
                accessor: "id",
                id: "edit",
                width: 64,
                disableResizing: true,
                disableSortBy: true,
                Cell: (c: CellProps<IdentityProviderDto>) => (
                    <TableIconButton onClick={() => onSelect(c.value)}>
                        <EditIcon />
                    </TableIconButton>
                ),
            },
            {
                Header: "",
                accessor: "id",
                id: "delete",
                width: 64,
                disableResizing: true,
                disableSortBy: true,
                Cell: (c: CellProps<IdentityProviderDto>) => (
                    <TableIconButton
                        title={
                            c.row.values.type === IdentityProviderType.Password
                                ? translate(translationKeys.VDLANG_IDENTITY_PROVIDER_DELETE_PASSWORD)
                                : translate(translationKeys.VDLANG_USED_IN_IDENTITY_PROVIDER)
                        }
                        disabled={
                            c.row.values.type === IdentityProviderType.Password ||
                            providersInUse.has(c?.value) ||
                            domains.some((s) => s.identityProviderId === c.value)
                        }
                        onClick={() => setIdentityProviderToDeleteId(c.value)}
                    >
                        <DeleteIcon />
                    </TableIconButton>
                ),
            },
        ];
    }, [translate, domains, onSelect, providersInUse, users]);

    return (
        <Root>
            <RootGrid container direction="column" wrap="nowrap">
                <CreateEditSignOnDialog
                    key={`${activeIdentityProviderId ?? -1}_${"entryPoint" in parsedMetadataConfig ? parsedMetadataConfig.entryPoint : ""}`}
                    open={isDialogOpen}
                    onClose={closeDialog}
                    translate={translate}
                    identityProvider={identityProviders.find((sel) => sel.id === activeIdentityProviderId)}
                    isDefaultProviderSet={identityProviders.some((sel) => sel.id !== activeIdentityProviderId && sel.isDefault)}
                    isLastActiveProvider={!identityProviders.some((sel) => sel.id !== activeIdentityProviderId && sel.isEnabled)}
                    parsedMetadataConfig={parsedMetadataConfig}
                />
                <UploadFileDialog
                    open={isFileDialogOpen}
                    onClose={closeFileDialog}
                    onUpload={parseFile}
                    file={selectedFile}
                    updateFile={selectedFileChanged}
                    primaryDisabled={!selectedFileIsValid}
                    translate={translate}
                    mimeTypes="text/xml"
                    customHelperText={helperText}
                />
                <DeleteDialog
                    item="identity_provider"
                    translate={translate}
                    open={identityProviderToDeleteId !== undefined}
                    onClose={() => setIdentityProviderToDeleteId(undefined)}
                    onDelete={onRemove}
                />
                <Grid item>
                    <Stack direction="row" justifyContent="flex-end" px={3} py={1.2} gap={1}>
                        <Button
                            variant="outlined"
                            color="primary"
                            href={translate(translationKeys.VDLANG_ADMIN_SIGNON_METADATA_LINK)}
                            target="_blank"
                        >
                            {translate(translationKeys.VDLANG_ADMIN_SIGNON_METADATA_TEXT)}
                        </Button>{" "}
                        <Button
                            variant="outlined"
                            color="primary"
                            href={translate(translationKeys.VDLANG_ADMIN_SIGNON_MANUAL_LINK)}
                            target="_blank"
                        >
                            {translate(translationKeys.VDLANG_ADMIN_SIGNON_MANUAL_TEXT)}
                        </Button>
                        <Button variant="outlined" color="primary" onClick={onNewMetadata}>
                            {translate(translationKeys.VDLANG_ADMIN_SIGNON_FROM_METADATA)}
                        </Button>
                        <Button variant="contained" color="primary" onClick={onNew}>
                            {translate(translationKeys.VDLANG_ADMIN_SIGNON_CREATE)}
                        </Button>
                    </Stack>
                </Grid>
                <Grid item component={Divider} />
                <Grow item>
                    <BaseTable
                        fullHeight
                        data={identityProviders}
                        columns={columns}
                        itemName={translationKeys.VDLANG_ADMIN_SIGNON}
                        translate={translate}
                        noDataText="-" // Shouldn't happen
                    />
                </Grow>
            </RootGrid>
        </Root>
    );
};

export default SignOnSettings;
