import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { IdentityProviderConfigListDto, IdentityProviderDto, type DomainDto, type UserDto } from "api-shared";
import { useDispatch } from "react-redux";
import { showNotificationEvent } from "../../infrastructure/notifications";
import { apiDelete, apiGet, apiPatch, apiPost } from "../../lib/api";
import { NotificationType } from "../../lib/notifications";
import { FeedbackTranslationKeys } from "../../translations/notification-translations";

const ADMIN_SIGNON_PATH = "admin/identity-providers";

export const useAdminIdentityProviders = () => {
    return useQuery({
        queryKey: [ADMIN_SIGNON_PATH],
        queryFn: ({ signal }) => apiGet<IdentityProviderConfigListDto>(ADMIN_SIGNON_PATH, { signal }),
    });
};

export const useAdminAddIdentityProviders = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: (data: Omit<IdentityProviderDto, "id">) => apiPost<IdentityProviderDto>(ADMIN_SIGNON_PATH, data),
        onSuccess: () => {
            queryClient.invalidateQueries([ADMIN_SIGNON_PATH]);
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_IDENTITY_PROVIDER_CREATED));
        },
    });
};

export const useAdminDeleteIdentityProviders = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: (id: number) => apiDelete(`${ADMIN_SIGNON_PATH}/${id}`),
        onSuccess: () => {
            queryClient.invalidateQueries([ADMIN_SIGNON_PATH]);
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_IDENTITY_PROVIDER_DELETED));
        },
    });
};

export const useAdminUpdateIdentityProviders = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: ({ id, ...changes }: Partial<IdentityProviderDto> & Pick<IdentityProviderDto, "id">) =>
            apiPatch<IdentityProviderDto>(`${ADMIN_SIGNON_PATH}/${id}`, changes),
        onSuccess: () => {
            queryClient.invalidateQueries([ADMIN_SIGNON_PATH]);
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_IDENTITY_PROVIDER_UPDATED));
        },
    });
};

export function resolveIdentityProvider(
    user: Pick<UserDto, "email" | "identityProviderId">,
    domains: DomainDto[],
    identityProviders: IdentityProviderDto[],
): IdentityProviderDto | undefined {
    // Resolve IdentityProvider in priority: User IdentityProvider >> Domain IdentityProvider >> Default Client IdentityProvider

    const emailDomain = user.email?.split("@")[1];
    const ipId = user.identityProviderId ?? domains?.find((d) => d.name === emailDomain)?.identityProviderId;

    if (ipId != null) {
        return identityProviders.find((ip) => ip.id === ipId);
    }

    return identityProviders.find((ip) => ip.isDefault);
}
