import AddIcon from "@mui/icons-material/AddRounded";
import { Button, Divider, Grid, Paper, styled } from "@mui/material";
import { AdminSupplierDto } from "api-shared";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Column, Row } from "react-table";
import SearchInput from "../../../components/input/SearchInput";
import LoadingAnimation from "../../../components/loading/LoadingAnimation";
import BaseTable from "../../../components/table/BaseTable";
import TableDateCell from "../../../components/table/TableDateCell";
import TableHeaderCell from "../../../components/table/TableHeaderCell";
import TableSettingsButton from "../../../components/table/TableSettingsButton";
import TableTextCell from "../../../components/table/TableTextCell";
import TableUserCell from "../../../components/table/TableUserCell";
import { useAdminSuppliersQuery, useCreateSupplierAdminMutation, useUpdateSupplierAdminMutation } from "../../../domain/admin/suppliers";
import { useAdminUsers } from "../../../domain/admin/users";
import { useDebounce } from "../../../hooks/useDebounce";
import useDialog from "../../../hooks/useDialog";
import { translationKeys } from "../../../translations/main-translations";
import CreateSupplierDialog from "./CreateSupplierDialog";
import EditSupplierDialog from "./EditSupplierDialog";

const sortNullableDates = (rowA: Row<AdminSupplierDto>, rowB: Row<AdminSupplierDto>, columnId: string) => {
    const a = rowA.values[columnId];
    const b = rowB.values[columnId];
    if (a == null) {
        return b == null ? 0 : -1;
    }
    if (b == null) {
        return 1;
    }
    return new Date(a).valueOf() - new Date(b).valueOf();
};

const Root = styled(Paper)({ height: "100%" });
const RootGrid = styled(Grid)({ height: "100%" });

export type SupplierFilterFunction = (item: AdminSupplierDto) => boolean;

const SupplierManagement = () => {
    const suppliersQuery = useAdminSuppliersQuery();
    const createSupplierMutation = useCreateSupplierAdminMutation();
    const updateSupplierMutation = useUpdateSupplierAdminMutation();

    const { t: translate } = useTranslation();

    const allUsers = useAdminUsers();

    const createDialog = useDialog();
    const editDialog = useDialog();
    const [selectedSupplier, setSelectedSupplier] = useState<AdminSupplierDto | null>(null);

    const [searchKey, setSearchKey] = useState("");
    const debouncedSearchKey = useDebounce(searchKey);

    // move into local variable so linter is able to figure out dependencies correctly
    const openEditDialog = editDialog.open;
    const openEditSupplierForm = useCallback(
        (supplier: number) => {
            setSelectedSupplier(suppliersQuery.data?.find((s) => s.id === supplier) ?? null);
            openEditDialog();
        },
        [suppliersQuery.data, openEditDialog],
    );

    const data = suppliersQuery.data ?? [];

    const columns = useMemo<Column<AdminSupplierDto>[]>(
        () => [
            {
                Header: TableHeaderCell,
                label: translate("Name"),
                accessor: "name",
                Cell: TableTextCell,
            },
            {
                Header: TableHeaderCell,
                label: translate("internal_number"),
                accessor: "supplierNumber",
                Cell: TableTextCell,
            },
            {
                Header: TableHeaderCell,
                label: translate("createdBy"),
                sortType: "user",
                accessor: "createdById",
                translate: translate,
                Cell: (props) => <TableUserCell users={allUsers.data} {...props} />,
            },
            {
                Header: TableHeaderCell,
                label: translate("createdAt"),
                accessor: "createdAt",
                sortType: sortNullableDates,
                Cell: TableDateCell,
            },
            {
                Header: TableHeaderCell,
                label: "",
                disableSortBy: true,
                disableResizing: true,
                width: 64,
                accessor: "id",
                Cell: ({ value }) => <TableSettingsButton title={translate("edit_partner")} onClick={() => openEditSupplierForm(value)} />,
            },
        ],
        [openEditSupplierForm, translate, allUsers.data],
    );

    if (!suppliersQuery.isSuccess) {
        return <LoadingAnimation />;
    }

    return (
        <Root>
            <RootGrid container direction="column" wrap="nowrap">
                {selectedSupplier != null ? (
                    <EditSupplierDialog
                        key={selectedSupplier.id}
                        open={editDialog.isOpen}
                        onClose={editDialog.close}
                        supplier={selectedSupplier}
                        updateSupplier={updateSupplierMutation.mutate}
                    />
                ) : null}
                <CreateSupplierDialog
                    open={createDialog.isOpen}
                    onClose={createDialog.close}
                    key="create"
                    createSupplier={createSupplierMutation.mutate}
                />
                <Grid item>
                    <Grid container justifyContent="space-between" alignItems="center" spacing={2} wrap="nowrap" px={3} py={1.2}>
                        <Grid item>
                            <SearchInput searchKey={searchKey} onChange={setSearchKey} translate={translate} fullWidth={false} />
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color="primary" onClick={createDialog.open} startIcon={<AddIcon />}>
                                {translate("Create Partner")}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item component={Divider} />
                <BaseTable
                    fullHeight
                    defaultCanSort
                    disableSortBy={false}
                    data={data}
                    columns={columns}
                    translate={translate}
                    globalFilter={debouncedSearchKey}
                    itemName="Suppliers"
                    noDataText={translate(translationKeys.VDLANG_NO_PARTNERS)}
                    isFetching={!suppliersQuery.isSuccess}
                />
            </RootGrid>
        </Root>
    );
};

export default React.memo(SupplierManagement);
