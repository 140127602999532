import { styled } from "@mui/system";
import Form from "../../../components/Form";

export const DialogForm = styled(Form)(({ theme }) => ({
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    height: "100%",
    width: "100%",
}));
