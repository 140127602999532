import { useQuery } from "@tanstack/react-query";
import { DashboardDto } from "api-shared";
import { apiGet } from "../../lib/api";

const ADMIN_DASHBOARDS_PATH = "admin/dashboards";

export const useAdminDashboards = () => {
    return useQuery({
        queryKey: [ADMIN_DASHBOARDS_PATH],
        queryFn: ({ signal }) => apiGet<DashboardDto[]>(ADMIN_DASHBOARDS_PATH, { signal }),
    });
};
