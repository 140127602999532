import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { CompanyCreateDto, CompanyDto, CompanyListDto, CompanyUpdateDto } from "api-shared";
import { useDispatch } from "react-redux";
import { showNotificationEvent } from "../../infrastructure/notifications";
import { apiDelete, apiGet, apiPatch, apiPost } from "../../lib/api";
import { NotificationType } from "../../lib/notifications";
import { FeedbackTranslationKeys } from "../../translations/notification-translations";

const ADMIN_COMPANIES_PATH = "admin/companies";

export const useAdminCompanies = () => {
    return useQuery({
        queryKey: [ADMIN_COMPANIES_PATH],
        queryFn: ({ signal }) => apiGet<CompanyListDto>(ADMIN_COMPANIES_PATH, { signal }),
    });
};

export const useAdminAddCompany = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: (data: CompanyCreateDto) => apiPost<CompanyDto>(ADMIN_COMPANIES_PATH, data),
        onSuccess: () => {
            queryClient.invalidateQueries([ADMIN_COMPANIES_PATH]);
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_COMPANY_CREATED));
        },
    });
};

export const useAdminDeleteCompany = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: (id: number) => apiDelete(`${ADMIN_COMPANIES_PATH}/${id}`),
        onSuccess: () => {
            queryClient.invalidateQueries([ADMIN_COMPANIES_PATH]);
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_COMPANY_REMOVED));
        },
    });
};

export const useAdminUpdateCompany = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: async ({ id, ...company }: CompanyUpdateDto & { id: number }) =>
            apiPatch<CompanyDto>(`${ADMIN_COMPANIES_PATH}/${id}`, company),
        onSuccess: () => {
            queryClient.invalidateQueries([ADMIN_COMPANIES_PATH]);
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_COMPANY_UPDATED));
        },
    });
};
