import AddIcon from "@mui/icons-material/AddRounded";
import DeleteIcon from "@mui/icons-material/DeleteRounded";
import EditIcon from "@mui/icons-material/EditRounded";
import InfoOutlined from "@mui/icons-material/InfoRounded";
import SortOutlined from "@mui/icons-material/SortRounded";
import ArrowRight from "@mui/icons-material/SubdirectoryArrowRightRounded";
import { Box, Button, Divider, Stack, styled, Typography } from "@mui/material";
import { SyntheticEvent, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import LoadingAnimation from "../../../../components/loading/LoadingAnimation";
import Tooltip from "../../../../components/Tooltip";
import TreeNodes from "../../../../components/TreeNodes";
import { useAdminTreeNodes } from "../../../../domain/admin/tree-nodes";
import useDialog from "../../../../hooks/useDialog";
import { useLanguage } from "../../../../hooks/useLanguage";
import { isLeaf } from "../../../../lib/tree";
import { Language, translationKeys } from "../../../../translations/main-translations";
import recursiveTreeSearch from "../util/recursiveTreeSearch";
import AddTreeNodeDialog from "./dialogs/AddTreeNodeDialog";
import DeleteTreeNodeDialog from "./dialogs/DeleteTreeNodeDialog";
import EditTreeNodeDialog from "./dialogs/EditTreeNodeDialog";
import MoveTreeNodeDialog from "./dialogs/MoveTreeNodeDialog";
import OrderTreeNodeDialog from "./dialogs/OrderTreeNodeDialog";
import TreeNodeControls from "./TreeNodeControls";

type ManageTreeNodesProps = {
    attributeId: number;
};

const StyledTreeNodes = styled(TreeNodes)({
    flexGrow: 1,
    overflowY: "auto",
});

const SelectedTreeNodeContextMenu = styled(Stack)(({ theme }) => ({
    padding: theme.spacing(1.5, 2),
    borderTop: `1px solid ${theme.palette.divider}`,
}));

const SelectedTreeNodeContextButton = styled(Button)(({ theme }) => ({
    borderColor: theme.palette.defaultDark.main,
    color: theme.palette.text.primary,
    ":hover": {
        borderColor: theme.palette.defaultDark.main,
    },
}));

const TooltipInfoIcon = styled(InfoOutlined)(({ theme }) => ({
    color: theme.palette.action.active,
    fontSize: "1.25em",
}));

const ManageTreeNodes = ({ attributeId }: ManageTreeNodesProps) => {
    const { t } = useTranslation();
    const appLanguage = useLanguage();
    const [language, setLanguage] = useState<Language>(appLanguage);
    const [selected, setSelected] = useState<number | null>(null);
    const addDialog = useDialog();
    const editDialog = useDialog();
    const deleteDialog = useDialog();
    const moveDialog = useDialog();
    const orderDialog = useDialog();
    const treeNodes = useAdminTreeNodes(attributeId);

    const selectedTreeNode = useMemo(() => {
        if (treeNodes.data && selected) {
            return recursiveTreeSearch(treeNodes.data ?? [], selected);
        }
    }, [treeNodes.data, selected]);

    const handleSelect = (event: SyntheticEvent, nodeId: number | null) => {
        // Workaround to prevent selection/deselection when clicking on the expand/collapse icon
        if ((event.target as HTMLElement).tagName === "svg") {
            return;
        }
        setSelected((old) => (nodeId === old ? null : nodeId));
    };

    function onLanguageChange(language: Language | null) {
        if (language != null) {
            setLanguage(language);
        }
    }

    if (!treeNodes.isSuccess) {
        return (
            <Stack height="100%" spacing={1} alignItems="center" justifyContent="center">
                <LoadingAnimation />
            </Stack>
        );
    }

    return (
        <>
            <TreeNodeControls language={language} onLanguageChange={onLanguageChange} onAdd={addDialog.open} />
            <Divider />
            {treeNodes.data.length === 0 ? (
                <Stack sx={{ height: "100%" }} spacing={1} alignItems="center" justifyContent="center">
                    <Typography>{t(translationKeys.VDLANG_TREE_NODE_EMPTY)}</Typography>
                    <Button variant="contained" startIcon={<AddIcon />} onClick={addDialog.open}>
                        {t(translationKeys.VDLANG_TREE_NODE_ADD_NODE)}
                    </Button>
                </Stack>
            ) : (
                <Stack sx={{ overflow: "auto", height: "100%" }}>
                    <StyledTreeNodes language={language} selected={selected} handleSelect={handleSelect} treeNodes={treeNodes.data} />
                    {selected !== null && selectedTreeNode !== undefined ? (
                        <SelectedTreeNodeContextMenu spacing={4} direction="row" alignItems="center" justifyContent="space-between">
                            <Tooltip title={<Trans i18nKey={translationKeys.VDLANG_TREE_NODE_CONTEXT_MENU_HINT} />}>
                                <Stack direction="row" spacing={0.5}>
                                    <Typography variant="body2" color="textPrimary" fontWeight="medium">
                                        {t(translationKeys.VDLANG_TREE_NODE_CONTEXT_MENU_TITLE)}
                                    </Typography>
                                    <TooltipInfoIcon />
                                </Stack>
                            </Tooltip>
                            <Box flexGrow={1} />
                            <SelectedTreeNodeContextButton
                                startIcon={<SortOutlined color="action" />}
                                onClick={orderDialog.open}
                                disabled={selectedTreeNode.children.length < 2}
                            >
                                {t(translationKeys.VDLANG_TREE_NODE_ADJUST_ORDER)}
                            </SelectedTreeNodeContextButton>
                            <SelectedTreeNodeContextButton onClick={moveDialog.open} startIcon={<ArrowRight color="action" />}>
                                {t(translationKeys.VDLANG_TREE_NODE_MOVE_NODE)}
                            </SelectedTreeNodeContextButton>
                            <Stack direction="row" spacing={1}>
                                <SelectedTreeNodeContextButton
                                    startIcon={<EditIcon color="action" />}
                                    onClick={editDialog.open}
                                    color="inherit"
                                >
                                    {t(translationKeys.VDLANG_TREE_NODE_EDIT_NODE)}
                                </SelectedTreeNodeContextButton>
                                <Button
                                    color="error"
                                    startIcon={<DeleteIcon />}
                                    onClick={deleteDialog.open}
                                    disabled={!isLeaf(selectedTreeNode)}
                                >
                                    {t("Delete")}
                                </Button>
                            </Stack>
                        </SelectedTreeNodeContextMenu>
                    ) : (
                        <SelectedTreeNodeContextMenu direction="row" justifyContent="end">
                            <SelectedTreeNodeContextButton
                                startIcon={<SortOutlined color="action" />}
                                onClick={orderDialog.open}
                                disabled={treeNodes.data.length < 2}
                            >
                                {t(translationKeys.VDLANG_TREE_NODE_ADJUST_ORDER)}
                            </SelectedTreeNodeContextButton>
                        </SelectedTreeNodeContextMenu>
                    )}
                </Stack>
            )}
            <AddTreeNodeDialog open={addDialog.isOpen} parent={selectedTreeNode} onClose={addDialog.close} attributeId={attributeId} />
            {selected && selectedTreeNode && (
                <>
                    <EditTreeNodeDialog
                        key={`edit_${selected}`}
                        open={editDialog.isOpen}
                        treeNode={selectedTreeNode}
                        onClose={editDialog.close}
                    />
                    <MoveTreeNodeDialog
                        key={`move_${selected}`}
                        treeNode={selectedTreeNode}
                        treeNodes={treeNodes.data}
                        open={moveDialog.isOpen}
                        onClose={moveDialog.close}
                        language={language}
                    />
                    <DeleteTreeNodeDialog
                        key={`delete_${selected}`}
                        treeNodes={treeNodes.data}
                        open={deleteDialog.isOpen}
                        onClose={deleteDialog.close}
                        treeNode={selectedTreeNode}
                        language={language}
                    />
                </>
            )}
            <OrderTreeNodeDialog
                open={orderDialog.isOpen}
                onClose={orderDialog.close}
                attributeId={attributeId}
                treeNodes={selectedTreeNode !== undefined ? selectedTreeNode.children : treeNodes.data}
                parentNode={selectedTreeNode}
                translate={t}
                language={language}
            />
        </>
    );
};

export default ManageTreeNodes;
