import { zodResolver } from "@hookform/resolvers/zod";
import { Checkbox, FormControlLabel, FormGroup, Stack, styled, Typography } from "@mui/material";
import { AclNamespaces, AclPermissions, AdminMeasureListDto, CreateAclsRequestBody, GroupDto, UserDto, UserTier } from "api-shared";
import { Controller, Resolver, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ActionItemDialog from "../../../components/dialogues/ActionItemDialog";
import Alert from "../../../components/Alert";

import { TFunction } from "i18next";
import { z } from "zod";
import Select from "../../../components/input/select/Select";
import { translationKeys } from "../../../translations/main-translations";
import { DialogForm } from "./DialogForm";
import { GroupSelect } from "./Inputs/GroupSelect";
import { UserSelect } from "./Inputs/UserSelect";
import { zFormUserGroupSchema } from "./PermissionsConstants";

type FormValues = Pick<CreateAclsRequestBody, "userIds" | "groupIds"> & { measureId: number; read: boolean; write: boolean };

type MeasureDialogProps = {
    open: boolean;
    onClose: () => void;
    measures: AdminMeasureListDto;
    users: UserDto[];
    groups: GroupDto[];
    onPermissionSubmit: (data: CreateAclsRequestBody) => void;
};

const ReducedPaddingCheckbox = styled(Checkbox)(({ theme }) => ({
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
}));

const customResolver: Resolver<FormValues> = async (values, context, options) => {
    if (values.read === false && values.write === false) {
        return {
            values,
            errors: { read: "At least one permission type is required", write: "At least one permission type is required" },
        };
    }
    const zod = zodResolver(
        zFormUserGroupSchema.and(z.object({ measureId: z.number().int().min(0), read: z.boolean(), write: z.boolean() })),
    );
    return zod(values, context, options);
};

const getMeasureOptions = (t: TFunction, measures?: AdminMeasureListDto) => {
    if (measures === undefined) {
        return [];
    } else {
        return [
            ...measures
                .map(({ id, title }) => ({
                    value: id,
                    label: title,
                }))
                .toSorted((a, b) => a.label.localeCompare(b.label)),
        ];
    }
};

export const MeasureDialog = ({ open = false, onClose = () => {}, measures, users, groups, onPermissionSubmit }: MeasureDialogProps) => {
    const { t } = useTranslation();

    const measureOptions = getMeasureOptions(t, measures);

    const {
        handleSubmit,
        control,
        formState: { isValid },
        reset,
    } = useForm<FormValues>({
        mode: "onChange",
        resolver: customResolver,
        defaultValues: { userIds: [], groupIds: [], measureId: undefined, read: false, write: false },
    });

    const onSubmit = handleSubmit((data) => {
        if (data.measureId !== null) {
            if (data.read) {
                onPermissionSubmit({
                    groupIds: data.groupIds,
                    userIds: data.userIds,
                    acl: {
                        namespace: AclNamespaces.Process,
                        permission: AclPermissions.Read,
                        rule: [{ fact: "id", operator: "equal", value: data.measureId }],
                        simpleEntityId: data.measureId,
                    },
                });
            }
            if (data.write) {
                onPermissionSubmit({
                    groupIds: data.groupIds,
                    userIds: data.userIds,
                    acl: {
                        namespace: AclNamespaces.Process,
                        permission: AclPermissions.Update,
                        rule: [{ fact: "id", operator: "equal", value: data.measureId }],
                        simpleEntityId: data.measureId,
                    },
                });
            }
        }
        onClose();
    });

    const onCancel = () => {
        onClose();
        reset();
    };

    return (
        <ActionItemDialog
            open={open}
            title={`${t(translationKeys.VDLANG_ACL_ADD_RULE)} - ${t(translationKeys.VDLANG_ACL_TITLE_PROCESS)}`}
            primary={t("Create")}
            primaryIsTranslated
            onPrimary={onSubmit}
            primaryDisabled={!isValid}
            onClose={onCancel}
            translate={t}
            disableContentPadding
            maxWidth="sm"
        >
            <DialogForm onSubmit={onSubmit} style={{ margin: 0 }}>
                <Stack gap={1}>
                    <Alert severity="info">{t(translationKeys.VDLANG_ACL_HINT_MEASURE_PERMISSION)}</Alert>
                    <Typography variant="subtitle2">{t(`${translationKeys.VDLANG_USER_TIERS}.${UserTier.Basic}`)}</Typography>
                    <Controller
                        name="measureId"
                        control={control}
                        render={({ field }) => {
                            const { value, onChange } = field;
                            return (
                                <Select
                                    margin="none"
                                    label={t(translationKeys.VDLANG_ACL_TITLE_PROCESS)}
                                    value={measureOptions.find((t) => t.value === value)}
                                    options={measureOptions}
                                    onChange={(o) => onChange(o?.value ?? 0)}
                                    menuPortalTarget={document.body}
                                    placeholder={`${t("Search")}...`}
                                    isSearchable={true}
                                />
                            );
                        }}
                    />
                    <Controller
                        name="read"
                        control={control}
                        render={({ field }) => {
                            const { value, onChange } = field;
                            return (
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <ReducedPaddingCheckbox
                                                size="small"
                                                checked={value}
                                                onChange={(_, checked) => onChange(checked)}
                                            />
                                        }
                                        label={t(`${translationKeys.VDLANG_ACL_PERMISSIONS}.${AclPermissions.Read}`)}
                                    />
                                </FormGroup>
                            );
                        }}
                    />
                    <Controller
                        name="write"
                        control={control}
                        render={({ field }) => {
                            const { value, onChange } = field;
                            return (
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <ReducedPaddingCheckbox
                                                size="small"
                                                checked={value}
                                                onChange={(_, checked) => onChange(checked)}
                                            />
                                        }
                                        label={t(`${translationKeys.VDLANG_ACL_PERMISSIONS}.${AclPermissions.Update}`)}
                                    />
                                </FormGroup>
                            );
                        }}
                    />
                    <Controller
                        name="groupIds"
                        control={control}
                        render={({ field }) => {
                            const { value, onChange } = field;
                            return <GroupSelect onChange={onChange} value={value} groups={groups} />;
                        }}
                    />
                    <Controller
                        name="userIds"
                        control={control}
                        render={({ field }) => {
                            const { value, onChange } = field;
                            return <UserSelect value={value} onChange={onChange} users={users} />;
                        }}
                    />
                </Stack>
            </DialogForm>
        </ActionItemDialog>
    );
};
