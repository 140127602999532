import ReplayIcon from "@mui/icons-material/ReplayRounded";
import SendIcon from "@mui/icons-material/SendRounded";
import { UserStatus } from "api-shared";
import { TFunction } from "i18next";
import TableIconButton from "../../../components/table/TableIconButton";

interface IPasswortResetTableCellProps {
    userId: number;
    status: UserStatus;
    translate: TFunction;
    resetUserInvitation: (userId: number) => void;
    resetPassword: (userId: number) => void;
}

const ActionLabel = {
    [UserStatus.STATUS_INVITED]: "reinvite",
} as Record<UserStatus, string | undefined>;

const PasswortResetTableCell = ({ userId, status, translate, resetUserInvitation, resetPassword }: IPasswortResetTableCellProps) => {
    const handleClick = () => {
        if (status === UserStatus.STATUS_INVITED) {
            resetUserInvitation(userId);
        } else {
            resetPassword(userId);
        }
    };

    return (
        <TableIconButton
            title={translate(ActionLabel[status] ?? "reset_user_password")}
            onClick={handleClick}
            disabled={status === UserStatus.STATUS_INACTIVE}
        >
            {status === UserStatus.STATUS_INVITED ? <SendIcon /> : <ReplayIcon />}
        </TableIconButton>
    );
};

export default PasswortResetTableCell;
