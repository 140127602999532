import { Button, Card, CardActions, CardContent, CardHeader, FormGroup, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Form from "../../../components/Form";
import { useGroups } from "../../../domain/admin/groups";

import { AclPermissions, ClientGroupType, GroupType, UserTier } from "api-shared";
import Select from "../../../components/input/select/Select";
import { useAdminUpdateClient } from "../../../domain/admin/client";
import { useCurrentClient } from "../../../domain/client";
import { translationKeys } from "../../../translations/main-translations";
import {
    getReadUpdatePermissionOption,
    getReadUpdatePermissionOptions,
    getUserTierOption,
    getUserTierOptions,
} from "../members/user-utils";
import { GroupSelect } from "../permissions/Inputs/GroupSelect";

const DefaultPermissionSettings = () => {
    const updateCurrentClientMutation = useAdminUpdateClient();
    const updateClient = updateCurrentClientMutation.mutate;

    const { t: translate } = useTranslation();
    const client = useCurrentClient();
    const groupsQuery = useGroups();

    const userTierOptions = getUserTierOptions(translate);
    const permissionOptions = getReadUpdatePermissionOptions(translate);

    const [clientGroups, setClientGroups] = useState(client.clientGroups);
    const [clientDefaultGroups, setClientDefaultGroups] = useState(
        client.clientGroups.filter((group) => group.type === ClientGroupType.DEFAULT_GROUP).map((group) => group.groupId),
    );
    const [clientExternalUserGroups, setClientExternalUserGroups] = useState(
        client.clientGroups.filter((group) => group.type === ClientGroupType.EXTERNAL_GROUP).map((group) => group.groupId),
    );
    const [clientDefaultMeasureGroups, setClientDefaultMeasureGroups] = useState(
        client.clientGroups.filter((group) => group.type === ClientGroupType.MEASURE_GROUP).map((group) => group.groupId),
    );

    const [clientDefaultUserTier, setClientDefaultUserTier] = useState(client.defaultUserTier);
    const [clientExternalUserTier, setClientExternalUserTier] = useState(client.externalUserTier);
    const [clientDefaultMeasurePermission, setClientDefaultMeasurePermission] = useState(client.defaultMeasurePermission);

    const [touched, setTouched] = useState(false);

    const filteredGroups = groupsQuery.data?.filter((group) => group.type === GroupType.STANDARD) ?? [];

    function setGroupsForType(groupType: ClientGroupType, groups: number[]) {
        const currentGroups = clientGroups.filter((group) => group.type !== groupType);
        const newGroups = groups.map((groupId) => ({ groupId, type: groupType }));
        setClientGroups([...currentGroups, ...newGroups]);
    }

    const handleDefaultGroupChange = (groups: number[]) => {
        setGroupsForType(ClientGroupType.DEFAULT_GROUP, groups);
        setClientDefaultGroups(groups);
        setTouched(true);
    };
    const handleExternalUserGroupChange = (groups: number[]) => {
        setGroupsForType(ClientGroupType.EXTERNAL_GROUP, groups);
        setClientExternalUserGroups(groups);
        setTouched(true);
    };
    const handleDefaultMeasureGroup = (groups: number[]) => {
        setGroupsForType(ClientGroupType.MEASURE_GROUP, groups);
        setClientDefaultMeasureGroups(groups);
        setTouched(true);
    };

    const handleDefaultTierChange = (tier: UserTier) => {
        setClientDefaultUserTier(tier);
        setTouched(true);
    };
    const handleExternalTierChange = (tier: UserTier) => {
        setClientExternalUserTier(tier);
        setTouched(true);
    };
    const handleDefaultMeasurePermissionChange = (permission: AclPermissions) => {
        setClientDefaultMeasurePermission(permission);
        setTouched(true);
    };

    const submit = () => {
        const changes = {
            clientGroups: clientGroups,
            defaultUserTier: clientDefaultUserTier,
            externalUserTier: clientExternalUserTier,
            defaultMeasurePermission: clientDefaultMeasurePermission,
        };
        updateClient(changes);
        setTouched(false);
    };

    return (
        <Grid container spacing={1} justifyContent="center">
            <Grid item xs={12} md={7}>
                <Card>
                    <CardHeader title={translate(translationKeys.VDLANG_ADMIN_SIDEBAR_USERS_AND_ACCESS_DEFAULT_PERMISSIONS)} />
                    <CardContent>
                        <Typography variant="subtitle1">
                            {translate(translationKeys.VDLANG_ADMIN_DEFAULT_PERMISSIONS_DEFAULT_GROUP)}
                        </Typography>
                        <Typography variant="subtitle2" color="textSecondary">
                            {translate(translationKeys.VDLANG_ADMIN_DEFAULT_PERMISSIONS_DEFAULT_GROUP_HINT)}
                        </Typography>
                        <Form onSubmit={submit}>
                            <FormGroup sx={{ mt: 1 }}>
                                <GroupSelect
                                    groups={filteredGroups}
                                    onChange={(groups) => handleDefaultGroupChange(groups)}
                                    value={clientDefaultGroups}
                                />
                            </FormGroup>
                            <FormGroup sx={{ mt: 1 }}>
                                <Select
                                    name="defaultusertier"
                                    label={translate(translationKeys.VDLANG_USER_TIER)}
                                    value={getUserTierOption(clientDefaultUserTier, translate)}
                                    onChange={(option) => handleDefaultTierChange(option?.value ?? userTierOptions[0].value)}
                                    options={userTierOptions}
                                    margin="none"
                                    menuPortalTarget={document.body}
                                />
                            </FormGroup>

                            <Typography variant="subtitle1" mt={4}>
                                {translate(translationKeys.VDLANG_ADMIN_DEFAULT_PERMISSIONS_EXTERNAL_USER_GROUP)}
                            </Typography>
                            <Typography variant="subtitle2" color="textSecondary">
                                {translate(translationKeys.VDLANG_ADMIN_DEFAULT_PERMISSIONS_EXTERNAL_USER_GROUP_HINT)}
                            </Typography>
                            <FormGroup sx={{ mt: 1 }}>
                                <GroupSelect
                                    groups={filteredGroups}
                                    onChange={(groups) => handleExternalUserGroupChange(groups)}
                                    value={clientExternalUserGroups}
                                />
                            </FormGroup>
                            <FormGroup sx={{ mt: 1 }}>
                                <Select
                                    name="externalusertier"
                                    label={translate(translationKeys.VDLANG_USER_TIER)}
                                    value={getUserTierOption(clientExternalUserTier, translate)}
                                    onChange={(option) => handleExternalTierChange(option?.value ?? userTierOptions[0].value)}
                                    options={userTierOptions}
                                    margin="none"
                                    menuPortalTarget={document.body}
                                />
                            </FormGroup>

                            <Typography variant="subtitle1" mt={4}>
                                {translate(translationKeys.VDLANG_ADMIN_DEFAULT_PERMISSIONS_PSEUDO_PUBLIC_MESURE_GROUP)}
                            </Typography>
                            <Typography variant="subtitle2" color="textSecondary">
                                {translate(translationKeys.VDLANG_ADMIN_DEFAULT_PERMISSIONS_PSEUDO_PUBLIC_MESURE_GROUP_HINT)}
                            </Typography>
                            <FormGroup sx={{ mt: 1 }}>
                                <GroupSelect
                                    groups={groupsQuery.data ?? []}
                                    onChange={(groups) => handleDefaultMeasureGroup(groups)}
                                    value={clientDefaultMeasureGroups}
                                />
                            </FormGroup>
                            <FormGroup sx={{ mt: 1 }}>
                                <Select
                                    name="defaultmeasurepermission"
                                    label={translate(translationKeys.VDLANG_ACL_PERMISSION)}
                                    value={getReadUpdatePermissionOption(clientDefaultMeasurePermission, translate)}
                                    onChange={(option) => handleDefaultMeasurePermissionChange(option?.value ?? permissionOptions[0].value)}
                                    options={permissionOptions}
                                    margin="none"
                                    menuPortalTarget={document.body}
                                />
                            </FormGroup>
                        </Form>
                    </CardContent>
                    <CardActions>
                        <Button onClick={submit} disabled={!touched} variant={touched ? "contained" : "outlined"} color="primary">
                            {translate(translationKeys.VDLANG_SAVE)}
                        </Button>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
    );
};

export default DefaultPermissionSettings;
