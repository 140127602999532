import { GroupDto } from "api-shared";
import { useTranslation } from "react-i18next";
import Select from "../../../../components/input/select/Select";
import { useLanguage } from "../../../../hooks/useLanguage";
import { fullGroupPath } from "../../../../lib/groups";
import { translationKeys } from "../../../../translations/main-translations";

type GroupSelectProps = {
    value: number[];
    onChange: (value: number[]) => void;
    groups?: GroupDto[];
};

export const GroupSelect = ({ groups, value, onChange }: GroupSelectProps) => {
    const { t } = useTranslation();
    const language = useLanguage();

    const groupOptions = groups
        ?.map(({ id }) => ({
            value: id,
            label: fullGroupPath(groups, id, language),
        }))
        .toSorted((a, b) => a.label.localeCompare(b.label));

    return (
        <Select
            margin="none"
            label={t(translationKeys.VDLANG_ADMIN_MEMBERS_GROUPS)}
            value={groupOptions?.filter((option) => value.includes(option.value))}
            options={groupOptions}
            onChange={(o) => onChange(o.map((v) => v.value))}
            menuPortalTarget={document.body}
            placeholder={`${t("Search")}...`}
            isSearchable={true}
            isMulti
        />
    );
};
