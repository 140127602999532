import { Table } from "@devexpress/dx-react-grid-material-ui";
import { styled } from "@mui/material";

const DataGridCell = (props: Table.DataCellProps) => <Table.Cell {...props} />;

export default styled(DataGridCell)(({ theme }) => ({
    "&.TableCell-cell": {
        padding: theme.spacing(1, 2),
    },
}));
