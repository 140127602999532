import AddIcon from "@mui/icons-material/AddRounded";
import { Button, Divider, Stack } from "@mui/material";
import { GroupDto, GroupType } from "api-shared";
import { uniq } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { CellProps, Column } from "react-table";
import DeleteDialog from "../../../components/dialogues/DeleteDialog";
import SearchInput from "../../../components/input/SearchInput";
import BaseTable from "../../../components/table/BaseTable";
import TableBooleanCell from "../../../components/table/TableBooleanCell";
import TableFloatCell from "../../../components/table/TableFloatCell";
import TableHeaderCell from "../../../components/table/TableHeaderCell";
import TableTextCell, { ITableTextCellProps } from "../../../components/table/TableTextCell";
import { useDeleteGroup } from "../../../domain/admin/groups";
import { useAlphanumericStringSort } from "../../../hooks/useAlphanumericStringSort";
import { useDebounce } from "../../../hooks/useDebounce";
import useDialog from "../../../hooks/useDialog";
import { getChildGroups } from "../../../lib/groups";
import { translationKeys } from "../../../translations/main-translations";
import { GroupDialog } from "./GroupDialog";
import { GroupTableDeleteCell } from "./GroupTableDeleteCell";
import { GroupTableEditCell } from "./GroupTableEditCell";
import { GroupTableNameCell } from "./GroupTableNameCell";

const EMPTY_GROUPS: GroupDto[] = [];

interface GroupTableProps {
    groupsToShow: GroupDto[];
    allGroups: GroupDto[];
    onGroupNavigate: (id: number) => void;
    currentGroupLevel: number | null;
}

export const GroupTable = ({ groupsToShow, allGroups, currentGroupLevel, onGroupNavigate }: GroupTableProps) => {
    const { t } = useTranslation();
    const { compare } = useAlphanumericStringSort();
    const standardGroups = groupsToShow.filter((group) => group.type === GroupType.STANDARD);
    const specialGroups = groupsToShow.filter((group) => group.type !== GroupType.STANDARD);
    const sortedStandardGroups = standardGroups.toSorted((a, b) => compare(a.nameEn, b.nameEn));
    const sortedSpecialGroups = specialGroups.toSorted((a, b) => compare(a.nameEn, b.nameEn));
    const sortedGroups = sortedSpecialGroups.concat(sortedStandardGroups);
    const [groupToDeleteId, setGroupToDeleteId] = useState<number | undefined>(undefined);

    const [searchKey, setSearchKey] = useState("");

    const [menuId, setMenuId] = useState<number | null>(null);

    const debouncedSearchKey = useDebounce(searchKey);

    const groupDialog = useDialog();

    const handleClose = () => {
        groupDialog.close();
        setMenuId(null);
    };

    const onSelect = useCallback(
        (value: number) => {
            setMenuId(value);
            groupDialog.open();
        },
        [groupDialog],
    );

    const deleteGroupMutation = useDeleteGroup();

    const onRemove = () => {
        if (groupToDeleteId === undefined) {
            return;
        }
        deleteGroupMutation.mutate(groupToDeleteId);
        setGroupToDeleteId(undefined);
    };

    const columns = useMemo<Column<GroupDto>[]>(() => {
        const ColumnCountMap = new Map<number, { totalUserCount: number; totalSubgroupCount: number; subgroupCount: number }>(
            groupsToShow.map((group) => {
                const currentChildren = getChildGroups(allGroups, group);
                return [
                    group.id,
                    {
                        totalUserCount: uniq([...group.userIds, ...currentChildren.map((c) => c.userIds).flat()]).length,
                        totalSubgroupCount: currentChildren.length,
                        subgroupCount: allGroups.filter((g) => g.parentGroupId === group.id).length,
                    },
                ];
            }),
        );
        return [
            {
                id: "nameEn",
                accessor: (group) => group.nameEn,
                label: `${t(translationKeys.VDLANG_ADMIN_GROUPS_GROUP_NAME)} (EN)`,
                Header: TableHeaderCell,
                Cell: (cell: CellProps<GroupDto>) => <GroupTableNameCell cell={cell} onClick={onGroupNavigate} />,
            },
            {
                id: "descriptionEn",
                accessor: (group) => group.descriptionEn,
                label: `${t(translationKeys.VDLANG_ADMIN_GROUPS_GROUP_DESCRIPTION)} (EN)`,
                Header: TableHeaderCell,
                Cell: TableTextCell,
            },
            {
                id: "nameDe",
                accessor: (group) => group.nameDe,
                label: `${t(translationKeys.VDLANG_ADMIN_GROUPS_GROUP_NAME)} (DE)`,
                Header: TableHeaderCell,
                Cell: (cell: CellProps<GroupDto>) => <GroupTableNameCell cell={cell} onClick={onGroupNavigate} />,
            },
            {
                id: "descriptionDe",
                accessor: (group) => group.descriptionDe,
                label: `${t(translationKeys.VDLANG_ADMIN_GROUPS_GROUP_DESCRIPTION)} (DE)`,
                Header: TableHeaderCell,
                Cell: TableTextCell,
            },
            {
                id: "all_subgroups",
                accessor: (group) => ColumnCountMap.get(group.id)?.totalSubgroupCount,
                label: t(translationKeys.VDLANG_ADMIN_GROUPS_ALL_SUBGROUPS),
                Header: TableHeaderCell,
                Cell: TableFloatCell,
            },
            {
                id: "all_users",
                accessor: (group) => ColumnCountMap.get(group.id)?.totalUserCount,
                label: t(translationKeys.VDLANG_ADMIN_GROUPS_ALL_USERS),
                Header: TableHeaderCell,
                Cell: TableFloatCell,
            },
            {
                id: "subgroups",
                accessor: (group) => ColumnCountMap.get(group.id)?.subgroupCount,
                label: t(translationKeys.VDLANG_ADMIN_GROUPS_SUBGROUPS),
                Header: TableHeaderCell,
                Cell: TableFloatCell,
            },
            {
                id: "users",
                accessor: (group) => group.userIds.length,
                label: t(translationKeys.VDLANG_ADMIN_GROUPS_USERS),
                Header: TableHeaderCell,
                Cell: TableFloatCell,
            },
            {
                id: "is_visible",
                accessor: (group) => group.isVisible,
                label: t(translationKeys.VDLANG_ADMIN_GROUPS_VISIBLE),
                Header: TableHeaderCell,
                Cell: (cell: ITableTextCellProps<GroupDto>) => (
                    <TableBooleanCell
                        value={cell.value}
                        trueText={t(translationKeys.VDLANG_YES)}
                        falseText={t(translationKeys.VDLANG_NO)}
                    />
                ),
            },
            {
                Header: "",
                accessor: "id",
                id: "edit",
                width: 64,
                disableResizing: true,
                disableSortBy: true,
                Cell: (cell) => <GroupTableEditCell cell={cell} onClick={onSelect} />,
            },
            {
                Header: "",
                accessor: "id",
                id: "delete",
                width: 64,
                disableResizing: true,
                disableSortBy: true,
                Cell: (cell) => <GroupTableDeleteCell cell={cell} onClick={setGroupToDeleteId} />,
            },
        ];
    }, [allGroups, groupsToShow, onGroupNavigate, onSelect, t]);

    return (
        <>
            <GroupDialog
                key={menuId}
                open={groupDialog.isOpen}
                onClose={handleClose}
                currentGroup={menuId ? allGroups.find((group) => group.id === menuId) : undefined}
                parentGroupId={currentGroupLevel}
            />
            <DeleteDialog
                item="group"
                translate={t}
                open={groupToDeleteId !== undefined}
                onClose={() => setGroupToDeleteId(undefined)}
                onDelete={onRemove}
            />
            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ px: 3, py: 1.5 }}>
                <SearchInput searchKey={searchKey} onChange={setSearchKey} translate={t} fullWidth={false} />
                <Button variant="contained" startIcon={<AddIcon />} onClick={groupDialog.open}>
                    {t(translationKeys.VDLANG_ADMIN_GROUPS_CREATE)}
                </Button>
            </Stack>
            <Divider />

            <BaseTable
                fullHeight
                isFetching={false}
                data={sortedGroups ?? EMPTY_GROUPS}
                columns={columns}
                itemName="groups"
                translate={t}
                globalFilter={debouncedSearchKey}
                noDataText={t(translationKeys.VDLANG_ADMIN_GROUPS_NO_GROUPS)}
                disablePagination
            />
        </>
    );
};
