import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { OnChangeValue, SingleValueProps, components } from "react-select";
import { StaticBadge } from "../../../components/StaticBadge";
import Select from "../../../components/input/select/Select";
import SelectSingleValue from "../../../components/input/select/components/SelectSingleValue";
import { Option } from "../../../components/input/select/types";
import { MeasureAction } from "./MeasuresSettings";

type Props = {
    value: MeasureAction | null;
    options: Option<MeasureAction>[];
    onChange?: (value: MeasureAction | null) => void;
    count: number;
};

const MeasureBulkSelect = ({ value, options, count, onChange }: Props) => {
    const { t } = useTranslation();

    const handleChange = (newValue: OnChangeValue<Option<MeasureAction>, false>) => {
        onChange?.(newValue?.value ?? null);
    };

    const SingleValueSelect = useMemo(
        () =>
            ({ children, ...props }: SingleValueProps<Option<MeasureAction>, false>) => (
                <SelectSingleValue {...props}>
                    {children} {count > 0 && <StaticBadge badgeContent={count} color="primary" />}
                </SelectSingleValue>
            ),
        [count],
    );

    return (
        <Select
            isMulti={false}
            isSearchable={false}
            isClearable={true}
            smallGutters
            placeholder={t("bulkActionSelect")}
            value={value === null ? null : options.find((option) => option.value === value)}
            options={options}
            onChange={handleChange}
            margin="none"
            components={{
                SingleValue: SingleValueSelect,
                Placeholder: components.Placeholder,
            }}
        />
    );
};

export default React.memo(MeasureBulkSelect);
