import DeleteIcon from "@mui/icons-material/DeleteRounded";
import EditIcon from "@mui/icons-material/EditRounded";
import VerifiedIcon from "@mui/icons-material/VerifiedUserRounded";
import { Button, Divider, Grid, Paper, Stack, styled } from "@mui/material";
import { DomainDto, IdentityProviderDto } from "api-shared";
import { TFunction } from "i18next";
import { useCallback, useMemo, useState } from "react";
import Tooltip from "../../../components/Tooltip";
import DeleteDialog from "../../../components/dialogues/DeleteDialog";
import BaseTable from "../../../components/table/BaseTable";
import TableHeaderCell from "../../../components/table/TableHeaderCell";
import TableIconButton from "../../../components/table/TableIconButton";
import TableTextCell, { ITableTextCellProps } from "../../../components/table/TableTextCell";
import { useAdminDeleteDomain } from "../../../domain/admin/domains";
import useDialog from "../../../hooks/useDialog";
import { translationKeys } from "../../../translations/main-translations";
import CreateEditDomainDialog from "./CreateEditDomainDialog";

const Root = styled(Paper)({ height: "100%" });
const RootGrid = styled(Grid)({ height: "100%" });

const Grow = styled(Grid)(() => ({
    flexGrow: 1,
    flexShrink: 1,
    height: 0, // work around bug in chrome 69/safari with unscollable page
}));

interface IDomainsSettingsProps {
    translate: TFunction;
    domains: DomainDto[];
    identityProviders: IdentityProviderDto[];
}

const DomainsSettings = ({ translate, domains, identityProviders }: IDomainsSettingsProps) => {
    const [activeDomainId, setActiveDomainId] = useState<number | undefined>(undefined);
    const [domainToDeleteId, setDomainToDeleteId] = useState<number | undefined>(undefined);

    const { isOpen: isDialogOpen, closeDialog, openDialog } = useDialog();

    const deleteDomainMutation = useAdminDeleteDomain();

    const onNew = () => {
        setActiveDomainId(undefined);
        openDialog();
    };

    const onSelect = useCallback(
        (value: any) => {
            setActiveDomainId(value);
            openDialog();
        },
        [setActiveDomainId, openDialog],
    );

    const onRemove = () => {
        if (domainToDeleteId === undefined) {
            return;
        }
        deleteDomainMutation.mutate(domainToDeleteId);
        setDomainToDeleteId(undefined);
    };

    const columns: any = useMemo(() => {
        return [
            {
                Header: TableHeaderCell,
                label: translate("Name"),
                accessor: "name",
                Cell: TableTextCell,
                id: "name",
            },
            {
                Header: TableHeaderCell,
                label: translate(translationKeys.VDLANG_VERIFIED_BY_VALUEDESK),
                accessor: "originatorId",
                id: "originatorId",
                width: 192,
                disableResizing: true,
                disableSortBy: true,
                Cell: (c: any) =>
                    c.row.values.originatorId === null ? (
                        <Tooltip title={translate(translationKeys.VDLANG_VERIFIED_BY_VALUEDESK_INFO)}>
                            <VerifiedIcon />
                        </Tooltip>
                    ) : (
                        <TableTextCell />
                    ),
            },
            {
                Header: TableHeaderCell,
                label: translate(translationKeys.VDLANG_ADMIN_SIGNON),
                accessor: "identityProviderId",
                id: "identityProviderId",
                width: 192,
                disableResizing: true,
                disableSortBy: true,
                Cell: (c: ITableTextCellProps) => (
                    <TableTextCell {...c} value={c?.value > 0 ? identityProviders.find((sel) => sel.id === c.value)?.name : "-"} />
                ),
            },
            {
                Header: "",
                accessor: "id",
                id: "edit",
                width: 64,
                disableResizing: true,
                disableSortBy: true,
                Cell: (c: any) => (
                    <TableIconButton onClick={() => onSelect(c.value)}>
                        <EditIcon />
                    </TableIconButton>
                ),
            },
            {
                Header: "",
                accessor: "id",
                id: "delete",
                width: 64,
                disableResizing: true,
                disableSortBy: true,
                Cell: (c: any) => (
                    <TableIconButton
                        disabled={c.row.values.identityProviderId != null || c.row.values.originatorId === null}
                        onClick={() => setDomainToDeleteId(c.value)}
                        title={
                            c.row.values.originatorId === null
                                ? translate(translationKeys.VDLANG_VERIFIED_BY_VALUEDESK_DELETE)
                                : c.row.values.identityProviderId != null
                                  ? translate(translationKeys.VDLANG_IDENTITY_PROVIDER_ASSIGNED_DELETE)
                                  : ""
                        }
                    >
                        <DeleteIcon />
                    </TableIconButton>
                ),
            },
        ];
    }, [translate, identityProviders, onSelect]);

    return (
        <Root>
            <RootGrid container direction="column" wrap="nowrap">
                <CreateEditDomainDialog
                    key={activeDomainId ?? -1}
                    open={isDialogOpen}
                    onClose={closeDialog}
                    translate={translate}
                    domain={domains.find((sel) => sel.id === activeDomainId)}
                    domains={domains}
                    identityProviders={identityProviders}
                />
                <DeleteDialog
                    item="domain"
                    translate={translate}
                    open={domainToDeleteId !== undefined}
                    onClose={() => setDomainToDeleteId(undefined)}
                    onDelete={onRemove}
                />
                <Grid item>
                    <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} px={3} py={1.2}>
                        <Button variant="contained" color="primary" onClick={onNew}>
                            {translate(translationKeys.VDLANG_ADMIN_DOMAIN_CREATE)}
                        </Button>
                    </Stack>
                </Grid>
                <Grid item component={Divider} />
                <Grow item>
                    <BaseTable
                        fullHeight
                        data={domains}
                        columns={columns}
                        itemName={translationKeys.VDLANG_ADMIN_DOMAIN}
                        translate={translate}
                        noDataText={translate(translationKeys.VDLANG_NO_DOMAINS)}
                    />
                </Grow>
            </RootGrid>
        </Root>
    );
};

export default DomainsSettings;
