import { Button } from "@mui/material";
import { CompanyDto, CompanyUpdateDto } from "api-shared";
import { TFunction } from "i18next";
import { useState } from "react";
import DangerMenuItem from "../../../components/DangerMenuItem";
import BaseDialog from "../../../components/dialogues/BaseDialog";
import DialogBackButton from "../../../components/dialogues/DialogBackButton";
import { translationKeys } from "../../../translations/main-translations";
import CompanyForm from "./CompanyForm";

interface IEditCompanyDialogProps {
    submitCallback: (company: CompanyUpdateDto) => void;
    onClose: () => void;
    open: boolean;
    translate: TFunction;
    company: CompanyDto;
    onDelete: () => void;
}

const EditCompanyDialog = ({ company, translate, open, submitCallback, onClose, onDelete }: IEditCompanyDialogProps) => {
    const [isFilled, setIsFilled] = useState(false);
    const [currentCompany, setCurrentCompany] = useState<CompanyUpdateDto>({
        name: company.name,
        city: company.city ? company.city : undefined,
        country: company.country ? company.country : undefined,
        street: company.street ? company.street : undefined,
        zip: company.zip ? company.zip : undefined,
    });

    const submit = () => {
        submitCallback(currentCompany);
    };

    const actions = [
        <DialogBackButton key="cancel" onClick={onClose} translate={translate} />,
        <Button key={translationKeys.VDLANG_SAVE} variant="contained" color="primary" disabled={!isFilled} onClick={submit}>
            {translate(translationKeys.VDLANG_SAVE)}
        </Button>,
    ];
    const menuActions =
        company != null && !company.isUsed
            ? [
                  <DangerMenuItem key={translationKeys.VDLANG_REMOVE} onClick={onDelete}>
                      {translate("Remove")}
                  </DangerMenuItem>,
              ]
            : [];

    return (
        <BaseDialog open={open} onClose={onClose} title={translate("Edit Company")} actions={actions} menuActions={menuActions}>
            <CompanyForm
                translate={translate}
                filledCallback={(isFilled: boolean, company?: CompanyUpdateDto) => {
                    if (company) {
                        setCurrentCompany(company);
                    }
                    setIsFilled(isFilled);
                }}
                onSubmit={submit}
                company={currentCompany}
            />
        </BaseDialog>
    );
};

export default EditCompanyDialog;
