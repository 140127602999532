import { AclSubjectType } from "api-shared";
import { z } from "zod";

// Must have at least one user or group
export const zFormUserGroupSchema = z.union([
    z.object({ userIds: z.array(z.number()).min(1), groupIds: z.array(z.number()).min(0) }),
    z.object({ userIds: z.array(z.number()).min(0), groupIds: z.array(z.number()).min(1) }),
    z.object({ userIds: z.array(z.number()).min(1), groupIds: z.array(z.number()).min(1) }),
]);

export type UserOrGroupOption = { label: string; value: string; entityId: number; type: AclSubjectType };
