import { DndContext, closestCenter } from "@dnd-kit/core";
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { ListItemText, Paper, Typography } from "@mui/material";
import { Language, TreeNodeDto } from "api-shared";
import { TFunction } from "i18next";
import { useState } from "react";
import { Trans } from "react-i18next";
import ActionItemDialog from "../../../../../components/dialogues/ActionItemDialog";
import DndItem from "../../../../../components/draggable-list/DndItem";
import { useAdminReorderTreeNodes } from "../../../../../domain/admin/tree-nodes";
import { useAlphanumericStringSort } from "../../../../../hooks/useAlphanumericStringSort";
import useSortableList from "../../../../../hooks/useSortableList";
import { sortTreeNodes } from "../../../../../lib/sort";
import { translateFromProperty } from "../../../../../lib/translate";
import { translationKeys } from "../../../../../translations/main-translations";

interface IOrderTreeNodeDialogProps {
    attributeId: number;
    treeNodes: TreeNodeDto[];
    parentNode?: TreeNodeDto;
    translate: TFunction;
    language: Language;
    open: boolean;
    onClose: () => void;
}

const OrderTreeNodeDialog = ({ treeNodes, attributeId, parentNode, translate, language, open, onClose }: IOrderTreeNodeDialogProps) => {
    const { compare } = useAlphanumericStringSort();

    const [reorderedRelations, setReorderedRelations] = useState<TreeNodeDto[] | undefined>(undefined);

    const displayedRelations = reorderedRelations ?? sortTreeNodes(treeNodes, language, compare);

    const { sensors, onDragEnd, onDragStart } = useSortableList({ items: displayedRelations, updateItems: setReorderedRelations });

    const updateTreeNodeOrder = useAdminReorderTreeNodes(attributeId).mutate;

    const onSave = () => {
        const updatedOrder = displayedRelations.map((relation, index) => ({ ...relation, order: index + 1 }));
        updateTreeNodeOrder({ treeNodes: updatedOrder, attributeId });
    };

    const onCloseDialog = () => {
        onClose();
        setReorderedRelations(undefined);
    };

    return (
        <ActionItemDialog
            open={open}
            onClose={onCloseDialog}
            primary={translate(translationKeys.VDLANG_SAVE)}
            primaryIsTranslated
            title={translate(translationKeys.VDLANG_TREE_NODE_ADJUST_ORDER)}
            onPrimary={onSave}
            translate={translate}
        >
            <Typography sx={{ pb: 2 }}>
                {parentNode !== undefined ? (
                    <Trans<translationKeys>
                        i18nKey={translationKeys.VDLANG_TREE_NODE_ADJUST_CHILD_ORDER_DESCRIPTION}
                        values={{ parentNode: translateFromProperty(parentNode, "name", language) }}
                    />
                ) : (
                    translate(translationKeys.VDLANG_TREE_NODE_ADJUST_ROOT_ORDER_DESCRIPTION)
                )}
            </Typography>
            <Paper>
                <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragStart={onDragStart}
                    onDragEnd={onDragEnd}
                    modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
                >
                    <SortableContext items={displayedRelations} strategy={verticalListSortingStrategy}>
                        {displayedRelations.map((item) => (
                            <DndItem key={item.id} id={item.id} divider showHandle>
                                <ListItemText>
                                    <Typography>{translateFromProperty(item, "name", language)}</Typography>
                                </ListItemText>
                            </DndItem>
                        ))}
                    </SortableContext>
                </DndContext>
            </Paper>
        </ActionItemDialog>
    );
};

export default OrderTreeNodeDialog;
