import { Divider, Grid, GridProps, Paper, PaperProps, Typography, styled } from "@mui/material";
import { MeasureCalculationGranularity } from "api-shared";
import { max, min } from "lodash";
import moment, { Moment } from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import DeleteDialog from "../../../components/dialogues/DeleteDialog";
import InfoIcon from "../../../components/icons/InfoIcon";
import CalculationTimerangeSelect from "../../../components/input/date/CalculationTimerangeSelect";
import LoadingAnimation from "../../../components/loading/LoadingAnimation";
import { useAdminCurrencies, useAdminDeleteCurrency } from "../../../domain/admin/currencies";
import { useClientFiscalYear } from "../../../domain/client";
import { translationKeys } from "../../../translations/main-translations";
import CurrencyTable from "./CurrencyTable";

const Root = styled("div")(() => ({ height: "100%" }));

const Container = styled(Grid)<
    {
        // fix for `component` typing
        // see: https://github.com/mui/material-ui/issues/13921
        component?: React.ElementType<PaperProps>;
    } & GridProps
>(() => ({
    height: "100%",
}));

const CurrencyInfoIcon = styled(InfoIcon)(({ theme }) => ({
    marginLeft: theme.spacing(0.5),
    fontSize: "1rem",
}));

const CurrencySettingsTable = styled(CurrencyTable)(() => ({
    flex: 1,
    // The element itself is a flex element, whose children use grow/shrink in the same flex direction
    // for their grow/shrink to work, their parent element (= this table element) has to define a height somehow
    minHeight: 0,
}));

function momentFromYear(year: number, fiscalYearStart: number): Moment {
    return moment().year(year).month(fiscalYearStart).startOf("month");
}

const CurrencySettings = () => {
    const { t: translate } = useTranslation();

    const fiscalYearStart = useClientFiscalYear();

    const [currencyToDelete, setCurrencyToDelete] = useState<number | null>(null);

    const [customStart, setCustomStart] = useState<Moment | null>(null);
    const [customEnd, setCustomEnd] = useState<Moment | null>(null);

    const currenciesQuery = useAdminCurrencies();
    const removeCurrencyMutation = useAdminDeleteCurrency();
    if (!currenciesQuery.isSuccess) {
        // This happens only when currencies are not loaded yet
        return <LoadingAnimation />;
    }
    const defaultCurrency = currenciesQuery.data.find(({ isDefault }) => isDefault);

    const allExchangeRateYears =
        currenciesQuery.data.length > 0
            ? currenciesQuery.data.flatMap((c) => c.exchangeRates ?? []).map(({ fiscalYear }) => fiscalYear)
            : [];

    const earliestExchangeRate = min(allExchangeRateYears);
    const latestExchangeRate = max(allExchangeRateYears);

    if (earliestExchangeRate === undefined || latestExchangeRate === undefined) {
        // This cannot happen as allExchangeRateYears has at least one element
        throw new Error("Invalid currency configuration");
    }

    const start = customStart ?? momentFromYear(earliestExchangeRate, fiscalYearStart);
    const end = customEnd ?? momentFromYear(latestExchangeRate, fiscalYearStart);

    return (
        <Root>
            {currencyToDelete !== null && (
                <DeleteDialog
                    open={Boolean(currencyToDelete)}
                    translate={translate}
                    onClose={() => setCurrencyToDelete(null)}
                    onDelete={() => currencyToDelete != null && removeCurrencyMutation.mutate(currencyToDelete)}
                    item="currency"
                >
                    {translate("remove_currency_description", {
                        currency: currenciesQuery.data.find((c) => c.id === currencyToDelete)?.name,
                    })}
                </DeleteDialog>
            )}
            <Container container direction="column" wrap="nowrap" component={Paper}>
                <Grid container justifyContent="space-between" alignItems="center" sx={{ py: 1.375, px: 3 }}>
                    <Typography variant="subtitle1" component="div">
                        {translate(translationKeys.VDLANG_ADMIN_SIDEBAR_DATA_CURRENCIES)}
                    </Typography>
                    <Grid item>
                        <CalculationTimerangeSelect
                            dense
                            granularity={MeasureCalculationGranularity.FISCAL_YEAR}
                            fiscalYearStart={fiscalYearStart}
                            start={start}
                            end={end}
                            maxStart={momentFromYear(earliestExchangeRate, fiscalYearStart)}
                            minEnd={momentFromYear(latestExchangeRate, fiscalYearStart)}
                            translate={translate}
                            onStartChanged={setCustomStart}
                            onEndChanged={setCustomEnd}
                        />
                    </Grid>
                </Grid>
                <Divider />
                {defaultCurrency != null && (
                    <Typography component="div" variant="body2" align="center" sx={{ p: 1 }}>
                        {translate(translationKeys.VDLANG_ADMIN_CURRENCIES_DEFAULT_CURRENCY_HINT, {
                            currencyCode: defaultCurrency?.isoCode,
                        })}
                        <CurrencyInfoIcon
                            fontSize="inherit"
                            color="action"
                            title={translate(translationKeys.VDLANG_ADMIN_CURRENCIES_DEFAULT_CURRENCY_TOOLTIP)}
                        />
                    </Typography>
                )}
                <Divider />
                <CurrencySettingsTable
                    currencies={currenciesQuery.data}
                    translate={translate}
                    startYear={start.year()}
                    endYear={end.year()}
                    removeCurrency={setCurrencyToDelete}
                    fiscalYearStart={fiscalYearStart}
                />
            </Container>
        </Root>
    );
};

export default CurrencySettings;
