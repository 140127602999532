import { Checkbox, Divider, FormControlLabel, FormGroup, Popover, Stack, formControlClasses, styled } from "@mui/material";
import {
    AdminMeasureConfigListDto,
    AdminMeasureListDto,
    AdminUserListDto,
    DashboardListDto,
    GroupListDto,
    AclNamespaces,
    AclPermissions,
    AclSubjectType,
} from "api-shared";
import { forIn } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import type { MultiValue } from "react-select";
import Select from "../../../components/input/select/Select";
import SelectButton from "../../../components/input/select/SelectButton";
import SelectStaticMenuContainer from "../../../components/input/select/components/SelectStaticMenu";
import { useAdminDirectPermissions } from "../../../domain/admin/permissions";
import useMenu from "../../../hooks/useMenu";
import { translationKeys } from "../../../translations/main-translations";
import { AclTable } from "./AclTable";
import { UserOrGroupOption } from "./PermissionsConstants";

const FiltersPopoverContainer = styled("div")(({ theme }) => ({
    width: theme.spacing(30),
    [`& .${formControlClasses.root}`]: {
        padding: theme.spacing(),
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
}));

const FixWidthSelectButton = styled(SelectButton)(({ theme }) => ({
    width: theme.spacing(28),
}));

type NamespaceOption = { label: string; value: AclNamespaces };
type PermissionOption = { label: string; value: AclPermissions };

type ManageAccessProps = {
    userOrGroupOptions: UserOrGroupOption[];
    filter: string;
    isLoading: boolean;
    measureConfigs: AdminMeasureConfigListDto;
    dashboards: DashboardListDto;
    measures: AdminMeasureListDto;
    users: AdminUserListDto;
    groups: GroupListDto;
};

const ManageAccess = ({
    userOrGroupOptions,
    filter,
    isLoading,
    measureConfigs,
    dashboards,
    measures,
    users,
    groups,
}: ManageAccessProps) => {
    const { t } = useTranslation();

    const [showAllPermissions, setShowAllPermissions] = useState(false);
    const [selectedNamespaces, setSelectedNamespaces] = useState<NamespaceOption[]>([]);
    const [selectedPermissions, setSelectedPermissions] = useState<PermissionOption[]>([]);
    const [selectedUserAndGroupFilter, setSelectedUserAndGroupFilter] = useState<UserOrGroupOption[]>([]);

    const groupFilterIds = selectedUserAndGroupFilter.filter((o) => o.type === AclSubjectType.Group).map((g) => g.entityId);
    const userFilterIds = selectedUserAndGroupFilter.filter((o) => o.type === AclSubjectType.User).map((u) => u.entityId);
    const directPermissionsQuery = useAdminDirectPermissions(
        showAllPermissions,
        selectedNamespaces.map((ns) => ns.value),
        selectedPermissions.map((p) => p.value),
        groupFilterIds,
        userFilterIds,
    );

    const menuNamespaces = useMenu();
    const menuPermissions = useMenu();
    const menuUsersOrGroups = useMenu();

    const handleShowAllPermissions = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShowAllPermissions(event.target.checked);
    };

    const handleNamespaceChange = (newSelectedOptions: MultiValue<NamespaceOption>) => {
        setSelectedNamespaces(newSelectedOptions as NamespaceOption[]);
    };

    const handlePermissionChange = (newSelectedOptions: MultiValue<PermissionOption>) => {
        setSelectedPermissions(newSelectedOptions as PermissionOption[]);
    };

    const handleUserOrGroupFilterChange = (newSelectedOptions: MultiValue<UserOrGroupOption>) => {
        setSelectedUserAndGroupFilter(newSelectedOptions as UserOrGroupOption[]);
    };

    let namespaceOptions: NamespaceOption[] = [];
    forIn(AclNamespaces, (value, key) => {
        if (isNaN(Number(key)) && value !== AclNamespaces.SuperAdmin) {
            namespaceOptions.push({ value, label: t(`${translationKeys.VDLANG_ACL_NAMESPACES}.${value}`) });
        }
    });
    namespaceOptions = namespaceOptions.toSorted((a, b) => a.label.localeCompare(b.label));

    let permissionOptions: PermissionOption[] = [];
    forIn(AclPermissions, (value, key) => {
        if (isNaN(Number(key))) {
            permissionOptions.push({ value, label: t(`${translationKeys.VDLANG_ACL_PERMISSIONS}.${value}`) });
        }
    });
    permissionOptions = permissionOptions.toSorted((a, b) => a.label.localeCompare(b.label));

    return (
        <Stack sx={{ height: "100%" }}>
            <Stack sx={{ px: 3, py: 1.5 }} display="flex" direction="row" justifyContent="space-between">
                <Stack direction="row" spacing={1}>
                    <FormGroup>
                        <Popover {...menuUsersOrGroups.menuProps}>
                            <FiltersPopoverContainer>
                                <Select
                                    isMulti
                                    options={userOrGroupOptions}
                                    value={selectedUserAndGroupFilter}
                                    onChange={(newSelectedOptions) => handleUserOrGroupFilterChange(newSelectedOptions)}
                                    menuIsOpen
                                    isClearable
                                    isSearchable
                                    placeholder={`${t("Search")}...`}
                                    margin="none"
                                    components={{
                                        Menu: SelectStaticMenuContainer,
                                        DropdownIndicator: undefined,
                                    }}
                                />
                            </FiltersPopoverContainer>
                        </Popover>
                        <FixWidthSelectButton
                            label={t(translationKeys.VDLANG_ACL_USER_GROUP)}
                            count={selectedUserAndGroupFilter.length}
                            size="medium"
                            fullWidth={false}
                            onClick={menuUsersOrGroups.openMenu}
                            aria-expanded={menuUsersOrGroups.menuProps.open ? "true" : "false"}
                            aria-haspopup="true"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Popover {...menuNamespaces.menuProps}>
                            <FiltersPopoverContainer>
                                <Select
                                    isMulti
                                    options={namespaceOptions}
                                    value={selectedNamespaces}
                                    onChange={(newSelectedOptions) => handleNamespaceChange(newSelectedOptions)}
                                    menuIsOpen
                                    isClearable
                                    isSearchable
                                    placeholder={`${t("Search")}...`}
                                    margin="none"
                                    components={{
                                        Menu: SelectStaticMenuContainer,
                                        DropdownIndicator: undefined,
                                    }}
                                />
                            </FiltersPopoverContainer>
                        </Popover>
                        <FixWidthSelectButton
                            label={t(translationKeys.VDLANG_ACL_NAMESPACE)}
                            count={selectedNamespaces.length}
                            size="medium"
                            onClick={menuNamespaces.openMenu}
                            aria-expanded={menuNamespaces.menuProps.open ? "true" : "false"}
                            aria-haspopup="true"
                            fullWidth={false}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Popover {...menuPermissions.menuProps}>
                            <FiltersPopoverContainer>
                                <Select
                                    isMulti
                                    options={permissionOptions}
                                    value={selectedPermissions}
                                    onChange={(newSelectedOptions) => handlePermissionChange(newSelectedOptions)}
                                    menuIsOpen
                                    isClearable
                                    isSearchable
                                    placeholder={`${t("Search")}...`}
                                    margin="none"
                                    components={{
                                        Menu: SelectStaticMenuContainer,
                                        DropdownIndicator: undefined,
                                    }}
                                />
                            </FiltersPopoverContainer>
                        </Popover>
                        <FixWidthSelectButton
                            label={t(translationKeys.VDLANG_ACL_PERMISSION)}
                            count={selectedPermissions.length}
                            size="medium"
                            onClick={menuPermissions.openMenu}
                            aria-expanded={menuPermissions.menuProps.open ? "true" : "false"}
                            aria-haspopup="true"
                            fullWidth={false}
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox size="small" defaultChecked={showAllPermissions} onChange={handleShowAllPermissions} />}
                            label={t(translationKeys.VDLANG_ACL_SHOW_ALL_PERMISSIONS)}
                        />
                    </FormGroup>
                </Stack>
            </Stack>
            <Divider />
            {directPermissionsQuery.isSuccess ? (
                <AclTable
                    key={`acl-table-manage-access-${showAllPermissions}-${selectedNamespaces.length}-${selectedPermissions.length}-${selectedUserAndGroupFilter.length}`}
                    acls={directPermissionsQuery.data}
                    isFetching={directPermissionsQuery.isLoading || isLoading}
                    measureConfigs={measureConfigs}
                    dashboards={dashboards}
                    measures={measures}
                    users={users}
                    groups={groups}
                    globalFilter={filter}
                />
            ) : null}
        </Stack>
    );
};

export default ManageAccess;
