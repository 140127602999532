import { Grid } from "@mui/material";
import { capitalize, CompanyCreateDto, CompanyUpdateDto, zCompanyUpdateDto } from "api-shared";
import { TFunction } from "i18next";
import { useState } from "react";
import Form from "../../../components/Form";
import ValidatedInput from "../../../components/input/ValidatedInput";

const editableAttributes = [
    {
        key: "name",
        mandatory: true,
    },
    {
        key: "street",
        mandatory: false,
    },
    {
        key: "zip",
        mandatory: false,
    },
    {
        key: "city",
        mandatory: false,
    },
    {
        key: "country",
        mandatory: false,
    },
];

interface ICompanyFormProps {
    translate: TFunction;
    filledCallback: (isFilled: boolean, company?: CompanyUpdateDto | CompanyCreateDto) => void;
    onSubmit: () => void;
    company?: CompanyUpdateDto;
}

interface CompanyFields {
    name: string;
    country: string;
    city: string;
    zip: string;
    street: string;
}

const isFilled = (company: CompanyUpdateDto) => zCompanyUpdateDto.safeParse(company).success;

const CompanyForm = ({ translate, filledCallback, onSubmit, company }: ICompanyFormProps) => {
    const [currentCompany, setCurrentCompany] = useState<CompanyUpdateDto>({
        name: company?.name,
        country: company?.country,
        city: company?.city,
        zip: company?.zip,
        street: company?.street,
    });

    const handleCompanyValueChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        const updatedCompany = { ...currentCompany, [name]: value };
        filledCallback(isFilled(updatedCompany), updatedCompany);
        setCurrentCompany(updatedCompany);
    };

    const submit = () => {
        if (isFilled(currentCompany)) {
            onSubmit();
        }
    };

    return (
        <Form onSubmit={submit}>
            <Grid item xs={12} container spacing={1}>
                {editableAttributes.map(({ key, mandatory }) => (
                    <Grid item key={key} xs={12}>
                        <ValidatedInput
                            name={key}
                            mandatory={mandatory}
                            label={capitalize(translate(key))}
                            type="text"
                            value={currentCompany[key as keyof CompanyFields] ?? ""}
                            onChange={handleCompanyValueChange}
                            fullWidth
                            margin="dense"
                            maxLength={255}
                        />
                    </Grid>
                ))}
            </Grid>
        </Form>
    );
};

export default CompanyForm;
