import {
    Button,
    Divider,
    ListItemText,
    MenuItem,
    MenuList,
    Paper,
    Popover,
    Stack,
    Typography,
    buttonBaseClasses,
    formControlClasses,
    styled,
} from "@mui/material";
import { AclSubjectType, UserStatus, formatUserName } from "api-shared";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import SearchInput from "../../../components/input/SearchInput";
import { Option } from "../../../components/input/select/types";
import { useAdminDashboards } from "../../../domain/admin/dashboards";
import { useGroups } from "../../../domain/admin/groups";
import { useAdminMeasureConfigs, useAdminMeasures } from "../../../domain/admin/measures";
import { useAdminUsers } from "../../../domain/admin/users";
import { useLanguage } from "../../../hooks/useLanguage";
import useMenu from "../../../hooks/useMenu";
import { fullGroupPath } from "../../../lib/groups";
import { translationKeys } from "../../../translations/main-translations";
import { ArrowDropDownIcon } from "../../ideas/IdeaModalSingleUserButton";
import AddRulesHandling from "./AddRulesHandling";
import EffectivePermissionsViewer from "./EffectivePermissionsViewer";
import ManageAccess from "./ManageAccess";
import { useDebounce } from "../../../hooks/useDebounce";

const Root = styled(Stack)(() => ({
    height: "100%",
}));

const SelectModePopoverContainer = styled("div")(({ theme }) => ({
    width: theme.spacing(40),
    [`& .${formControlClasses.root}`]: {
        padding: theme.spacing(),
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
}));

const SelectFieldButton = styled(Button)({
    padding: 0,
    [`&.${buttonBaseClasses.root}:hover`]: {
        backgroundColor: "transparent",
    },
});

enum SettingsMode {
    ManageAccess = "manageAccess",
    ViewEffectivePermissions = "viewEffectivePermissions",
}

const PermissionSettings = () => {
    const { t } = useTranslation();
    const language = useLanguage();

    const [selectedMode, setSelectedMode] = useState(SettingsMode.ManageAccess);

    const [filter, setFilter] = useState("");
    const tableFilterValue = useDebounce(filter);

    const measureConfigs = useAdminMeasureConfigs();
    const dashboards = useAdminDashboards();
    const measures = useAdminMeasures();
    const users = useAdminUsers();
    const groups = useGroups();
    const tablesMenu = useMenu();

    const handleModeChange = (table: SettingsMode) => {
        setSelectedMode(table);
        tablesMenu.close();
    };

    const settingsModeOptions: Option<string>[] = [
        { label: t(translationKeys.VDLANG_ACL_MANAGE_ACCESS), value: SettingsMode.ManageAccess },
        { label: t(translationKeys.VDLANG_ACL_VIEW_EFFECTIVE_PERMISSIONS), value: SettingsMode.ViewEffectivePermissions },
    ];

    const userOptions =
        users.data
            ?.filter((u) => u.status !== UserStatus.STATUS_DELETED && u.status !== UserStatus.STATUS_INACTIVE)
            .map((user) => ({
                value: `${AclSubjectType.User}-${user.id}`,
                entityId: user.id,
                label: formatUserName(user),
                type: AclSubjectType.User,
            })) ?? [];
    const sortedUserOptions = userOptions.toSorted((a, b) => a.label.localeCompare(b.label));
    const groupOptions =
        groups.data?.map((group) => ({
            value: `${AclSubjectType.Group}-${group.id}`,
            entityId: group.id,
            label: fullGroupPath(groups.data, group.id, language),
            type: AclSubjectType.Group,
        })) ?? [];
    const sortedGroupOptions = groupOptions.toSorted((a, b) => a.label.localeCompare(b.label));
    const userOrGroupOptions = [...sortedUserOptions, ...sortedGroupOptions];

    return (
        <Root spacing={2}>
            <Paper sx={{ height: "100%" }}>
                <Stack sx={{ height: "100%" }}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ px: 3, py: 2 }}>
                        <Popover
                            {...tablesMenu.menuProps}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                        >
                            <SelectModePopoverContainer>
                                <MenuList dense>
                                    {settingsModeOptions.map((option) => (
                                        <MenuItem
                                            key={option.value}
                                            value={option.value}
                                            onClick={() => handleModeChange(option.value as SettingsMode)}
                                            selected={selectedMode === option.value}
                                        >
                                            <ListItemText primary={option.label} primaryTypographyProps={{ noWrap: true }} />
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </SelectModePopoverContainer>
                        </Popover>
                        <SelectFieldButton variant="text" color="inherit" onClick={tablesMenu.open} disableRipple>
                            <Typography variant="subtitle1">
                                {settingsModeOptions.find((option) => option.value === selectedMode)?.label}
                            </Typography>
                            <ArrowDropDownIcon />
                        </SelectFieldButton>
                        <Stack direction="row" spacing={1}>
                            <SearchInput translate={t} searchKey={filter} onChange={setFilter} fullWidth={false} />
                            <AddRulesHandling
                                users={users.data ?? []}
                                groups={groups.data ?? []}
                                dashboards={dashboards.data ?? []}
                                measures={measures.data ?? []}
                                measureConfigs={measureConfigs.data ?? []}
                            />
                        </Stack>
                    </Stack>
                    <Divider />
                    {selectedMode === SettingsMode.ManageAccess ? (
                        <ManageAccess
                            userOrGroupOptions={userOrGroupOptions}
                            filter={tableFilterValue}
                            isLoading={users.isLoading || groups.isLoading || measureConfigs.isLoading || dashboards.isLoading}
                            measureConfigs={measureConfigs.data ?? []}
                            dashboards={dashboards.data ?? []}
                            measures={measures.data ?? []}
                            users={users.data ?? []}
                            groups={groups.data ?? []}
                        />
                    ) : (
                        <EffectivePermissionsViewer
                            userOrGroupOptions={userOrGroupOptions}
                            filter={tableFilterValue}
                            isLoading={users.isLoading || groups.isLoading || measureConfigs.isLoading || dashboards.isLoading}
                            measureConfigs={measureConfigs.data ?? []}
                            dashboards={dashboards.data ?? []}
                            measures={measures.data ?? []}
                            users={users.data ?? []}
                            groups={groups.data ?? []}
                        />
                    )}
                </Stack>
            </Paper>
        </Root>
    );
};

export default PermissionSettings;
