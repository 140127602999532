import { TextField, styled, type TextFieldProps } from "@mui/material";
import { zHexColor } from "api-shared";
import { useTranslation } from "react-i18next";
import { translationKeys } from "../../translations/main-translations";

interface ColorInputProps extends Omit<TextFieldProps, "value" | "onChange"> {
    value: string;
    onChange?: (value: string) => void;
}

const ColorField = styled(TextField)(({ theme }) => ({
    "& input": {
        padding: theme.spacing(),
    },
    '& input[type="color" i]::-webkit-color-swatch-wrapper': {
        // Chrome/WebKit add some small padding around the color swatch
        padding: 0,
    },
    // for some reason, combining the rules below does not work
    '& input[type="color" i]::-webkit-color-swatch': {
        border: "none",
        borderRadius: theme.shape.borderRadius,
    },
    '& input[type="color" i]::-moz-color-swatch': {
        border: "none",
        borderRadius: theme.shape.borderRadius,
    },
}));

function ColorInput({ value, onChange, ...textFieldProps }: Readonly<ColorInputProps>) {
    const { t: translate } = useTranslation();

    const isValidColor = zHexColor.safeParse(value).success;

    return (
        <ColorField
            type="color"
            value={value}
            error={!isValidColor}
            helperText={!isValidColor ? translate(translationKeys.VDLANG_COLOR_INPUT_BAD_VALUE) : undefined}
            onChange={(e) => onChange?.(e.target.value)}
            {...textFieldProps}
        />
    );
}

export default ColorInput;
