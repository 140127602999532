import {
    ChangeSet,
    Column,
    EditingState,
    IntegratedPaging,
    IntegratedSorting,
    PagingState,
    Sorting,
    SortingState,
} from "@devexpress/dx-react-grid";
import {
    Grid as DxGrid,
    Grid,
    PagingPanel,
    Table,
    TableEditColumn,
    TableEditRow,
    TableHeaderRow,
} from "@devexpress/dx-react-grid-material-ui";
import { DepartmentDto } from "api-shared";
import { TFunction } from "i18next";
import { isEmpty } from "lodash";

import React, { ReactElement, useCallback, useMemo, useState } from "react";
import DataGridCell from "../../../components/datagrid/DataGridCell";
import DataGridCommandCell, { IDataGridCommandCellProps } from "../../../components/datagrid/DataGridCommandCell";
import DataGridHeaderCell from "../../../components/datagrid/DataGridHeaderCell";

import { styled } from "@mui/material";
import DataGridNoDataComponent from "../../../components/datagrid/DataGridNoDataComponent";
import CustomPagingPanelContainer from "../../../components/datagrid/DataGridPaginationPane";
import Tooltip from "../../../components/Tooltip";
import { useAdminAddDepartment, useAdminUpdateDepartment } from "../../../domain/admin/departments";
import { generateId } from "../../../lib/ids";
import { translationKeys } from "../../../translations/main-translations";

interface IDepartmentsTableProps {
    translate: TFunction;
    departments: Omit<DepartmentDto, "createdAt">[];
    removeDepartment: (id: number) => void;
}

interface DepartmentRow extends Omit<DepartmentDto, "createdAt"> {
    createdAt: string;
}
enum DepartmentColumns {
    NameEn = "nameEn",
    NameDe = "nameDe",
    CreatedAt = "createdAt",
}

const MAX_LENGTH_DEPARTMENT_NAME = 1024;

const validateDepartmentRow = ({ nameEn, nameDe }: DepartmentRow): boolean =>
    [nameEn, nameDe].every((x) => x !== undefined && x.trim().length > 0 && x.trim().length <= MAX_LENGTH_DEPARTMENT_NAME);

function createEmptyRow(): DepartmentRow {
    return {
        id: generateId(),
        nameDe: "",
        nameEn: "",
        createdAt: "",
        isUsed: false,
    };
}

export function isDividerColumn({ name }: Column): boolean {
    return name === DepartmentColumns.NameEn;
}

const injectDisabledState = (
    child: ReactElement<IDataGridCommandCellProps>,
    row: DepartmentRow,
    invalidRows: number[],
    translate: TFunction,
) => {
    let isDisabled = false;
    let tooltip = "";
    if (child?.props.id === "commit") {
        isDisabled = !validateDepartmentRow(row) || invalidRows.includes(row.id);
    } else if (child?.props.id === "delete") {
        isDisabled = Boolean(row.isUsed);
        tooltip = translate(translationKeys.VDLANG_DEPARTMENT_TABLE_CANNOT_DELETE_IN_USE);
    }
    if (isDisabled) {
        return (
            <Tooltip title={tooltip}>
                <span>{React.cloneElement(child, { disabled: true })}</span>
            </Tooltip>
        );
    }
    return child;
};

const defaultSorting: Sorting[] = [{ columnName: DepartmentColumns.NameEn, direction: "asc" }];

// Use locale specific sorting and make sure small case and CAPS are considered equal
const enCollator = new Intl.Collator("en", { sensitivity: "base" });
const deCollator = new Intl.Collator("de", { sensitivity: "base" });
const sortingColumnExtensions: IntegratedSorting.ColumnExtension[] = [
    { columnName: DepartmentColumns.NameEn, compare: (a, b) => enCollator.compare(a, b) },
    { columnName: DepartmentColumns.NameDe, compare: (a, b) => deCollator.compare(a, b) },
];

const RootComponent = styled(Grid.Root)(({ theme }) => ({
    flex: 1,
    minHeight: 0,
}));

const DepartmentsTableCell = styled(DataGridCell)(({ theme, column }) => ({
    borderLeft: isDividerColumn(column) ? `2px solid ${theme.palette.divider}` : undefined,
}));

const DepartmentsTableHeaderCell = styled((props: TableHeaderRow.CellProps) => <DataGridHeaderCell {...props} />)(({ theme, column }) => ({
    ...(isDividerColumn(column) && {
        borderLeft: `2px solid ${theme.palette.divider}`,
    }),
}));

const DepartmentsTable = ({ departments, translate, removeDepartment }: IDepartmentsTableProps) => {
    const [invalidRows, setInvalidRows] = useState<number[]>([]);
    const [addedRows, setAddedRows] = useState<unknown[]>([]);

    const createDepartmentMutation = useAdminAddDepartment();
    const updateDepartmentMutation = useAdminUpdateDepartment();

    const defaultColumns: Column[] = [
        { name: "nameEn", title: translate(translationKeys.VDLANG_DEPARTMENT_TABLE_COLUMN_NAME_EN) },
        { name: "nameDe", title: translate(translationKeys.VDLANG_DEPARTMENT_TABLE_COLUMN_NAME_DE) },
        { name: "createdAt", title: translate(translationKeys.VDLANG_CREATED_AT) },
    ];

    const updateRowStatus = (rowId: number, isValid: boolean) => {
        if (isValid) {
            invalidRows.includes(rowId) && setInvalidRows((ids) => ids.filter((id) => id !== rowId));
        } else {
            !invalidRows.includes(rowId) && setInvalidRows((ids) => [...ids, rowId]);
        }
    };

    const createStringColumnRowChange = (row: DepartmentRow, value: string, columnName: string) => {
        const sanitizedValue = value.normalize(); // normalize combined unicode chars into single ones if possible
        const changeset = { [columnName]: sanitizedValue };
        const isNewRowValid = validateDepartmentRow({ ...row, ...changeset });
        updateRowStatus(row.id, isNewRowValid);
        const length = [...sanitizedValue].length; // workaround for better estimation of string length with unicode chars
        if (length <= MAX_LENGTH_DEPARTMENT_NAME) {
            return changeset;
        }
    };

    const updateAddedRows = (newAddedRows: DepartmentRow[]) => {
        const createdRows: DepartmentRow[] = [];
        // initialize empty rows with proper default values
        const updatedAddedRows = newAddedRows.map((row) => {
            if (!isEmpty(row)) {
                return row;
            }
            const newRow = createEmptyRow();
            createdRows.push(newRow);
            return newRow;
        });
        createdRows.forEach((createdRow) => {
            updateRowStatus(createdRow.id, validateDepartmentRow(createdRow));
        });
        setAddedRows(updatedAddedRows);
    };

    const stringEditingColumnExtensions: EditingState.ColumnExtension[] = [
        { columnName: DepartmentColumns.NameDe, createRowChange: createStringColumnRowChange },
        { columnName: DepartmentColumns.NameEn, createRowChange: createStringColumnRowChange },
        { columnName: DepartmentColumns.CreatedAt, editingEnabled: false },
    ];

    const onSave = useCallback(
        ({ added, deleted, changed }: ChangeSet) => {
            if (deleted !== undefined) {
                deleted.forEach((id) => removeDepartment(+id));
            }
            if (added !== undefined) {
                added.forEach(({ nameEn, nameDe }: DepartmentRow) => {
                    createDepartmentMutation.mutate({
                        nameEn,
                        nameDe,
                    });
                });
            }
            if (changed !== undefined) {
                Object.entries(changed).forEach(([key, changes]) => {
                    updateDepartmentMutation.mutate({ id: +key, ...changes });
                });
            }
        },
        [createDepartmentMutation, removeDepartment, updateDepartmentMutation],
    );

    const TableEditColumnCell = ({ children, ...props }: TableEditColumn.CellProps) => {
        const row = props.row as DepartmentRow;
        return (
            <TableEditColumn.Cell {...props}>
                {React.Children.map(children as ReactElement<IDataGridCommandCellProps>[], (child) =>
                    injectDisabledState(child, row, invalidRows, translate),
                )}
            </TableEditColumn.Cell>
        );
    };

    const messages = useMemo(
        () => ({
            addCommand: translate("Add"),
            editCommand: translate("Edit"),
            commitCommand: translate(translationKeys.VDLANG_SAVE),
            cancelCommand: translate("Cancel"),
            deleteCommand: translate("delete"),
        }),
        [translate],
    );

    return (
        <DxGrid rootComponent={RootComponent} rows={departments} columns={defaultColumns} getRowId={(r) => r.id}>
            <EditingState
                onCommitChanges={onSave}
                columnExtensions={[...stringEditingColumnExtensions]}
                addedRows={addedRows}
                onAddedRowsChange={updateAddedRows}
            />
            <PagingState defaultCurrentPage={0} defaultPageSize={25} />
            <SortingState defaultSorting={defaultSorting} />
            <IntegratedSorting columnExtensions={sortingColumnExtensions} />
            <IntegratedPaging />
            <Table
                noDataCellComponent={DataGridNoDataComponent}
                messages={{ noData: translate(translationKeys.VDLANG_DEPARTMENT_TABLE_NO_DATA) }}
                cellComponent={DepartmentsTableCell}
            />
            <TableHeaderRow showSortingControls cellComponent={DepartmentsTableHeaderCell} />
            <TableEditRow />
            <TableEditColumn
                showAddCommand
                showEditCommand
                showDeleteCommand
                commandComponent={DataGridCommandCell}
                cellComponent={TableEditColumnCell}
                messages={messages}
            />
            <PagingPanel
                containerComponent={CustomPagingPanelContainer}
                pageSizes={[10, 25, 50]}
                messages={{ rowsPerPage: `${translate(translationKeys.VDLANG_PAGING_ROWS_PER_PAGE)}:` }}
            />
        </DxGrid>
    );
};

export default React.memo(DepartmentsTable);
