import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
    AdminSupplierDto,
    AdminSupplierListDto,
    CreateSupplierAdminDto,
    UpdateSupplierAdminDto,
    UpdateSupplierAdminParamDto,
} from "api-shared";
import { useDispatch } from "react-redux";
import { showNotificationEvent } from "../../infrastructure/notifications";
import { apiGet, apiPatch, apiPost } from "../../lib/api";
import { NotificationType } from "../../lib/notifications";
import { FeedbackTranslationKeys } from "../../translations/notification-translations";

const SUPPLIERS_PATH = "admin/suppliers";

export const SupplierAdminQueryKeys = {
    all: [SUPPLIERS_PATH] as const,
};

export const useAdminSuppliersQuery = (enabled = true) => {
    return useQuery({
        queryKey: SupplierAdminQueryKeys.all,
        queryFn: ({ signal }) => apiGet<AdminSupplierListDto>(SUPPLIERS_PATH, { signal }),
        enabled,
    });
};
export const useCreateSupplierAdminMutation = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: (data: CreateSupplierAdminDto) => apiPost<AdminSupplierDto>(SUPPLIERS_PATH, data),
        onSuccess: () => {
            queryClient.invalidateQueries(SupplierAdminQueryKeys.all);
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_PARTNER_CREATED));
        },
    });
};

export const useUpdateSupplierAdminMutation = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: ({ id, ...data }: UpdateSupplierAdminDto & UpdateSupplierAdminParamDto) =>
            apiPatch<AdminSupplierDto>(`${SUPPLIERS_PATH}/${id}`, data),
        onSuccess: () => {
            queryClient.invalidateQueries(SupplierAdminQueryKeys.all);
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_PARTNER_UPDATED));
        },
    });
};
