import { TreeNodeDto } from "api-shared";

function recursiveTreeSearch(groups: TreeNodeDto[], id: number): TreeNodeDto | undefined {
    // check if id is on the current level
    const currentLevel = groups.find((treeNode) => treeNode.id === id);
    if (currentLevel !== undefined) {
        return currentLevel;
    }
    const flattendChildren = groups.map(({ children }) => children).flat();
    // return undefined if no children left
    if (flattendChildren.length === 0) {
        return undefined;
    }
    // We need to go deeper
    return recursiveTreeSearch(flattendChildren, id);
}

export default recursiveTreeSearch;
