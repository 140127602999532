import { zodResolver } from "@hookform/resolvers/zod";
import { Checkbox, Divider, FormControl, IconButton, Stack, styled, Typography } from "@mui/material";
import { GroupDto, nonNullable, UpdateGroupRequestBody, UserDto, zUpdateGroupRequestBody } from "api-shared";
import { useRef, useState } from "react";
import { Controller, ControllerRenderProps, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Form from "../../../components/Form";
import ActionItemDialog from "../../../components/dialogues/ActionItemDialog";
import SearchInput from "../../../components/input/SearchInput";
import SingleUserButton from "../../../components/user/SingleUserButton";

import DeleteRounded from "@mui/icons-material/DeleteRounded";
import { useVirtualizer } from "@tanstack/react-virtual";
import { TFunction } from "i18next";
import { useUpdateGroupMutation } from "../../../domain/admin/groups";
import { useDebounce } from "../../../hooks/useDebounce";
import { compareUsersByDisplayName } from "../../../lib/sort";
import { translationKeys } from "../../../translations/main-translations";

type UserDialogSelectListProps = {
    fieldProps: ControllerRenderProps<Pick<UpdateGroupRequestBody, "userIds">, "userIds">;
    users: UserDto[];
    translate: TFunction;
};

const UserDialogSelectListScrollingContainer = styled("div")({
    height: "800px",
    overflow: "auto",
});

const UserDialogSelectListContainer = styled("div", {
    shouldForwardProp: (prop) => prop !== "height",
})<{
    height: number;
}>(({ height }) => ({
    height: `${height}px`,
    width: "100%",
    position: "relative",
}));

const UserDialogSelectListItem = styled("div", {
    shouldForwardProp: (prop) => prop !== "height" && prop !== "start",
})<{ height: number; start: number }>(({ height, start }) => ({
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: `${height}px`,
    transform: `translateY(${start}px)`,
}));

const UserDialogSelectList = ({ fieldProps, users, translate }: UserDialogSelectListProps) => {
    const scrollElementRef = useRef<HTMLDivElement | null>(null);

    const rowVirtualizer = useVirtualizer({
        count: users.length,
        getScrollElement: () => scrollElementRef.current,
        estimateSize: () => 50,
    });

    const totalSize = rowVirtualizer.getTotalSize();

    return (
        <FormControl>
            <UserDialogSelectListScrollingContainer ref={scrollElementRef}>
                <UserDialogSelectListContainer height={totalSize}>
                    {rowVirtualizer.getVirtualItems().map((virtualItem) => (
                        <UserDialogSelectListItem key={virtualItem.key} height={virtualItem.size} start={virtualItem.start}>
                            <Stack direction="row" justifyContent="space-between" alignItems="space-between" py={0.5}>
                                <SingleUserButton user={users[virtualItem.index]} translate={translate} />
                                <Checkbox
                                    size="small"
                                    checked={fieldProps.value.includes(users[virtualItem.index].id)}
                                    onChange={() => {
                                        if (!fieldProps.value.includes(users[virtualItem.index].id)) {
                                            fieldProps.onChange([...fieldProps.value, users[virtualItem.index].id]);
                                            return;
                                        }
                                        const updatedList = fieldProps.value.filter((u) => u !== users[virtualItem.index].id);
                                        fieldProps.onChange(updatedList);
                                    }}
                                    inputRef={fieldProps.ref}
                                />
                            </Stack>
                        </UserDialogSelectListItem>
                    ))}
                </UserDialogSelectListContainer>
            </UserDialogSelectListScrollingContainer>
        </FormControl>
    );
};

type UserDialogProps = {
    open: boolean;
    currentUserIds: number[];
    allUsers: UserDto[];
    onClose: () => void;
    currentGroup: GroupDto;
};

export const UserDialog = ({ open, currentUserIds, onClose, allUsers, currentGroup }: UserDialogProps) => {
    const { t } = useTranslation();

    const [searchKey, setSearchKey] = useState("");

    const debouncedSearchKey = useDebounce(searchKey);

    const users = allUsers
        .filter((user) => user.displayname?.toLowerCase().includes(debouncedSearchKey.toLowerCase()))
        .toSorted(compareUsersByDisplayName);

    const {
        handleSubmit,
        control,
        formState: { isValid, isDirty },
        setValue,
        watch,
        reset,
    } = useForm<Pick<UpdateGroupRequestBody, "userIds">>({
        mode: "onChange",
        resolver: zodResolver(zUpdateGroupRequestBody.pick({ userIds: true })),
        defaultValues: { userIds: currentUserIds },
    });

    const updateGroupsMutation = useUpdateGroupMutation();

    const onSubmit = handleSubmit((data) => {
        updateGroupsMutation.mutate({ groupId: currentGroup.id, ...currentGroup, userIds: data.userIds }, { onSuccess: handleClose });
    });

    function handleClose() {
        onClose();
        reset({ userIds: currentUserIds });
    }

    const getSelectedList = () => {
        const currentValues = watch("userIds");
        const filteredUserList = currentValues
            .map((item) => allUsers.find((u) => u.id === item) ?? null)
            .filter(nonNullable)
            .toSorted(compareUsersByDisplayName);
        return filteredUserList.map((user) => (
            <Stack key={user.id} direction="row" justifyContent="space-between" alignItems="space-between" py={0.5}>
                <SingleUserButton user={user} translate={t} />
                <IconButton
                    onClick={() =>
                        setValue(
                            "userIds",
                            currentValues.filter((val) => val !== user.id),
                            { shouldValidate: true, shouldDirty: true, shouldTouch: true },
                        )
                    }
                >
                    <DeleteRounded />
                </IconButton>
            </Stack>
        ));
    };

    return (
        <ActionItemDialog
            open={open}
            title={t(translationKeys.VDLANG_ADMIN_GROUPS_MANAGE_USERS_TITLE)}
            primary={t(translationKeys.VDLANG_SAVE)}
            primaryIsTranslated
            onPrimary={onSubmit}
            primaryDisabled={!isValid || !isDirty}
            onClose={handleClose}
            translate={t}
            disableContentPadding
            maxWidth="md"
        >
            <Stack direction="row" height="100%">
                <Stack px={3} py={2} width="100%">
                    <Typography fontWeight="medium" color="GrayText" variant="overline">
                        {t(translationKeys.VDLANG_ADMIN_GROUPS_ALL_USERS_TITLE)}
                    </Typography>
                    <Form onSubmit={onSubmit} style={{ margin: 0 }}>
                        <Stack gap={1}>
                            <SearchInput onChange={(input) => setSearchKey(input)} searchKey={searchKey} translate={t} />
                            <Controller
                                name="userIds"
                                control={control}
                                render={({ field }) => <UserDialogSelectList fieldProps={field} users={users} translate={t} />}
                            />
                        </Stack>
                    </Form>
                </Stack>
                <Divider orientation="vertical" flexItem />
                <Stack px={3} py={2} width="100%">
                    <Typography fontWeight="medium" color="GrayText" variant="overline">
                        {t(translationKeys.VDLANG_ADMIN_GROUPS_ASSIGNED_USERS)}
                    </Typography>
                    {getSelectedList()}
                </Stack>
            </Stack>
        </ActionItemDialog>
    );
};
