import { UserDto, formatUserName } from "api-shared";
import { TFunction } from "i18next";
import Select from "../../../components/input/select/Select";

type MeasuresAssigneeSelectProps = {
    value: number | null;
    onChange: (value: number | null) => void;
    translate: TFunction;
    users?: UserDto[];
};

export const MeasuresAssigneeSelect = ({ value, onChange, translate, users }: MeasuresAssigneeSelectProps) => {
    const userOptions = users?.map((user) => ({
        value: user.id,
        label: formatUserName(user),
    }));

    return (
        <Select
            margin="none"
            value={userOptions?.filter((option) => option.value === value)}
            options={userOptions}
            onChange={(o) => {
                if (o !== null) {
                    onChange(o.value);
                }
            }}
            menuPortalTarget={document.body}
            placeholder={`${translate("Search")}...`}
            isSearchable
        />
    );
};
