import { GroupDto } from "api-shared";
import { CellProps } from "react-table";
import TableLinkCallbackCell from "../../../components/table/TableLinkCallbackCell";

interface GroupTableNameCellProps {
    cell: CellProps<GroupDto>;
    onClick: (value: any) => void;
}

export const GroupTableNameCell = ({ cell, onClick }: GroupTableNameCellProps) => (
    <TableLinkCallbackCell
        {...cell}
        onClick={(index) => {
            onClick(cell.rows.find((row) => row.index === index)?.original.id ?? 0);
        }}
    >
        {cell.value}
    </TableLinkCallbackCell>
);
