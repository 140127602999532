import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AdminOpportunityListDto, AdminUpdateOpportunitiesRequestBody } from "api-shared";
import { useDispatch } from "react-redux";
import { showNotificationEvent } from "../../infrastructure/notifications";
import { apiGet, apiPatch } from "../../lib/api";
import { NotificationType } from "../../lib/notifications";
import { FeedbackTranslationKeys } from "../../translations/notification-translations";

const ADMIN_OPPS_PATH = "admin/opportunities";

export const useAdminOpportunities = () => {
    return useQuery({
        queryKey: [ADMIN_OPPS_PATH],
        queryFn: ({ signal }) => apiGet<AdminOpportunityListDto>(ADMIN_OPPS_PATH, { signal }),
    });
};

export const useAdminUpdateOpportunitiesMutation = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: ({ oppIds, action }: AdminUpdateOpportunitiesRequestBody) =>
            apiPatch<AdminOpportunityListDto>(ADMIN_OPPS_PATH, { oppIds, action }),
        onSuccess: () => {
            queryClient.invalidateQueries([ADMIN_OPPS_PATH]);
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_OPPS_UPDATED));
        },
    });
};
