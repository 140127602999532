import { zodResolver } from "@hookform/resolvers/zod";
import { Checkbox, FormControlLabel, FormGroup, Stack, TextField, Typography } from "@mui/material";
import { CreateGroupRequestBody, GroupDto, GroupType, zCreateGroupRequestBody } from "api-shared";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Alert from "../../../components/Alert";
import Form from "../../../components/Form";
import Tooltip from "../../../components/Tooltip";
import ActionItemDialog from "../../../components/dialogues/ActionItemDialog";
import { useCreateGroup, useUpdateGroupMutation } from "../../../domain/admin/groups";
import { translationKeys } from "../../../translations/main-translations";

const GROUP_DEFAULT_VALUES: CreateGroupRequestBody = {
    nameEn: "",
    descriptionEn: "",
    nameDe: "",
    descriptionDe: "",
    parentGroupId: null,
    isVisible: true,
};

type GroupDialogProps = {
    open: boolean;
    currentGroup?: GroupDto;
    onClose: () => void;
    parentGroupId: number | null;
};

export const GroupDialog = ({ open, currentGroup, onClose, parentGroupId }: GroupDialogProps) => {
    const { t } = useTranslation();

    const createGroupMutation = useCreateGroup();
    const updateGroupMutation = useUpdateGroupMutation();
    const isNewGroup = currentGroup === undefined;

    const {
        register,
        handleSubmit,
        reset,
        control,
        formState: { errors, isValid },
    } = useForm<CreateGroupRequestBody>({
        mode: "onChange",
        resolver: zodResolver(zCreateGroupRequestBody),
        defaultValues: currentGroup ?? GROUP_DEFAULT_VALUES,
    });

    const onSubmit = handleSubmit((data) => {
        if (!isNewGroup) {
            updateGroupMutation.mutate(
                {
                    groupId: currentGroup.id,
                    ...data,
                    userIds: currentGroup.userIds,
                },
                { onSuccess: handleClose },
            );
        } else {
            createGroupMutation.mutate({ ...data, parentGroupId }, { onSuccess: handleClose });
        }
    });

    function handleClose() {
        reset(currentGroup ?? GROUP_DEFAULT_VALUES);
        onClose();
    }

    const title = t(isNewGroup ? translationKeys.VDLANG_ADMIN_GROUPS_DIALOG_CREATE : translationKeys.VDLANG_ADMIN_GROUPS_DIALOG_UPDATE);
    const primaryButton = isNewGroup
        ? translationKeys.VDLANG_ADMIN_GROUPS_DIALOG_CREATE_BUTTON
        : translationKeys.VDLANG_ADMIN_GROUPS_DIALOG_UPDATE_BUTTON;

    const isManagedGroup =
        (currentGroup?.type === GroupType.MANAGED_EVERYONE || currentGroup?.type === GroupType.MANAGED_FULL_USER) && !isNewGroup;
    const isExtGroup = currentGroup?.type === GroupType.EXT_SCIM && !isNewGroup;
    const isSpecialGroup = currentGroup?.type !== GroupType.STANDARD && !isNewGroup;

    return (
        <ActionItemDialog
            open={open}
            title={title}
            primary={primaryButton}
            onPrimary={onSubmit}
            primaryDisabled={!isValid}
            onClose={handleClose}
            translate={t}
        >
            <Form onSubmit={onSubmit}>
                <Stack spacing={2}>
                    <Alert severity="info">{t(translationKeys.VDLANG_ADMIN_GROUPS_DIALOG_HINT)}</Alert>
                    {isManagedGroup && <Alert severity="warning">{t(translationKeys.VDLANG_ADMIN_GROUPS_MANAGED_EDIT_DISABLED)}</Alert>}
                    {isExtGroup && <Alert severity="warning">{t(translationKeys.VDLANG_ADMIN_GROUPS_EXT_EDIT_DISABLED)}</Alert>}
                    <TextField
                        autoFocus
                        autoComplete="off"
                        fullWidth
                        label={`${t(translationKeys.VDLANG_ADMIN_GROUPS_GROUP_NAME)} (EN)`}
                        error={errors.nameEn !== undefined}
                        helperText={
                            errors.nameEn &&
                            t(
                                errors.nameEn.type.includes("too_big")
                                    ? translationKeys.VDLANG_ADMIN_GROUPS_DIALOG_NAME_LONG
                                    : translationKeys.VDLANG_ADMIN_GROUPS_DIALOG_NAME_SHORT,
                            )
                        }
                        {...register("nameEn")}
                        disabled={isSpecialGroup}
                    />
                    <TextField
                        fullWidth
                        label={`${t(translationKeys.VDLANG_ADMIN_GROUPS_GROUP_DESCRIPTION)} (EN)`}
                        error={errors.descriptionEn !== undefined}
                        helperText={errors.descriptionEn && t(translationKeys.VDLANG_ADMIN_GROUPS_DIALOG_DESCRIPTION_ERROR)}
                        {...register("descriptionEn")}
                        disabled={isSpecialGroup}
                    />
                    <TextField
                        fullWidth
                        label={`${t(translationKeys.VDLANG_ADMIN_GROUPS_GROUP_NAME)} (DE)`}
                        error={errors.nameDe !== undefined}
                        helperText={
                            errors.nameDe &&
                            t(
                                errors.nameDe.type.includes("too_big")
                                    ? translationKeys.VDLANG_ADMIN_GROUPS_DIALOG_NAME_LONG
                                    : translationKeys.VDLANG_ADMIN_GROUPS_DIALOG_NAME_SHORT,
                            )
                        }
                        {...register("nameDe")}
                        disabled={isSpecialGroup}
                    />
                    <TextField
                        fullWidth
                        label={`${t(translationKeys.VDLANG_ADMIN_GROUPS_GROUP_DESCRIPTION)} (DE)`}
                        error={errors.descriptionDe !== undefined}
                        helperText={errors.descriptionDe && t(translationKeys.VDLANG_ADMIN_GROUPS_DIALOG_DESCRIPTION_ERROR)}
                        {...register("descriptionDe")}
                        disabled={isSpecialGroup}
                    />
                    <Controller
                        name="isVisible"
                        control={control}
                        render={({ field }) => {
                            const { value, onChange } = field;
                            return (
                                <FormGroup>
                                    <Typography variant="body2" color={(theme) => theme.palette.text.secondary}>
                                        {t(translationKeys.VDLANG_ADMIN_GROUPS_VISIBILITY)}
                                    </Typography>
                                    <Tooltip title={t(translationKeys.VDLANG_ADMIN_GROUPS_VISIBLE_HINT)}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    size="small"
                                                    checked={value}
                                                    onChange={(_, checked) => onChange(checked)}
                                                    color="primary"
                                                />
                                            }
                                            label={t(translationKeys.VDLANG_ADMIN_GROUPS_VISIBLE)}
                                        />
                                    </Tooltip>
                                </FormGroup>
                            );
                        }}
                    />
                </Stack>
            </Form>
        </ActionItemDialog>
    );
};
