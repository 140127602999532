import {
    ChangeSet,
    Column,
    DataTypeProvider,
    EditingState,
    IntegratedPaging,
    IntegratedSorting,
    PagingState,
    Sorting,
    SortingState,
} from "@devexpress/dx-react-grid";
import { Grid as DxGrid, PagingPanel, Table, TableEditColumn, TableEditRow, TableHeaderRow } from "@devexpress/dx-react-grid-material-ui";
import {
    CreateCurrencyDto,
    CurrencyDto,
    ExchangeRateDto,
    MeasureCalculationGranularity,
    UpdateCurrencyDto,
    UpdateExchangeRateDto,
    validateExchangeRateBoundaries,
} from "api-shared";
import classNames from "classnames";
import { TFunction } from "i18next";
import { isEmpty } from "lodash";
import moment from "moment";
import React, { ReactElement, useCallback, useMemo, useState } from "react";
import isFloat from "validator/lib/isFloat";
import DataGridCommandCell, { IDataGridCommandCellProps } from "../../../components/datagrid/DataGridCommandCell";
import DataGridNoDataComponent from "../../../components/datagrid/DataGridNoDataComponent";
import DataGridNumberFormatter from "../../../components/datagrid/DataGridNumberFormatter";
import CustomPagingPanelContainer from "../../../components/datagrid/DataGridPaginationPane";
import { useAdminAddCurrency, useAdminUpdateCurrency } from "../../../domain/admin/currencies";
import { calendarToFiscal, formatFiscalUnit } from "../../../lib/fiscal-units";
import { generateId } from "../../../lib/ids";
import { compareStringsByNumericValue } from "../../../lib/sort";
import { translationKeys } from "../../../translations/main-translations";
import CurrencyTableCell from "./CurrencyTableCell";
import CurrencyTableHeaderCell from "./CurrencyTableHeaderCell";

interface ICurrencyTableProps {
    translate: TFunction;
    currencies: CurrencyDto[];
    className?: string;
    removeCurrency: (id: number) => void;
    startYear: number;
    endYear: number;
    fiscalYearStart: number;
}

enum CurrencyColumns {
    Name = "name",
    IsoCode = "isoCode",
}

function toSafeNumber(input: string | number): number {
    return typeof input === "number" ? input : +input.replace(",", ".");
}

function formatNumber(input: number, maximumFractionDigits = 6): string {
    return input.toLocaleString("en", {
        maximumFractionDigits,
        useGrouping: false, // no thousand seperators
    });
}

export function isDividerColumn({ name }: Column): boolean {
    return name === CurrencyColumns.IsoCode;
}

function sanitizeRate({ fiscalYear, rate }: ModifiedExchangeRate): UpdateExchangeRateDto {
    return {
        fiscalYear,
        rate: typeof rate === "string" && rate.length === 0 ? null : formatNumber(toSafeNumber(rate)),
    };
}

function areRatesValid(rates: ModifiedExchangeRate[]): boolean {
    if (rates.every(({ rate }) => typeof rate === "string" && rate.length === 0)) {
        return false;
    }

    if (rates.some(({ rate }) => typeof rate === "string" && rate.length > 0 && toSafeNumber(rate) === 0)) {
        return false;
    }

    // validate that non-empty values meet the boundary requirements
    return rates.every(({ rate }) => rate === "" || validateRateBoundaries(rate));
}

function validateRateBoundaries(rate: string | number) {
    const strRate = typeof rate === "number" ? formatNumber(rate, 7) : rate.replace(",", ".");
    return validateExchangeRateBoundaries(strRate);
}

function validateCurrencyRow({ name, isoCode, exchangeRates }: CurrencyRow): boolean {
    const areStringColumnsValid = [name, isoCode].every((x) => x !== undefined && x.trim().length > 0);
    const areRateColumnsValid = areRatesValid(exchangeRates);
    return areStringColumnsValid && areRateColumnsValid;
}

const injectDisabledState = (child: ReactElement<IDataGridCommandCellProps>, row: CurrencyRow, invalidRows: number[]) => {
    let isDisabled = false;
    if (child?.props.id === "commit") {
        isDisabled = !validateCurrencyRow(row) || invalidRows.includes(row.id);
    } else if (child?.props.id === "delete") {
        isDisabled = Boolean(row.isUsed);
    }
    if (isDisabled) {
        return React.cloneElement(child, { disabled: true });
    }
    return child;
};

function createEmptyCurrencyRow(): CurrencyRow {
    return {
        id: generateId(),
        isoCode: "",
        name: "",
        // add a default exchange rate for the current fiscal year
        exchangeRates: [{ fiscalYear: moment().year(), rate: formatNumber(1) }],
    };
}

interface ModifiedExchangeRate extends Pick<ExchangeRateDto, "fiscalYear"> {
    rate: string | number;
}

interface CurrencyRow extends Pick<CurrencyDto, "id" | "name" | "isoCode" | "isUsed"> {
    exchangeRates: ModifiedExchangeRate[];
}

const defaultColumnExtensions = [
    { columnName: CurrencyColumns.Name, width: 160 },
    { columnName: CurrencyColumns.IsoCode, width: 100 },
];

const defaultSorting: Sorting[] = [{ columnName: CurrencyColumns.Name, direction: "asc" }];

function sanitizeCurrency({ exchangeRates, name, isoCode }: CurrencyRow): UpdateCurrencyDto {
    return {
        ...(name != null && { name: name.trim() }),
        ...(isoCode != null && { isoCode: isoCode.trim() }),
        ...(exchangeRates != null && { exchangeRates: exchangeRates.map(sanitizeRate) }),
    };
}

const CurrencyTable = ({
    className,
    currencies: currenciesWithDefault,
    translate,
    removeCurrency,
    startYear,
    endYear,
    fiscalYearStart,
}: ICurrencyTableProps) => {
    const numOfYears = endYear - startYear + 1;
    const years = Array.from({ length: numOfYears }, (_, i) => i + startYear);
    const [invalidRows, setInvalidRows] = useState<number[]>([]);
    const [addedRows, setAddedRows] = useState<unknown[]>([]);

    const createCurrencyMutation = useAdminAddCurrency();
    const updateCurrencyMutation = useAdminUpdateCurrency();

    // Hide row of the default currency, it is not editable any way and it's ExchangeRates are always 1
    const currencies = useMemo(() => currenciesWithDefault.filter(({ isDefault }) => !isDefault), [currenciesWithDefault]);

    // Basic Column setup
    const defaultColumns: Column[] = [
        { name: CurrencyColumns.Name, title: translate(translationKeys.VDLANG_CURRENCY_TABLE_COLUMN_NAME) },
        { name: CurrencyColumns.IsoCode, title: translate(translationKeys.VDLANG_CURRENCY_TABLE_COLUMN_CODE) },
    ];
    const yearColumns = [...years].sort().map((year) => ({
        name: String(year),
        title: formatFiscalUnit(
            calendarToFiscal(moment().year(year), fiscalYearStart, MeasureCalculationGranularity.FISCAL_YEAR),
            MeasureCalculationGranularity.FISCAL_YEAR,
            translate,
        ),
        getCellValue: (row: CurrencyDto) => {
            const rate = row.exchangeRates?.find((er) => er.fiscalYear === year)?.rate;
            return rate !== undefined ? formatNumber(rate) : undefined;
        },
    }));
    const columns = [...defaultColumns, ...yearColumns];

    const yearColumnExtensions = yearColumns.map(({ name }) => ({
        columnName: name,
        align: "right" as const,
    }));

    const updateRowStatus = (rowId: number, isValid: boolean) => {
        if (isValid) {
            invalidRows.includes(rowId) && setInvalidRows((ids) => ids.filter((id) => id !== rowId));
        } else {
            !invalidRows.includes(rowId) && setInvalidRows((ids) => [...ids, rowId]);
        }
    };

    const createStringColumnRowChange = (row: CurrencyRow, value: string, columnName: string) => {
        const sanitizedValue = value.normalize(); // normalize combined unicode chars into single ones if possible
        const maxLength = columnName === CurrencyColumns.IsoCode ? 8 : 255;
        const changeset = { [columnName]: sanitizedValue };
        const isNewRowValid = validateCurrencyRow({ ...row, ...changeset });
        updateRowStatus(row.id, isNewRowValid);
        const length = [...sanitizedValue].length; // workaround for better estimation of string length with unicode chars
        if (length <= maxLength) {
            return changeset;
        }
    };

    const createRateColumnRowChange = ({ id, exchangeRates }: CurrencyRow, value: string, columnName: string) => {
        const sanitizedValue = value.replace(",", "."); // normalize decimal seperator
        const isValid = sanitizedValue.length === 0 || sanitizedValue.endsWith(".") || isFloat(sanitizedValue);
        const isOutOfRange = sanitizedValue.length > 0 && !validateRateBoundaries(sanitizedValue);

        if (!isValid || isOutOfRange) {
            // ignore input leading to out of range values
            return {};
        }
        const updatedExchangeRate = { fiscalYear: +columnName, rate: value };
        const index = exchangeRates.findIndex((c) => c.fiscalYear === +columnName);
        const newRates = [...exchangeRates];

        if (index > -1) {
            newRates[index] = updatedExchangeRate;
        } else {
            newRates.push(updatedExchangeRate);
        }
        const areValid = areRatesValid(newRates);
        updateRowStatus(id, areValid);
        return {
            exchangeRates: newRates,
        };
    };

    const updateAddedRows = (newAddedRows: CurrencyRow[]) => {
        const createdRows: CurrencyRow[] = [];
        // initialize empty rows with proper default values
        const updatedAddedRows = newAddedRows.map((row) => {
            if (!isEmpty(row)) {
                return row;
            }
            const newRow = createEmptyCurrencyRow();
            createdRows.push(newRow);
            return newRow;
        });
        createdRows.forEach((createdRow) => {
            updateRowStatus(createdRow.id, validateCurrencyRow(createdRow));
        });
        setAddedRows(updatedAddedRows);
    };

    const stringEditingColumnExtensions: EditingState.ColumnExtension[] = [
        { columnName: CurrencyColumns.IsoCode, createRowChange: createStringColumnRowChange },
        { columnName: CurrencyColumns.Name, createRowChange: createStringColumnRowChange },
    ];

    const rateEditingColumnExtensions: EditingState.ColumnExtension[] = yearColumns.map(({ name }) => ({
        columnName: name,
        createRowChange: createRateColumnRowChange,
    }));

    const rateSortingColumnExtensions: IntegratedSorting.ColumnExtension[] = yearColumns.map(({ name }) => ({
        columnName: name,
        compare: compareStringsByNumericValue,
    }));

    const onSave = useCallback(
        ({ added, deleted, changed }: ChangeSet) => {
            if (deleted !== undefined) {
                deleted.forEach((id) => removeCurrency(+id));
            }
            if (added !== undefined) {
                added.forEach((row: CurrencyRow) => {
                    const payload = sanitizeCurrency(row) as CreateCurrencyDto;
                    // Remove ExchangeRates with value null, no need save them
                    const validExchangeRates = payload.exchangeRates.filter(({ rate }) => rate != null);
                    createCurrencyMutation.mutate({
                        ...payload,
                        exchangeRates: validExchangeRates,
                    });
                });
            }
            if (changed !== undefined) {
                Object.entries(changed)
                    .filter(([key, value]) => value !== undefined) // saving unchanged rows yields undefined changes here
                    .forEach(([key, changes]) => {
                        updateCurrencyMutation.mutate({ id: +key, ...sanitizeCurrency(changes) });
                    });
            }
        },
        [createCurrencyMutation, removeCurrency, updateCurrencyMutation],
    );

    // Forward className props to root component of table
    const RootComponent = useCallback(
        (props: DxGrid.RootProps & { className?: string }) => <DxGrid.Root {...props} className={classNames(className, props.className)} />,
        [className],
    );

    const TableEditColumnCell = ({ children, ...props }: TableEditColumn.CellProps) => {
        const row = props.row as CurrencyRow;
        return (
            <TableEditColumn.Cell {...props}>
                {React.Children.map(children as ReactElement<IDataGridCommandCellProps>[], (child) =>
                    injectDisabledState(child, row, invalidRows),
                )}
            </TableEditColumn.Cell>
        );
    };

    const messages = useMemo(
        () => ({
            addCommand: translate("Add"),
            editCommand: translate("Edit"),
            commitCommand: translate(translationKeys.VDLANG_SAVE),
            cancelCommand: translate("Cancel"),
            deleteCommand: translate("delete"),
        }),
        [translate],
    );

    return (
        <DxGrid rootComponent={RootComponent} rows={currencies} columns={columns} getRowId={(r) => r.id}>
            <DataTypeProvider for={yearColumns.map(({ name }) => name)} formatterComponent={DataGridNumberFormatter} />
            <EditingState
                onCommitChanges={onSave}
                columnExtensions={[...stringEditingColumnExtensions, ...rateEditingColumnExtensions]}
                addedRows={addedRows}
                onAddedRowsChange={updateAddedRows}
            />
            <PagingState defaultCurrentPage={0} defaultPageSize={25} />
            <SortingState defaultSorting={defaultSorting} />
            <IntegratedSorting columnExtensions={rateSortingColumnExtensions} />
            <IntegratedPaging />
            <Table
                noDataCellComponent={DataGridNoDataComponent}
                messages={{ noData: translate(translationKeys.VDLANG_ADMIN_CURRENCIES_NO_DATA) }}
                cellComponent={CurrencyTableCell}
                columnExtensions={[...defaultColumnExtensions, ...yearColumnExtensions]}
            />
            <TableHeaderRow showSortingControls cellComponent={CurrencyTableHeaderCell} />
            <TableEditRow />
            <TableEditColumn
                showAddCommand
                showEditCommand
                showDeleteCommand
                commandComponent={DataGridCommandCell}
                cellComponent={TableEditColumnCell}
                messages={messages}
            />
            <PagingPanel
                containerComponent={CustomPagingPanelContainer}
                pageSizes={[10, 25, 50]}
                messages={{ rowsPerPage: `${translate(translationKeys.VDLANG_PAGING_ROWS_PER_PAGE)}:` }}
            />
        </DxGrid>
    );
};

export default React.memo(CurrencyTable);
