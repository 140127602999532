import TableTextCell, { ITableTextCellProps } from "./TableTextCell";

interface ITableBooleanCellProps<D extends object = Record<string, unknown>> extends ITableTextCellProps<D> {
    value: boolean;
    trueText: string;
    falseText: string;
}

const TableBooleanCell = <D extends object = Record<string, unknown>>({
    value,
    trueText,
    falseText,
    ...textCellProps
}: ITableBooleanCellProps<D>) => {
    return <TableTextCell {...textCellProps} value={value ? trueText : falseText} />;
};

export default TableBooleanCell;
